import React from 'react';
import { format as formatDate, parse as parseDate } from 'fecha';

import { ContentMediaData } from '../../../helpers/AssetLoaderTypes';
import AuthorResolver from '../../../helpers/AuthorResolver';

import './PlainCard.scss';
import MarkdownDisplay from '../../markdowndisplay/MarkdownDisplay';
import { CardClickAction, WrapWithLink } from '../Card';
import Tag from '../Tag';

interface IProps {
	data: ContentMediaData;
	onClickAction?: CardClickAction;
}

const MAX_WORD_COUNT = 25;

export default function PlainCard(props: IProps) {
	let publishDate = props.data.publishdate || props.data.date;
	let authorInfo;
	if (props.data.author) {
		const author = AuthorResolver.getData(props.data.author);
		authorInfo = (
			<div className="author-info">
				{AuthorResolver.getImage(props.data.author)}
				<span>
					{author.name}
					{publishDate ? ` | ${formatDate(parseDate(publishDate, 'YYYY-MM-DD') || new Date(0), 'MMMM Do, YYYY')}` : ''}
				</span>
			</div>
		);
	}

	let tags;
	if (props.data.tags) {
		tags = (
			<div className="tags">
				{props.data.tags.split(',').map((s, i) => (
					<Tag key={i}>{s.trim()}</Tag>
				))}
			</div>
		);
	}

	// Truncate text to first 50 words
	const words = (props.data.description || props.data.summary || '').split(' ').slice(0, MAX_WORD_COUNT);
	let text = words.join(' ');
	if (words.length === MAX_WORD_COUNT) text += '...';

	return (
		<div className="dx-card-plain">
			<span className="title">{WrapWithLink(props.data.title || 'CARD TITLE', props.data.link, props.onClickAction)}</span>
			{props.data.subtitle ? <span className="subtitle">{props.data.subtitle}</span> : undefined}
			<span className="text">
				{text && <MarkdownDisplay markdown={text} />}
				{!text && <em>This item does not have a description.</em>}
			</span>
			{authorInfo}
			{tags}
		</div>
	);
}
