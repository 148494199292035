import React, { useEffect, useState } from 'react';
import { DxTabPanel, DxTabbedContent, DxAccordionGroup, DxAccordion } from 'genesys-react-components';

import { ModelSchema, OpenAPIDefinition, OperationDetails, ResponseInfo, SchemaRef } from '../../../../helpers/openapi/OpenAPITypes';
import SwaggerCache from '../../../../helpers/openapi/SwaggerCache';
import ModelJsonDisplay from '../display/ModelJsonDisplay';
import ModelSchemaDisplay from '../display/ModelSchemaDisplay';

import './ResponsesDisplay.scss';

interface IProps {
	operationDetails: OperationDetails;
	source?: string;
}

export default function ResponsesDisplay(props: IProps) {
	const [swagger, setSwagger] = useState<OpenAPIDefinition>();

	// Constructor
	useEffect(() => {
		(async () => setSwagger(await SwaggerCache.get(props.source)))();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Build response objects
	const builldResponse = (statusCode: string, responseDefinition: ResponseInfo) => {
		if (!swagger) return null;

		let bodySchema = responseDefinition.schema ? SwaggerCache.resolveModel(swagger, responseDefinition.schema as ModelSchema) : undefined;

		const hasResponse = bodySchema !== undefined && responseDefinition.schema !== undefined;

		return (
			<DxAccordion title={`${statusCode} - ${responseDefinition.description}`} key={statusCode} className="responses-display">
				{hasResponse && (
					<DxTabbedContent>
						{bodySchema && (
							<DxTabPanel title="Schema">
								<div className="model-body-container">
									<ModelSchemaDisplay
										definition={swagger}
										schema={responseDefinition.schema as SchemaRef}
										// modelName={bodySchema.__modelName}
										showExpanded={true}
									/>
								</div>
							</DxTabPanel>
						)}
						{bodySchema && (
							<DxTabPanel title="JSON">
								<div>
									<ModelJsonDisplay schema={bodySchema} />
								</div>
							</DxTabPanel>
						)}
						{responseDefinition['x-inin-error-codes'] && (
							<DxTabPanel title="Error Codes">
								<table className="error-codes">
									<tbody>
										{Object.entries(responseDefinition['x-inin-error-codes'] as Record<string, string>).map(
											([errorCode, errorDescription]) => (
												<tr key={errorCode}>
													<td>
														<code>{errorCode}</code>
													</td>
													<td>{errorDescription}</td>
												</tr>
											)
										)}
									</tbody>
								</table>
							</DxTabPanel>
						)}
					</DxTabbedContent>
				)}
				{!hasResponse && (
					<p>
						<em>Response has no content</em>
					</p>
				)}
			</DxAccordion>
		);
	};

	return (
		<DxAccordionGroup className="operation-overview">
			{Object.entries(props.operationDetails.operation.responses as Record<number, ResponseInfo>).map(([statusCode, responseDefinition]) =>
				builldResponse(statusCode, responseDefinition)
			)}
		</DxAccordionGroup>
	);
}
