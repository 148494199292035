import React, { useState, useEffect } from 'react';
import { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { useRecoilState, useRecoilValue } from 'recoil';
import { DxAccordion, DxButton, DxToggle } from 'genesys-react-components';
import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';

import { OperationDetails } from '../../../helpers/openapi/OpenAPITypes';
import CodeFence from '../../codefence/CodeFence';
import AlertBlock from '../../markdown/alertblock/AlertBlock';
import { selectedAccountAtom } from '../../../helpers/atoms/AccountsAtom';
import AccountSwitcher from '../../accountswitcher/AccountSwitcher';
import { HeaderData, ParameterData, AuthNames } from '../../../types';
import AppSettings from '../../../helpers/settings/AppSettings';
import TooltipButton from '../../tooltip/TooltipButton';
import PermissionsDisplay from './operationcomponents/PermissionsDisplay';
import ScopesDisplay from './operationcomponents/ScopesDisplay';
import LimitsDisplay from './operationcomponents/LimitsDisplay';
import DxLink from '../../dxlink/DxLink';
import OperationSecurityDisplay from './operationcomponents/OperationSecurityDisplay';
import ParameterEditor from './operationcomponents/ParameterEditor';
import BodyEditor from './operationcomponents/BodyEditor';
import InvocationsDisplay from './operationcomponents/InvocationsDisplay';
import {
	getRequestDataAtom,
	getRequestDataBodyAtom,
	getRequestDataHeadersAtom,
	getRequestDataParametersAtom,
	RequestConfig,
} from '../../../helpers/atoms/APIExplorerRequestCache';
import CopyButton from '../../copybutton/CopyButton';
import ResponsesDisplay from './operationcomponents/ResponsesDisplay';

import './OperationContent.scss';
import HeaderEditor from './operationcomponents/HeaderEditor';

enum LinkType {
	Standard = 'STANDARD',
	Markdown = 'MARKDOWN',
	Configured = 'CONFIGURED',
}

interface IProps {
	operationDetails: OperationDetails;
	source?: string;
}

export default function OperationContent(props: IProps) {
	// Local state objects
	const [response, setResponse] = useState<AxiosResponse<any>>();
	const [responseErrorString, setResponseErrorString] = useState<string>();
	const [isResponsePending, setIsResponsePending] = useState<boolean>();

	// Atom state objects
	const selectedAccount = useRecoilValue(selectedAccountAtom);
	const parameterData = useRecoilValue(getRequestDataParametersAtom(props.operationDetails.operation.operationId));
	const [headerData, setHeaderData] = useRecoilState(getRequestDataHeadersAtom(props.operationDetails.operation.operationId));
	const bodyData = useRecoilValue(getRequestDataBodyAtom(props.operationDetails.operation.operationId));
	const readingMode = useRecoilValue(AppSettings.apiExplorerReadingModeAtom());
	const requestData = useRecoilValue(getRequestDataAtom(props.operationDetails.operation.operationId));

	// Constructor
	useEffect(() => {
		setHeaderData(generateDefaultHeaders());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const resetLocalState = () => {
		setResponse(undefined);
		setResponseErrorString(undefined);
		setIsResponsePending(undefined);
	};

	useEffect(() => {
		setHeaderData(generateDefaultHeaders());
		resetLocalState();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedAccount, props.operationDetails, props.source]);

	const isPureCloudAuth = props.operationDetails.operation.security?.some((securityInfo) =>
		Object.keys(securityInfo).includes('PureCloud OAuth')
	);

	const generateDefaultHeaders = (): HeaderData[] => {
		const initialHeaders: HeaderData[] = [];
		if (isPureCloudAuth) {
			// Is standard OAuth, add known token
			initialHeaders.push({
				name: 'Authorization',
				value: '*******************',
				isSecret: true,
				prefix: 'Bearer ',
				disableUserRemove: true,
			});
		} else if (props.operationDetails.operation.security?.some((securityInfo) => Object.keys(securityInfo).length > 0)) {
			// Something that's not OAuth, but there is auth
			initialHeaders.push({ name: 'Authorization', value: '', isSecret: true, disableUserRemove: true });
		}

		initialHeaders.push({ name: 'Content-Type', value: 'application/json', disableUserRemove: true });

		return initialHeaders;
	};

	function makeAxiosRequest(
		operationDetails: OperationDetails,
		parameterData: ParameterData | undefined,
		headerData: HeaderData[] | undefined,
		bodyData: any
	): AxiosRequestConfig {
		// Replace path params
		let requestPath = operationDetails.path;
		if (parameterData) {
			Object.entries(parameterData).forEach(
				([key, value]) => (requestPath = requestPath.replaceAll(new RegExp(`{${key}}`, 'gi'), encodeURIComponent(value as string)))
			);
		}

		// Build header object (key:value as object properties)
		const headers: any = {};
		const requestData = headerData?.filter((data) => data['value'] !== undefined && data['value'].trim().length !== 0);
		requestData?.forEach((data) => (headers[data.name] = data.prefix ? data.prefix + data.value : data.value)); //custom headers may not have prefix

		// Build query params object (key:value as object properties)
		const queryParams: any = {};
		if (parameterData) {
			operationDetails.operation.parameters
				// Is query param and has a value
				.filter((param) => param.in === 'query' && parameterData[param.name] !== undefined && parameterData[param.name] !== null)
				.forEach(
					(param) =>
						(queryParams[param.name] = Array.isArray(parameterData[param.name])
							? (parameterData[param.name] as string[]).join(',')
							: (parameterData[param.name] as string))
				);
		}
		return {
			method: operationDetails.verb as Method,
			url: requestPath,
			headers,
			params: queryParams,
			data: bodyData,
		};
	}

	const executeRequest = () => {
		if (!selectedAccount) return setResponseErrorString('ERROR: no active account');

		const request = makeAxiosRequest(props.operationDetails, parameterData, headerData, bodyData);
		const requestKey = `${request.method?.toUpperCase()} ${request.url}`;

		// This trace is intentional for end-user troubleshooting
		console.log(`[>> ${requestKey}]`, request);

		// Override auth header to current account's token
		if (isPureCloudAuth) {
			if (!request.headers) request.headers = {};
			request.headers['Authorization'] = `Bearer ${selectedAccount.token}`;
		}

		setIsResponsePending(true);
		setResponseErrorString(undefined);
		setResponse(undefined);

		// Execute request
		selectedAccount.api
			.request(request)
			.then((res) => {
				// This trace is intentional for end-user troubleshooting
				console.log(`[<< ${requestKey}]`, res);
				setResponse(res);
				setIsResponsePending(false);
				setResponseErrorString(undefined);
			})
			.catch((err) => {
				// This trace is intentional for end-user troubleshooting
				console.error(`[ERROR ${requestKey}]`, err);
				setIsResponsePending(false);
				if (err.response) {
					setResponse(err.response);
					const res = err.response as AxiosResponse;
					setResponseErrorString(
						`The request failed with response code: ${res?.status || -1}. Message: ${
							res?.data?.message || res?.statusText || 'Unknown failure. Check the JavaScript console for more information.'
						}`
					);
				} else {
					// This is usually caused by a CORS error, but can be any runtime error. Axios throws a normal exception when the browser blocks the response.
					setResponseErrorString('The request failed in an unexpected way. Check the JavaScript console for details.');
				}
			});
	};

	const copyButton = (link: string, linkType: LinkType) => {
		const formattedLink =
			linkType === LinkType.Markdown ? `[${props.operationDetails.verb.toUpperCase()} ${props.operationDetails.path}](${link})` : link;
		let btnText;
		switch (linkType) {
			case LinkType.Standard:
				btnText = 'Copy link';
				break;
			case LinkType.Configured:
				btnText = 'Copy link with data';
				break;
			case LinkType.Markdown:
				btnText = 'Copy markdown link';
				break;
			default:
				btnText = '';
		}
		return (
			<TooltipButton tooltipText="Link copied" className="copy-link-button" onClick={() => navigator.clipboard.writeText(formattedLink)}>
				<GenesysDevIcon
					className="button-icon"
					icon={linkType === LinkType.Markdown ? GenesysDevIcons.BrandMarkdown : GenesysDevIcons.AppLink}
				/>
				{btnText}
			</TooltipButton>
		);
	};

	let requestError;
	if (responseErrorString) {
		requestError = (
			<div className="response-output">
				<AlertBlock alertType="critical">
					<p>{responseErrorString}</p>
				</AlertBlock>
			</div>
		);
	}

	const isStandardAuth = props.operationDetails.operation?.security?.some((securityInfo) =>
		Object.keys(securityInfo).includes(AuthNames.GenesysCloudOAuth)
	);

	const stringifyRequestConfig = (data?: RequestConfig): string => {
		if (!data) return '';
		return (
			window.btoa(
				// stringify and encode/unescape to make sure special chars fall in the Latin1 range
				unescape(
					encodeURIComponent(
						JSON.stringify({
							operationId: data.operationId,
							body: data.body,
							parameters: data.parameters,
							// Scrub certain headers from data
							headers: data.headers?.filter(
								(v) => v.disableUserRemove !== true || !['authorization', 'content-type'].includes(v.name.toLowerCase())
							),
						})
					)
				)
			) || ''
		);
	};

	return (
		<div className="operation-explorer">
			<div className="copy-button-container">
				{copyButton(makeOperationLink(props.operationDetails, stringifyRequestConfig(requestData), false), LinkType.Standard)}
				{copyButton(makeOperationLink(props.operationDetails, stringifyRequestConfig(requestData), true), LinkType.Configured)}
				{copyButton(makeOperationLink(props.operationDetails, stringifyRequestConfig(requestData), false), LinkType.Markdown)}
				<DxToggle
					label="Reading Mode"
					value={readingMode}
					onChange={(value: any) => AppSettings.setApiExplorerReadingMode(value === true)}
				/>
			</div>
			<DxAccordion title="Operation Information" showOpen={false}>
				<h3>Resource Authorization</h3>
				<OperationSecurityDisplay operationDetails={props.operationDetails} />
				<div className="scopes-permissions-container">
					<PermissionsDisplay operationDetails={props.operationDetails} />
					<ScopesDisplay operationDetails={props.operationDetails} />
				</div>
				<h3>Resource Limits</h3>
				<p>
					The following limits apply to this resource. For more information, see the main article on{' '}
					<DxLink href="/platform/api/rate-limits">Rate Limits</DxLink> and the full list of{' '}
					<DxLink href="/organization/organization/limits">Platform API Limits</DxLink>.
				</p>
				<LimitsDisplay operationDetails={props.operationDetails} />
			</DxAccordion>
			<DxAccordion title="API Request" showOpen={true}>
				<h3>Request Parameters</h3>
				<ParameterEditor operationDetails={props.operationDetails} source={props.source} />
				<HeaderEditor operationDetails={props.operationDetails} />
				{props.operationDetails.operation.parameters.some((param) => param.in === 'body') && (
					<React.Fragment>
						<h3>Request Body</h3>
						<BodyEditor operationDetails={props.operationDetails} source={props.source} />
					</React.Fragment>
				)}
				<h3>Invocations</h3>
				<InvocationsDisplay operationDetails={props.operationDetails} source={props.source} />
				<h3>Execute Request</h3>
				{readingMode && isStandardAuth && (
					<AlertBlock alertType="info" title="Reading mode is enabled">
						<div className="execute-request-reading-mode">
							<DxToggle
								label="Reading Mode"
								value={readingMode}
								onChange={(value: any) => AppSettings.setApiExplorerReadingMode(value === true)}
							/>
							Switch out of reading mode to configure and execute this request
						</div>
					</AlertBlock>
				)}
				{!readingMode && isStandardAuth && (
					<div className="request-controls-container">
						{selectedAccount && (
							<div className="request-controls">
								<div className="account-card-container">
									<AccountSwitcher mode="mini" />
								</div>
								<div className="execute-button-container">
									<DxButton onClick={executeRequest} disabled={isResponsePending === true} className="execute-button">
										{isResponsePending === true ? 'Waiting for response...' : 'Execute Request'}
									</DxButton>
								</div>
							</div>
						)}
						{!selectedAccount && <em>Add an account using the account selector to make requests</em>}
						{requestError}
					</div>
				)}
				{!isStandardAuth && (
					<AlertBlock alertType="warning" title="Non-standard Authorization Warning">
						<OperationSecurityDisplay operationDetails={props.operationDetails} />
					</AlertBlock>
				)}
				{response && (
					<div>
						<h4>
							Response:{' '}
							<span className={response.status >= 200 && response.status < 300 ? 'response-status-ok' : 'response-status-failed'}>
								{response.status} {response.statusText}
							</span>
						</h4>
						<table className="response-header-table">
							<tbody>
								{(Object.entries(response.headers || {}) || []).map(([key, value]) => (
									<tr key={key}>
										<td>{key}</td>
										<td>
											{value as any} <CopyButton copyText={value as any} />
										</td>
									</tr>
								))}
							</tbody>
						</table>
						<CodeFence
							className="response-body-fence"
							value={JSON.stringify(response.data, null, 2)}
							language="json"
							sizeKb={Buffer.byteLength(JSON.stringify(response.data)) / 1000}
						/>
					</div>
				)}
			</DxAccordion>
			<DxAccordion title="API Responses">
				<ResponsesDisplay operationDetails={props.operationDetails} source={props.source} />
			</DxAccordion>
		</div>
	);
}

function makeOperationLink(operation: OperationDetails, requestLinkParam: string, shouldConfigureLink: boolean) {
	return (
		window.location.origin +
		window.location.pathname +
		(requestLinkParam && shouldConfigureLink ? `?requestConfig=${requestLinkParam}` : '') +
		`#${operation.key}`
	);
}
