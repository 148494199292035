import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getRequestDataParametersAtom } from '../../../../helpers/atoms/APIExplorerRequestCache';
import { SchemaToPropertyInfo } from '../../../../helpers/openapi/OpenAPITools';

import { ModelSchema, OpenAPIDefinition, OperationDetails, ParameterInfo } from '../../../../helpers/openapi/OpenAPITypes';
import SwaggerCache from '../../../../helpers/openapi/SwaggerCache';
import AppSettings from '../../../../helpers/settings/AppSettings';
import DataTable, { DataTableRow } from '../../../markdown/datatable/DataTable';
import ModelProperty from '../modeleditor/wizardeditor/ModelProperty';

interface IProps {
	operationDetails: OperationDetails;
	source?: string;
}

export default function ParameterEditor(props: IProps) {
	const [swagger, setSwagger] = useState<OpenAPIDefinition>();
	const readingMode = useRecoilValue(AppSettings.apiExplorerReadingModeAtom());
	const [parameterData, setParameterData] = useRecoilState(getRequestDataParametersAtom(props.operationDetails.operation.operationId));

	// Constructor
	useEffect(() => {
		(async () => setSwagger(await SwaggerCache.get(props.source)))();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const toModelSchema = (param: ParameterInfo): ModelSchema => {
		return {
			__propertyName: param.name,
			description: param.description,
			required: param.required ? [param.name] : undefined,
			type: param.type,
			items: param.items,
			format: param.format,
			enum: param.enum,
			__isRequired: param.required,
		};
	};

	// Return no params
	if (!props.operationDetails.operation.parameters.some((param) => param.in === 'path' || param.in === 'query')) {
		return (
			<div>
				<em>This resource does not have any path or query parameters</em>
			</div>
		);
	}

	// Return reading mode
	if (readingMode) {
		return (
			<div>
				<DataTable
					headerRow={{
						cells: [{ content: 'Parameter' }, { content: 'Location' }, { content: 'Description' }],
					}}
					rows={props.operationDetails.operation.parameters
						.filter((param) => ['path', 'query'].includes(param.in))
						.map((param) => {
							return {
								cells: [
									{ content: param.name },
									{ content: param.in },
									{
										content: `(${param.type}${param.required === true ? ', required' : ''}) ${param.description?.trim()}${
											param.type.trim() === 'array'
												? param.items?.enum
													? (param.description?.trim().endsWith('.') ? '' : '.') + ' Valid values: ' + param.items?.enum.join(', ')
													: ''
												: param.enum
												? (param.description?.trim().endsWith('.') ? '' : '.') + ' Valid values: ' + param.enum?.join(', ')
												: ''
										}`,
									},
								],
							} as DataTableRow;
						})}
				/>
			</div>
		);
	}

	const updateParameter = (name: string, value: string) => {
		const newParameterData = { ...parameterData };
		newParameterData[name] = value;
		setParameterData(newParameterData);
	};

	// Return editor
	return (
		<div className="parameter-list-container">
			<div>
				<h4>Path Parameters</h4>
				<ul className="parameter-list">
					{swagger &&
						props.operationDetails.operation.parameters
							.filter((param) => param.in === 'path')
							.map((param, i) => (
								<li key={i}>
									<ModelProperty
										initialValue={parameterData ? parameterData[param.name] : undefined}
										property={SchemaToPropertyInfo(toModelSchema(param))}
										onValueUpdated={updateParameter}
										swagger={swagger}
									/>
								</li>
							))}
				</ul>
			</div>
			<div>
				<h4>Query Parameters</h4>
				<ul className="parameter-list">
					{swagger &&
						props.operationDetails.operation.parameters
							.filter((param) => param.in === 'query')
							.map((param, i) => (
								<li key={i}>
									<ModelProperty
										initialValue={parameterData ? parameterData[param.name] : undefined}
										property={SchemaToPropertyInfo(toModelSchema(param))}
										onValueUpdated={updateParameter}
										swagger={swagger}
									/>
								</li>
							))}
				</ul>
			</div>
		</div>
	);
}
