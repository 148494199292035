import React, { useEffect, useState, useRef } from 'react';
import { DxItemGroup, DxItemGroupItem, DxTextbox, DxAccordion, DxButton } from 'genesys-react-components';
import { GenesysDevIcons, GenesysDevIcon } from 'genesys-dev-icons';
import { useRecoilState } from 'recoil';
import axios, { CancelTokenSource } from 'axios';

import { selectedAccountAtom } from '../../../helpers/atoms/AccountsAtom';
import LoadingPlaceholder from '../../loadingplaceholder/LoadingPlaceholder';
import { Models } from '../../../helpers/platformapi/PlatformAPITypes';
import { addToast, ToastType } from '../../../helpers/atoms/ToastAtom';
import CodeFence from '../../codefence/CodeFence';
import AlertBlock from '../../markdown/alertblock/AlertBlock';
import './ScreenShare.scss';

interface Config {
	webchatServiceUrl: string;
	webchatAppUrl: string;
	orgId?: string;
	orgName?: string;
	logLevel: string;
	locale: string;
	orgGuid?: string;
	cssClass: string;
	css: string;
	contentCssUrl: string;
	standAloneApplication: boolean;
	webchatDeploymentKey: string;
}

function ScreenShare() {
	const [webchatItems, setWebchatItems] = useState<DxItemGroupItem[]>([]);
	const [standAlone, setStandAlone] = useState<DxItemGroupItem[]>([]);
	const [selectedDeployment, setSelectedDeployment] = useState('');
	const [additionalCss, setAdditionalCss] = useState('');
	const [logLevel, setLogLevel] = useState('DEBUG');
	const [locale, setLocale] = useState('en');
	const [cssUrl, setCssUrl] = useState('');
	const [cssClass, setCssClass] = useState('screenshare-frame');
	const [containerEl, setContainerEl] = useState('screenshareContainer');
	const [hasPermissions, setHasPermisions] = useState(true);
	const [isStandAlone, setIsStandAlone] = useState(true);
	const [selectedAccount] = useRecoilState(selectedAccountAtom);
	const [loadingDeployments, setLoadingDeployments] = useState(false);
	const cancelToken = useRef<CancelTokenSource | undefined>();
	const logLevels: DxItemGroupItem[] = [
		{ label: 'Debug', value: 'DEBUG' },
		{ label: 'Info', value: 'INFO' },
		{ label: 'Warn', value: 'WARN' },
		{ label: 'Error', value: 'ERROR' },
		{ label: 'Fatal', value: 'FATAL' },
	];

	useEffect(() => {
		if (!selectedAccount) return;

		//Cancel ongoing request
		cancelToken.current?.cancel('Active account was changed');

		//Reset fields
		setWebchatItems([]);
		setSelectedDeployment('');
		setHasPermisions(true);

		//get deployments
		loadDeployments()
			.then((res: any) => {
				const deploymentItems: DxItemGroupItem[] = res.map((d: Models.WidgetDeployment) => {
					return { label: d.name, value: d.id };
				});
				deploymentItems.unshift({ label: '', value: '' });
				setWebchatItems(deploymentItems);
			})
			.catch((err) => {
				addToast({ title: 'Unable to get deployments', message: err.message, toastType: ToastType.Critical });
				if (err.message.includes('403')) {
					setHasPermisions(false);
				}
			});

		setStandAlone([
			{ label: 'Yes', value: '' },
			{ label: 'No', value: 'no' },
		]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedAccount]);

	function loadDeployments() {
		return new Promise((resolve, reject) => {
			if (!selectedAccount) {
				reject('No active account');
			}
			const tokenSource = axios.CancelToken.source();
			cancelToken.current = tokenSource;
			selectedAccount?.api
				.request({ url: '/api/v2/widgets/deployments', method: 'get', cancelToken: cancelToken.current?.token })
				.then((deployments) => {
					// Sort a-z
					deployments.data.entities.sort((a: any, b: any) =>
						a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0
					);

					// Process list
					let deploymentList: Models.WidgetDeployment[] = [];
					deployments.data.entities.forEach((deployment: any) => {
						if (deployment.disabled) return;

						if (deployment.authenticationRequired === true) {
							deployment.name += ' (Requires Authentication, not supported with dev tools)';
						}

						deploymentList.push(deployment);
					});
					// Done
					resolve(deploymentList);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	function createDeployment() {
		return new Promise((resolve, reject) => {
			if (!selectedAccount) {
				reject('No active account');
			}

			let deploymentName = '';
			const body = {
				name: 'Developer Tools',
				description: 'Created by the Genesys Cloud Developer Tools',
				authenticationRequired: false,
				disabled: false,
				webChatConfig: {
					webChatSkin: 'basic',
				},
			};
			selectedAccount?.api
				.request({ url: '/api/v2/widgets/deployments', method: 'post', data: body })
				.then((deployment) => {
					deploymentName = deployment.data.name;
					resolve(deploymentName);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async function createNewDeployment() {
		try {
			setLoadingDeployments(true); //display LoadingPlaceholder
			const newDeploymentName = (await createDeployment()) as string;

			const deployments: Models.WidgetDeployment[] = (await loadDeployments()) as Models.WidgetDeployment[];

			const deploymentItems: DxItemGroupItem[] = deployments.map((deployment: Models.WidgetDeployment) => {
				return { label: deployment.name || '', value: deployment.id || '' };
			});

			deploymentItems.unshift({ label: '', value: '' });
			setWebchatItems(deploymentItems);
			setLoadingDeployments(false);
			addToast({
				title: 'New web chat deployment created',
				toastType: ToastType.Success,
				message: `Created webchat deployment: ${newDeploymentName}`,
			});
		} catch (error: any) {
			setLoadingDeployments(false);
			addToast({ title: 'Failed to create new deployment', toastType: ToastType.Critical, message: error.message });
		}
	}

	const verifiedCssJson = (cssClass: string) => {
		if (!cssClass) {
			return '';
		}
		try {
			const obj = JSON.parse(cssClass);
			return obj;
		} catch (err) {
			return '';
		}
	};

	let chatConfig: Config = {
		webchatServiceUrl: `https://realtime.${selectedAccount?.region}:443`,
		webchatAppUrl: `https://apps.${selectedAccount?.region}/webchat`,
		orgId: selectedAccount?.me?.organization?.thirdPartyOrgId,
		orgName: selectedAccount?.me?.organization?.thirdPartyOrgName,
		logLevel: logLevel,
		locale: locale,
		orgGuid: selectedAccount?.me?.organization?.id,
		cssClass: cssClass,
		css: verifiedCssJson(additionalCss),
		contentCssUrl: cssUrl,
		standAloneApplication: isStandAlone,
		webchatDeploymentKey: selectedDeployment,
	};

	const config = `const chatConfig =
    ${JSON.stringify(chatConfig, null, 2)};
	function loadScreenshare() { 
		ININ.screenshare.create(config, function(err, screenshare) { 
			if (err) { 
				// You should change this block to properly handle errors. 
				console.error(err);
			  if (err.name === 'UNSUPPORTED_BROWSER') { 
				// Redirect to instructions for unsupported browser, or handle appropriately
				alert('Sorry, either your browser is not supported, or the page is not being served over TLS (HTTPS).');
				return; 
			  } 
			  alert('An error occurred launching the screen share widget. See console for details'); 
			} 
			screenshare.renderScreenShareForm({ 
				containerEl: '${containerEl}'
			}); 
		}); 
	}`;

	if (!selectedAccount) {
		return (
			<div className="warning-container">
				<GenesysDevIcon className="icon" icon={GenesysDevIcons.AppInfoSolid} />
				<span> Please add an account to utilize this tool</span>
			</div>
		);
	}

	let notStandAlonePayload;

	if (!isStandAlone) {
		notStandAlonePayload = (
			<React.Fragment>
				<DxTextbox
					value={containerEl}
					inputType="text"
					label="Container Element Selector"
					description="Container Element Selector refers to the name of the screen share container div id. The screen share form renders inside of this div."
					onChange={setContainerEl}
				/>
				<DxTextbox
					value={cssClass}
					inputType="text"
					label="CSS Class"
					description="CSS class if widget is rendered as frame"
					onChange={setCssClass}
				/>
				{additionalCss && !verifiedCssJson(additionalCss) ? (
					<AlertBlock
						title="Additional CSS is not properly formatted and will not be added to the config until it is."
						alertType="critical"
						className="alert"
					/>
				) : (
					''
				)}

				<DxTextbox
					value={additionalCss}
					inputType="textarea"
					label="Additional CSS"
					description='Additional CSS properties if widget is rendered as an iframe. These properties apply to the iframe itself, not to its content. These rules should be in JSON format like the following example: { "width": "480px", "height": "282px", "border": "none" }'
					onChange={(text) => setAdditionalCss(text)}
				/>
				<DxAccordion title="Deployment script Tag" className="screenShare-accordion">
					<CodeFence
						title="Deployment script Tag"
						value={`<script type="text/javascript" src="https://apps.${selectedAccount?.region}/webchat/jsapi-v1.js" ></script>`}
						language="javascript"
					/>
				</DxAccordion>
				<DxAccordion title="Config Code" className="screenShare-accordion">
					<div>
						<CodeFence title="Config Code" value={config} language="javascript" />
					</div>
				</DxAccordion>
			</React.Fragment>
		);
	}

	return (
		<div className="screenshare-container">
			<h3>Screen Share Configuration</h3>

			{!selectedDeployment && <AlertBlock title="Required" alertType="critical" className="alert" />}

			<DxItemGroup
				format="dropdown"
				items={webchatItems}
				title="Deployment"
				description={`${
					webchatItems.length > 0
						? ' Select a webchat deployment or create new one'
						: '	No web chat deployments found, please create a new one'
				}`}
				onItemChanged={(item) => setSelectedDeployment(item.value)}
			/>

			{loadingDeployments ? (
				<LoadingPlaceholder text="Creating Deployment" />
			) : (
				<DxButton onClick={createNewDeployment} type="primary">
					Create new deployment
				</DxButton>
			)}

			<DxItemGroup
				format="dropdown"
				items={logLevels}
				title="Log Level"
				description="The level of debugging output."
				onItemChanged={(item) => setLogLevel(item.value)}
			/>
			<DxTextbox
				value={locale}
				inputType="text"
				placeholder="en"
				label="Locale"
				description="The locale code. Specify this option if you plan to localize your widget."
				onChange={setLocale}
			/>
			<DxTextbox
				inputType="text"
				label="Content CSS Url"
				description="A URL to a hosted stylesheet for customizing the screen share security code entry form."
				onChange={setCssUrl}
			/>
			<DxItemGroup
				format="dropdown"
				items={standAlone}
				title="Stand Alone Mode"
				description="Whether you are using a stand alone screen share client. If no, then screen share only starts in supported browsers (Firefox and Chrome). Set this to yes if you want to use a stand alone screen share client."
				onItemChanged={(item) => setIsStandAlone(!item.value)}
			/>
			{notStandAlonePayload}

			{!hasPermissions && (
				<AlertBlock
					title="Unable to list deployments. Missing permission: webchat:deployment:read"
					alertType="critical"
					className="alert"
				/>
			)}

			{isStandAlone && (
				<DxAccordion title="Screen Share Url">
					<span className="url-container">{`https://apps.${
						selectedAccount.region
					}/webchat/screenshare/#?webchatServiceUrl=${encodeURIComponent(
						`https://realtime.${selectedAccount.region}:443`
					)}&logLevel=${logLevel}&orgId=${encodeURIComponent(
						selectedAccount.me?.organization?.thirdPartyOrgId || ''
					)}&orgGuid=${encodeURIComponent(selectedAccount.me?.organization?.id || '')}&orgName=${encodeURIComponent(
						selectedAccount.me?.organization?.thirdPartyOrgName || ''
					)}&webchatDeploymentKey=${selectedDeployment}${cssUrl ? `&contentCssUrl=${encodeURIComponent(cssUrl)}` : ''}`}</span>
				</DxAccordion>
			)}
		</div>
	);
}

export default ScreenShare;
