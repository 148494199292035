import React, { useState } from 'react';
import MarkdownDisplay from '../markdowndisplay/MarkdownDisplay';

import './TabbedContent.scss';

interface IProps {
	titles: (string | JSX.Element)[];
	panes: (string | JSX.Element)[];
	className?: string;
	initialTabId?: number;
}

export default function TabbedContent(props: IProps) {
	const [activeTab, setActiveTab] = useState(props.initialTabId || 0);

	let pane = <em>no content</em>;
	if (props.panes.length > activeTab) {
		if (typeof props.panes[activeTab] === 'string') {
			pane = <MarkdownDisplay markdown={props.panes[activeTab]} />;
		} else {
			pane = props.panes[activeTab] as JSX.Element;
		}
	}

	return (
		<div className={'tabbed-content' + (props.className ? ` ${props.className}` : '')}>
			<div className="tab-titles">
				{props.titles.map((title, i) => (
					<span key={i} className={`tab-title${i === activeTab ? ' active' : ''}`} onClick={() => setActiveTab(i)}>
						{typeof title === 'string' ? <MarkdownDisplay markdown={title} /> : title}
					</span>
				))}
			</div>
			<div className="tab-content">{pane}</div>
		</div>
	);
}
