import React from 'react';
import { withRouter } from 'react-router-dom';
import { default as qs } from 'qs';

import { default as NavMan } from '../helpers/NavigationManager';
import SettingsManager from '../helpers/settings/SettingsManager';
import { AnemiaTheme, SettingNames } from '../types';

/* Layouts and content components */
// import DefaultLayout from './layouts/DefaultLayout';
import DefaultLayout2021 from './layouts/DefaultLayout2021';
import PageContent from './core/PageContent';
import LoadingPlaceholder from './loadingplaceholder/LoadingPlaceholder';

/* Themes */
// import '../styles/themes/genesys.scss';
// import '../styles/themes/genesys-dark.scss';
// import '../styles/themes/eggplant.scss';
// import '../styles/themes/retro.scss';
// import '../styles/themes/darkness.scss';
// import '../styles/themes/dark-ice.scss';
// import '../styles/themes/solarized-dark.scss';
// import '../styles/themes/solarized-light.scss';

import ClickCapture from './ClickCapture';
import LandingPage from './layouts/LandingPage';
import ApiExplorerLayout from './layouts/apiexplorer/ApiExplorerLayout';

const contentExtensionStripperRegex = /(.+)(?:\.html|\.md)/i;

class LayoutController extends React.Component {
	constructor() {
		super();
		this.state = {
			pathname: '',
			theme: AnemiaTheme.Default,
		};
	}

	async getTheme() {
		return await SettingsManager.get(SettingNames.SelectedTheme);
	}

	componentDidMount() {
		NavMan.onSitemapLoaded(() => {
			// This triggers a re-render once the sitemap is populated to be able to retrieve the page data
			this.setState({ hasSitemap: true });

			// Remove JWT from querystring and history
			// This is done here to prevent triggering a navigation event before the page can be found in the sitemap
			const queryParams = qs.parse(window.location.search.replace(/^\?/, ''));
			if (queryParams.jwt) {
				delete queryParams.jwt;
				let queryString = Object.keys(queryParams)
					.map((p) => `${p}=${encodeURIComponent(queryParams[p])}`)
					.join('&');
				if (queryString) queryString = '?' + queryString;
				const l = window.location;
				this.props.history.replace(`${l.pathname}${queryString}${l.hash}`);
			}
		});
		this.getTheme().then((theme) => this.setState({ theme: theme }));
	}

	componentDidUpdate() {
		const stateUpdate = {};
		let didUpdate = false;

		// Check props vs. state
		if (this.props.location.pathname !== this.state.pathname) {
			stateUpdate.pathname = this.props.location.pathname;
			didUpdate = true;
			this.hash = this.props.location.hash;

			// Strip known content extensions
			let newPath;
			const match = contentExtensionStripperRegex.exec(this.props.location.pathname);
			if (match) newPath = match[1];

			// Use trailing slash for index pages
			if ((newPath || this.props.location.pathname).toLowerCase().endsWith('/index'))
				newPath = (newPath || this.props.location.pathname).substring(0, (newPath || this.props.location.pathname).length - 5);

			// Rewrite URL/history and abort further processing
			if (newPath) {
				this.props.history.replace(newPath + this.props.location.search + this.props.location.hash);
				NavMan.setPathname(newPath);
				return;
			}

			// Validate that the current path matches the page's link
			// The primary use case is for robustness to redirect incorrect file paths to dirs: /path/to/dir -> /path/to/dir/
			let page = NavMan.getPage(this.props.location.pathname);
			if (page && page.link !== this.props.location.pathname) {
				return this.props.history.replace(page.link + this.props.location.search + this.props.location.hash);
			}

			// This is what informs the Sitemap of when a navigation event occurs
			NavMan.setPathname(this.props.location.pathname);
		} else if (this.props.location.hash !== this.hash) {
			this.hash = this.props.location.hash;
			NavMan.setPathname(this.props.location.pathname);
		}

		// Update state
		if (didUpdate) {
			this.setState(stateUpdate);
		}
	}

	render() {
		let layout;
		let page = NavMan.getPage(this.state.pathname);

		switch (page && page.layout ? page.layout : 'default') {
			case '':
			case undefined: {
				// Unable to determine layout, show loading page
				layout = <LoadingPlaceholder />;
				break;
			}
			case 'test': {
				layout = (
					<div style={{ backgroundColor: '#ffbbbb', height: '100vh' }}>
						<PageContent />
					</div>
				);
				break;
			}
			case 'landingpage': {
				layout = <LandingPage />;
				break;
			}
			case 'apiexplorer': {
				layout = <ApiExplorerLayout />;
				break;
			}
			default: {
				layout = <DefaultLayout2021 />;
			}
		}

		// Set theme directly on body to ensure the theme can style everything in the layout
		document.body.className = this.state.theme;

		return (
			<div>
				<ClickCapture>{layout}</ClickCapture>
			</div>
		);
	}
}

// withRouter adds the property "location" to this component indicating the current path as determined by the parent BrowserRouter
export default withRouter(LayoutController);
