import { processModel } from '../../../../helpers/openapi/OpenAPITools';
import { ModelSchema } from '../../../../helpers/openapi/OpenAPITypes';
import CodeFence from '../../../codefence/CodeFence';

interface IProps {
	schema: ModelSchema;
	isRequest?: boolean;
	swagger?: any;
}

export default function ModelJsonDisplay(props: IProps) {
	return (
		<div>
			<CodeFence value={JSON.stringify(processModel(props.schema, props.isRequest, undefined, props.swagger), null, 2)} language="json" />
		</div>
	);
}
