import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { OperationDetails, VisibilityType, visibilityMap } from '../../../helpers/openapi/OpenAPITypes';
import OperationContent from './OperationContent';
import { toolboxApiResourcesAtom, AddApiResource, removeApiResource } from '../../../helpers/atoms/ToolboxAtom';
import { ApiResourceProps, ToolboxApp, ToolboxItem } from '../../../types';
import StarIcon from '../../staricon/StarIcon';
import Tag from '../../../components/cards/Tag';

import './OperationTile.scss';

interface IProps {
	operationDetails: OperationDetails;
	source?: string;
	showExpanded?: boolean;
	preventCollapse?: boolean;
}

export default function OperationTile(props: IProps) {
	const bookmarks = useRecoilValue(toolboxApiResourcesAtom);

	const isLinkedTo = window.location.hash.toLowerCase() === `#${props.operationDetails.key.toLowerCase()}`;
	const matchingBookmark = bookmarks.some(
		(b: ToolboxItem) =>
			(b.props as ApiResourceProps).path === props.operationDetails.path &&
			(b.props as ApiResourceProps).verb === props.operationDetails.verb
	);
	const op = props.operationDetails;

	const [isExpanded, setIsExpanded] = useState(isLinkedTo || props.showExpanded || props.preventCollapse);
	const [isBookmarked, setIsBookmarked] = useState(matchingBookmark);

	// maintain correct bookmark display state when bookmarks are updated
	useEffect(() => {
		const matchingBookmark =
			bookmarks?.some(
				(b: ToolboxItem) =>
					(b.props as ApiResourceProps).path === props.operationDetails.path &&
					(b.props as ApiResourceProps).verb === props.operationDetails.verb
			) || false;
		if (matchingBookmark !== isBookmarked) {
			setIsBookmarked(matchingBookmark);
		} // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bookmarks, props.operationDetails]);

	let content;
	if (isExpanded) {
		content = <OperationContent operationDetails={props.operationDetails} source={props.source} />;
	}

	// Join summary and description in a normalized way
	let description = (op.operation.summary || '').trim();
	if (description !== '' && op.operation.description) {
		if (!description.endsWith('.')) description += '. ';
		else description += ' ';
		description += op.operation.description;
	}

	let deprecatedTag;
	let visibilityTag;
	let visibilityType: VisibilityType | undefined = op.operation['x-genesys-visibility'];
	if (op.operation.deprecated) {
		deprecatedTag = (
			<div className={`resource-tag${visibilityType ? ' no-right-pad' : ''}`}>
				<Tag>Deprecated</Tag>
			</div>
		);
	}
	if (visibilityType) {
		visibilityTag = (
			<div className="resource-tag">
				<Tag className={`tag-${visibilityType}`}>{visibilityMap[visibilityType]}</Tag>
			</div>
		);
	}

	const handleClick = () => {
		if (!isBookmarked) {
			AddApiResource({
				title: `${op.verb.toUpperCase()} ${op.path}`,
				appType: ToolboxApp.ApiExplorer,
				props: { path: op.path, verb: op.verb } as ApiResourceProps,
			});
		} else {
			removeApiResource({
				title: `${op.verb.toUpperCase()} ${op.path}`,
				appType: ToolboxApp.ApiExplorer,
				props: { path: op.path, verb: op.verb } as ApiResourceProps,
			});
		}
		setIsBookmarked(!isBookmarked);
	};

	return (
		<div id={op.key} className="openapi-operation-tile">
			<div className={`operation-container operation-${op.verb.toLowerCase()}`}>
				<div
					className={`operation${isExpanded ? ' expanded' : ''}${props.preventCollapse ? ' force-open' : ''}`}
					onClick={(e) => {
						setIsExpanded(props.preventCollapse || !isExpanded);
						e.preventDefault();
					}}
				>
					<StarIcon
						starredTooltipText="Saved to toolbox"
						unstarredTooltipText="Removed from toolbox"
						className="bookmark-icon-container"
						onClick={handleClick}
						isStarred={isBookmarked}
					/>
					<div className="verb">{op.verb}</div>
					<div className={`path${deprecatedTag || visibilityTag ? ' no-right-pad' : ''}`}>{op.path}</div>
					{deprecatedTag}
					{visibilityTag}
					<div className="description">{description}</div>
				</div>
				{content}
			</div>
		</div>
	);
}
