import React, { useEffect, useState } from 'react';
import { DxTextbox, DxCheckbox } from 'genesys-react-components';

import AssetLoader from '../../../../helpers/AssetLoader';
import TopicTile from './TopicTile';
import { IProps, Schema, Topic, Permissions, PermissionDetails, RequiredPermissions, Visibility } from './NotificationDefinitions';
import { addToast, ToastType } from '../../../../helpers/atoms/ToastAtom';

import './NotificationTool.scss';
import { GenesysDevIcons } from 'genesys-dev-icons';
import LoadingPlaceholder from '../../../loadingplaceholder/LoadingPlaceholder';

function NotificationTool(props: IProps) {
	const [topics, setTopics] = useState<Topic[] | undefined>();
	const [filteredTopics, setFilteredTopics] = useState<Topic[]>([]);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [filterEventBridge, setFilterEventBridge] = useState(false);
	const [filterWebSocket, setFilterWebSocket] = useState(false);
	const [filterProcessAutomation, setFilterProcessAutomation] = useState(false);

	useEffect(() => {
		const { source } = props || {};

		AssetLoader.get(source, true, undefined)
			.then((res) => {
				let parsedTopics = parseNotifications(res);
				return parsedTopics;
			})
			.then((data: Topic[]) => {
				if (data) setTopics(data);
			})
			.catch((err) => {
				addToast({
					title: 'Failed to Load Notification Topics',
					message: `${err}`,
					toastType: ToastType.Critical,
				});
			}); // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function parseNotifications(topics: any) {
		let notificationTopics: Topic[] = [];
		topics.forEach((topics: any) => {
			const permissionsInfo: Permissions = {
				permissionDetails: topics.permissionDetails,
				requiresPermissions: topics.requiresPermissions,
				requiresCurrentUser: topics.requiresCurrentUser,
				requiresCurrentUserOrPermission: topics.requiresCurrentUserOrPermission,
				enforced: topics.enforced,
				requiresDivisionPermissions: topics.requiresDivisionPermissions,
			};
			notificationTopics.push({
				id: topics.id,
				idKey: topics.id.replace(/\W/g, '-'),
				description: tagPreviewTopics(topics.description, topics.visibility),
				exampleSchema: schemaToExample(topics.schema),
				uriPaths: getFormattedUriValues(topics.publicApiTemplateUriPaths),
				requiredPermissions: getNewFormattedPermissions(permissionsInfo),
				topicParameters: topics.topicParameters || [],
				transports: topics.transports || [],
				visibility: topics.visibility,
			});
		});
		return notificationTopics;
	}

	useEffect(() => {
		if (!topics) return;

		// Filter based on search term
		let filteredTopics = topics.filter(
			(topic) =>
				topic.id.toLowerCase().includes(searchTerm.toLowerCase()) || topic.description.toLowerCase().includes(searchTerm.toLowerCase())
		);

		// No type filter
		if (
			(filterEventBridge && filterWebSocket && filterProcessAutomation) ||
			(!filterEventBridge && !filterWebSocket && !filterProcessAutomation)
		) {
			setFilteredTopics(filteredTopics);
			return;
		}

		// Filter search term results based on checkbox values
		setFilteredTopics(
			filteredTopics.filter(
				(topic) =>
					topic.transports.includes('All') ||
					(filterEventBridge && topic.transports.includes('EventBridge')) ||
					(filterWebSocket && topic.transports.includes('Websocket')) ||
					(filterProcessAutomation && topic.transports.includes('ProcessAutomation')) ||
					(filterProcessAutomation &&
						filterEventBridge &&
						topic.transports.includes('ProcessAutomation') &&
						topic.transports.includes('EventBridge'))
			)
		);
	}, [filterEventBridge, filterWebSocket, filterProcessAutomation, searchTerm, topics]);

	function schemaToExample(schema: Schema, example: any = {}) {
		if (schema.properties === null || schema.properties === undefined) {
			return {};
		}

		if (schema.description) {
			example['description'] = schema.description;
		}

		Object.entries(schema.properties).forEach(([propertyName, property]) => {
			example[propertyName] = getValue(property);
		});
		return example;
	}

	function tagPreviewTopics(descr: string, visibility: string) {
		if (0 === Visibility.Preview.localeCompare(visibility, 'en', { sensitivity: 'base' })) {
			return '(PREVIEW) ' + descr;
		} else {
			return descr;
		}
	}

	function getFormattedUriValues(urls: string[]) {
		let uriList: string[] = [];
		if (!urls || urls.length <= 0) {
			uriList.push('No related public API resource');
			return uriList;
		}

		urls.forEach((uri: string) => {
			uriList.push(uri);
		});

		return uriList;
	}

	function getNewFormattedPermissions(permissionInfo: Permissions) {
		if (!permissionInfo.permissionDetails || permissionInfo.permissionDetails.length <= 0) {
			return getOldFormattedPermissions(permissionInfo);
		} else {
			return getFormattedPermissions(permissionInfo.permissionDetails);
		}
	}

	//Refer to previous logic
	function getFormattedPermissions(permissionDetails: PermissionDetails[]) {
		let formattedPermissions: RequiredPermissions = {
			permission: '',
			permissionList: [],
			enforced: false,
		};
		if (!permissionDetails || permissionDetails.length <= 0) {
			formattedPermissions.permission = 'No required permissions';
			return formattedPermissions;
		}

		permissionDetails.forEach((detail: PermissionDetails) => {
			switch (detail.type) {
				case 'requiresCurrentUser': {
					formattedPermissions.enforced = detail.enforced;
					formattedPermissions.permission = 'Requires current user';
					break;
				}
				case 'requiresPermissions': {
					formattedPermissions.enforced = detail.enforced;
					formattedPermissions.permission = 'Requires permissions:';

					detail.permissions.forEach((p: string) => {
						formattedPermissions.permissionList.push(p);
					});
					break;
				}
				case 'requiresDivisionPermissions': {
					if (detail.allowsCurrentUser) {
						formattedPermissions.permission = 'Requires current user OR these divisional permissions:';
					} else {
						formattedPermissions.permission = 'Subscriber must match division of {id} and have these permissions:';
					}
					formattedPermissions.enforced = detail.enforced;

					detail.permissions.forEach((p: string) => {
						formattedPermissions.permissionList.push(p);
					});

					break;
				}
				case 'requiresAnyDivisionPermissions': {
					formattedPermissions.enforced = detail.enforced;

					formattedPermissions.permission = 'Requires the following permissions in any division:';

					detail.permissions.forEach((p: string) => {
						formattedPermissions.permissionList.push(p);
					});

					break;
				}
				default:
					formattedPermissions.permission = 'No required permissions';
			}
		});
		return formattedPermissions;
	}

	//Refer to previous logic
	function getOldFormattedPermissions(permissionInfo: Permissions) {
		let formattedPermission: RequiredPermissions = {
			permission: '',
			permissionList: [],
			enforced: permissionInfo.enforced,
		};

		if (!permissionInfo.requiresPermissions || permissionInfo.requiresPermissions.length <= 0) {
			if (permissionInfo.requiresCurrentUser) {
				formattedPermission.permission = 'Requires current user';
				return formattedPermission;
			} else {
				formattedPermission.permission = 'No required permissions';
				return formattedPermission;
			}
		}
		if (permissionInfo.requiresDivisionPermissions) {
			if (permissionInfo.requiresCurrentUserOrPermission) {
				formattedPermission.permission = 'Requires current user OR these divisional permissions:';
			} else {
				formattedPermission.permission = 'Subscriber must match division of {id} and have these permissions: ';
			}
		}

		permissionInfo.requiresPermissions.forEach((p: string) => {
			formattedPermission.permissionList.push(p);
		});
		return formattedPermission;
	}

	//Parser for schema logic
	function getValue(property: any) {
		switch (property.type.toLowerCase()) {
			case 'object': {
				if (property.properties) {
					return schemaToExample(property);
				} else if (property.description) {
					return { type: 'object', description: property.description };
				} else return 'object';
			}

			case 'array':
				return [schemaToExample(property.items)];

			case 'string': {
				if (property.enum) {
					return { enum: property.enum, description: property.description };
				} else if (property.description) {
					return { type: 'string', description: property.description };
				} else return 'string';
			}

			case 'number':
			case 'integer':
				if (property.description) {
					return { type: property.type, description: property.description };
				} else return 0;

			case 'boolean':
				if (property.description) {
					return { type: property.type, description: property.description };
				} else return true;
			default:
				return 'unknown';
		}
	}

	return (
		<div className="topics-container">
			<DxTextbox
				inputType="text"
				label="Topic Search"
				placeholder="Type any part of a topic id or description"
				icon={GenesysDevIcons.AppSearch}
				clearButton={true}
				onChange={setSearchTerm}
			/>

			<div className="transport-filter">
				<DxCheckbox
					label="WebSocket"
					itemValue="Websocket"
					className="filter-Websocket"
					checked={filterWebSocket}
					onCheckChanged={setFilterWebSocket}
				/>
				<DxCheckbox
					label="Event Bridge"
					itemValue="EventBridge"
					className="filter-EventBridge"
					checked={filterEventBridge}
					onCheckChanged={setFilterEventBridge}
				/>
				<DxCheckbox
					label="Process Automation"
					itemValue="ProcessAutomation"
					className="filter-ProcessAutomation"
					checked={filterProcessAutomation}
					onCheckChanged={setFilterProcessAutomation}
				/>
			</div>

			<div className="headings-container">
				<h4>Topic</h4>
				<h4 className="description">Description</h4>
			</div>

			{topics ? (
				filteredTopics.length > 0 ? (
					filteredTopics.map((notification: Topic) => <TopicTile topic={notification} key={notification.id} />)
				) : (
					<em>No topics match filter critera</em>
				)
			) : (
				<LoadingPlaceholder text="Loading topics" />
			)}
		</div>
	);
}

export default NotificationTool;
