import React, { useEffect, useState, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { DxAccordion, DxItemGroup, DxButton, DxToggle, DxItemGroupItem } from 'genesys-react-components';
import axios, { CancelTokenSource } from 'axios';
import { GenesysDevIcons, GenesysDevIcon } from 'genesys-dev-icons';

import LoadingPlaceholder from '../../loadingplaceholder/LoadingPlaceholder';
import AppSettings from '../../../helpers/settings/AppSettings';
import { selectedAccountAtom } from '../../../helpers/atoms/AccountsAtom';
import CodeFence from '../../codefence/CodeFence';
import AlertBlock from '../../markdown/alertblock/AlertBlock';
import { addToast, ToastType } from '../../../helpers/atoms/ToastAtom';
import ChatDataForm from './ChatDataForm';
import { SavedWebChatData, ChatData } from '../../../types';
import { WebChatSettingsData } from '../../../types';
import { Models } from '../../../helpers/platformapi/PlatformAPITypes';
import Tooltip from '../../tooltip/Tooltip';

import { loadQueues, loadDeployments, createDeployment } from './helperFunctions';

import './WebChatV1.scss';

export enum WebchatVersion {
	ONE = 'one',
	TWO = 'two',
	MESSENGER = 'messenger',
}

interface ChatConfigV1 {
	webchatAppUrl: string;
	webchatServiceUrl: string;
	orgId?: string;
	orgGuid?: string;
	orgName?: string;
	queueName: string;
	logLevel: string;
	locale: string;
	data: ChatData;
	companyLogo: Asset;
	companyLogoSmall: Asset;
	agentAvatar: Asset;
	welcomeMessage: string;
	cssClass: string;
	css: CssProperty;
}

interface IframeParameters {
	region?: string;
	orgGuid?: string;
	chatConfig?: ChatConfigV1;
	openInNewWindow?: boolean;
	deploymentKey?: string;
	chatEnv?: string;
}

interface Asset {
	width: number;
	height: number;
	url: string;
}

interface CssProperty {
	width: string;
	height: string;
}

function WebChatV1() {
	const [configDisabled, setConfigDisabled] = useState(false);
	const [selectedAccount] = useRecoilState(selectedAccountAtom);
	const [queueItems, setQueueItems] = useState<DxItemGroupItem[]>([]);
	const [deployemtItems, setDeploymentItems] = useState<DxItemGroupItem[]>([]);
	const [chatInitiated, setChatInitiated] = useState(false);
	const [loadingDeployments, setLoadingDeployments] = useState(false);
	const [loadingConfigData, setLoadingConfigData] = useState(true);
	const [openInNewWindow, setOpenInNewWindow] = useState(false);
	const [selectedDeployment, setSelectedDeployment] = useState('');
	const [selectedQueue, setSelectedQueue] = useState('');
	const [permissions, setPermissions] = useState('');
	const [collectedChatData, setCollectedChatData] = useState<ChatData>({});
	const [chatDataToBeSaved, setChatDataToBeSaved] = useState<ChatData>({});
	const [iframeUrlParam, setIframeUrlParam] = useState<IframeParameters>({});
	const cancelToken = useRef<CancelTokenSource | undefined>();
	const savedData = useRecoilValue(AppSettings.webChatDataAtom());
	const savedSettings = useRecoilValue(AppSettings.webChatSettingsAtom());

	let chatEnvTag = '';
	let chatEnv = '';

	let chatConfig: ChatConfigV1 = {
		webchatAppUrl: `https://apps.${selectedAccount?.region}/webchat`,
		webchatServiceUrl: `https://realtime.${selectedAccount?.region}:443`,
		orgId: selectedAccount?.me?.organization?.thirdPartyOrgId,
		orgGuid: selectedAccount?.me?.organization?.id,
		orgName: selectedAccount?.me?.organization?.thirdPartyOrgName,
		queueName: selectedQueue,
		logLevel: 'DEBUG',
		locale: collectedChatData.locale || 'en',
		data: collectedChatData,
		companyLogo: {
			width: 600,
			height: 149,
			url: `${window.location.origin}/assets/Genesys-Cloud-CX-Logo-Color.png`,
		},
		companyLogoSmall: {
			width: 25,
			height: 25,
			url: `${window.location.origin}/assets/companylogo.png`,
		},
		agentAvatar: {
			width: 462,
			height: 462,
			url: `${window.location.origin}/assets/agent.jpg`,
		},
		welcomeMessage: collectedChatData.welcomeMessage || 'Thanks for chatting using the dev tools chat page',
		cssClass: 'webchat-frame',
		css: {
			width: '100%',
			height: '480px',
		},
	};

	useEffect(() => {
		if (!selectedAccount) return;

		setChatInitiated(false);

		let permissionsErr = '';

		const tokenSource = axios.CancelToken.source();
		cancelToken.current = tokenSource;

		if (!selectedAccount.me?.authorization) {
			addToast({
				title: 'Authorization Error',
				message:
					'Unable to validate permissions. You may not see deployments or queues in the dropdowns. You\'re probably missing the permission "authorization:grant:view".',
				toastType: ToastType.Critical,
			});
			return;
		}

		let hasReadDeployment = false;
		let hasViewQueues = false;

		selectedAccount.me?.authorization?.permissions?.forEach((p: string) => {
			// Must check for startswith because of how permissions look with divisions. Can't use exact match here.
			if (p.startsWith('webchat:deployment:read')) hasReadDeployment = true;
			if (p.startsWith('routing:queue:view')) hasViewQueues = true;
		});

		// Set error messages
		if (!hasReadDeployment) permissionsErr += 'webchat:deployment:read,\n';
		if (!hasViewQueues) permissionsErr += 'routing:queue:view';
		setPermissions(permissionsErr);

		setLoadingConfigData(true);

		/**Wait for both deployments and queues to load and process results
		 If there are saved config then they gets applied, if not user has to select new config**/
		Promise.all([loadDeployments(cancelToken.current, WebchatVersion.ONE), loadQueues(cancelToken.current)])
			.then((res: any) => {
				const deployments = res[0];
				const queues = res[1];

				let deploymentItems: DxItemGroupItem[] = deployments.map((d: Models.WebChatDeployment) => {
					return { label: d.name, value: d.id };
				});

				let queueItems: DxItemGroupItem[] = queues.map((q: Models.Queue) => {
					return { label: q.name, value: q.id };
				});

				if (savedSettings.version1 && savedSettings.version1.selectedQueue !== '' && savedSettings.version1.selectedDeployment !== '') {
					deploymentItems = [];
					queueItems = [];
					deployments.forEach((d: Models.WidgetDeployment) => {
						if (d.name && d.id) {
							if (savedSettings.version1.selectedDeployment === d.id) {
								deploymentItems.push({ label: d.name, value: d.id, isSelected: true });
								return;
							}
							deploymentItems.push({ label: d.name, value: d.id });
						}
					});

					if (deploymentItems.length === 0 || !deploymentItems.find((d) => d.isSelected)) {
						deploymentItems.unshift({ label: '--- Select a deployment ---', value: '', disabled: true, isSelected: true });
						setDeploymentItems(deploymentItems);
					} else {
						if (savedSettings?.version1?.selectedDeployment) setSelectedDeployment(savedSettings.version1.selectedDeployment || '');
					}

					queues.forEach((q: Models.Queue) => {
						if (q.name && q.id) {
							if (savedSettings.version1.selectedQueue === q.name) {
								queueItems.push({ label: q.name, value: q.id, isSelected: true });
								return;
							}
							queueItems.push({ label: q.name, value: q.id });
						}
					});

					if (queueItems.length === 0 || !queueItems.find((q) => q.isSelected)) {
						queueItems.unshift({ label: '--- Select a queue ---', value: '', disabled: true, isSelected: true });
						setQueueItems(queueItems);
					} else {
						if (savedSettings?.version1?.selectedQueue) setSelectedQueue(savedSettings.version1.selectedQueue || '');
					}

					setDeploymentItems(deploymentItems);
					setQueueItems(queueItems);
				} else {
					deploymentItems.unshift({ label: '--- Select a deployment ---', value: '', disabled: true, isSelected: true });
					setDeploymentItems(deploymentItems);

					queueItems.unshift({ label: '--- Select a queue ---', value: '', disabled: true, isSelected: true });
					setQueueItems(queueItems);
				}

				setLoadingConfigData(false);
			})
			.catch((err) => {
				if (!axios.isCancel(err)) {
					console.error(err);
					addToast({ title: 'Unable to get deployments/queues', message: err.message, toastType: ToastType.Critical });
					setLoadingConfigData(false);
				}
			});

		window.addEventListener('message', getIframeEvents);

		return () => {
			cancelToken.current?.cancel('Component unmounted');
			window.removeEventListener('message', getIframeEvents);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedAccount]);

	if (!selectedAccount) {
		return (
			<div className="warning-container">
				<GenesysDevIcon className="icon" icon={GenesysDevIcons.AppInfoSolid} />
				<span> Please add an account to utilize this tool</span>
			</div>
		);
	}

	const getIframeEvents = (event: any) => {
		const message = event.data.message;
		if (event.data.value === 'chat-ended') {
			setChatInitiated(false);
			setConfigDisabled(false);
		}

		if (message === 'iframe-error') {
			addToast({ title: 'Error starting chat', message: event.data.value.message, toastType: ToastType.Critical });
			setChatInitiated(false);
			setConfigDisabled(false);
		}
	};

	async function createNewDeployment() {
		try {
			setLoadingDeployments(true);
			setChatInitiated(false);
			const newDeployment = (await createDeployment(cancelToken.current, WebchatVersion.ONE)) as Models.WidgetDeployment;

			const deployments: Models.WidgetDeployment[] = (await loadDeployments(
				cancelToken.current,
				WebchatVersion.ONE
			)) as Models.WidgetDeployment[];

			//Filter the new deployment out of the deployments array
			const filteredDeployments = deployments.filter((d: Models.WidgetDeployment) => d.id !== newDeployment.id);

			const deploymentItems: DxItemGroupItem[] = filteredDeployments.map((deployment: Models.WidgetDeployment) => {
				return { label: deployment.name || '', value: deployment.id || '' };
			});

			deploymentItems.unshift({ label: newDeployment.name || '', value: newDeployment.id || '', isSelected: true });

			setDeploymentItems(deploymentItems);

			setSelectedDeployment(newDeployment.id || '');

			setLoadingDeployments(false);

			addToast({
				title: 'New web chat deployment created',
				toastType: ToastType.Success,
				message: `Created webchat deployment: ${newDeployment.name}`,
			});
		} catch (error: any) {
			setLoadingDeployments(false);
			addToast({ title: 'Failed to create new deployment', toastType: ToastType.Critical, message: error.message });
		}
	}

	const getChatregion = (environment: string | undefined) => {
		if (!environment || environment === '') return '';

		if (environment.endsWith('.jp')) return 'ap-northeast-1';
		else if (environment.endsWith('.com.au')) return 'ap-southeast-2';
		else if (environment.endsWith('.ie')) return 'eu-west-1';
		else if (environment.endsWith('.de')) return 'eu-central-1';
		else if (environment.endsWith('mypurecloud.com')) return 'us-east-1';
		else if (environment.endsWith('usw2.pure.cloud')) return 'us-west-2';
		else if (environment.endsWith('cac1.pure.cloud')) return 'ca-central-1';
		else if (environment.endsWith('apne2.pure.cloud')) return 'ap-northeast-2';
		else if (environment.endsWith('euw2.pure.cloud')) return 'eu-west-2';
		else if (environment.endsWith('use2.us-gov-pure.cloud')) return 'us-east-2';
		else if (environment.endsWith('sae1.pure.cloud')) return 'sa-east-1';
		else if (environment.endsWith('aps1.pure.cloud')) return 'ap-south-1';
		else if (environment.includes('tca')) {
			chatEnv = 'test';
			chatEnvTag = '\n  env="test"';
			return 'us-east-1';
		} else if (environment.includes('dca')) {
			chatEnv = 'dev';
			chatEnvTag = '\n  env="dev"';
			return 'us-east-1';
		} else {
			//Default
			return 'us-east-1';
		}
	};

	function saveChatData() {
		const newSettings: WebChatSettingsData = {
			selectedDeployment: selectedDeployment,
			selectedQueue: selectedQueue,
		};

		AppSettings.setWebChatSettings(newSettings, WebchatVersion.ONE);

		if (!chatDataToBeSaved.firstName || !chatDataToBeSaved.lastName) {
			return;
		}
		let savedChatData = [...savedData];

		let newData: SavedWebChatData = {
			id: chatDataToBeSaved.id || '',
			name: `${chatDataToBeSaved.lastName.trim()}-${chatDataToBeSaved.firstName.trim()}`,
			data: chatDataToBeSaved,
		};

		const dataExists = savedChatData.find((sd: SavedWebChatData) => sd.id === newData.id);

		if (dataExists) {
			const updatedData = savedChatData.filter((sd) => sd.id !== newData.id);
			updatedData.unshift(newData);
			AppSettings.setWebChatData(updatedData);
			return;
		}

		savedChatData.unshift(newData);

		AppSettings.setWebChatData(savedChatData);
	}

	function startChat() {
		if (selectedDeployment === '' || selectedQueue === '') {
			addToast({
				title: 'Unable to start chat ',
				message: 'Missing configuration(s): please select a deployment and queue',
				toastType: ToastType.Critical,
			});
			return;
		}
		const newIframeUrlParam: IframeParameters = {
			region: selectedAccount?.region,
			orgGuid: selectedAccount?.me?.organization?.id,
			deploymentKey: selectedDeployment,
			openInNewWindow: openInNewWindow,
			chatEnv: chatEnv,
			chatConfig: chatConfig,
		};

		setIframeUrlParam(newIframeUrlParam);
		setChatInitiated(true);
		setConfigDisabled(true);
		saveChatData();
	}

	function updateChatData(chatData: ChatData) {
		let customAttr = [];
		let temp = { ...chatData };

		setChatDataToBeSaved({ ...chatData });

		delete temp['id'];

		if (temp['customAttr']) {
			customAttr = temp['customAttr'];
			delete temp['customAttr'];
		}

		for (const a of customAttr) {
			temp[a.name] = a.value;
		}

		setCollectedChatData(temp);
	}

	const config = `const chatConfig = ${JSON.stringify(chatConfig, null, 2)};
ININ.webchat.create(chatConfig, function(err, webchat) {
	if (err) {
	  console.error(err);
	  throw err;
	}
	webchat.renderPopup({
	  width: 400,
	  height: 400,
	  title: 'Chat'
	});
  });
}`;

	const scriptTag = `<script
	id="purecloud-webchat-js" type="text/javascript"
	src="https://apps.${selectedAccount?.region}/webchat/jsapi-v1.js"
	region="${getChatregion(selectedAccount?.region)}"
	org-guid="${selectedAccount.me?.organization?.id}"
	deployment-key="${selectedDeployment}"${chatEnvTag}>
</script>`;

	return (
		<div className="webchatV1-container">
			<div>
				To get started, follow these instructions:
				<ul>
					<li>Ensure that you are a member of a queue and also on-queue.</li>
					<li>
						Select a widget deployment or create a new one if there is none available. You can create a new deployment by using the button
						beside the deployments dropdown.
					</li>
					<li>Select a queue that you are currently on-queue.</li>
					<li>
						If you want to open the chat in a new window, select the toggle below the deployments dropdown. Note: make sure your browser is
						not disabling popups when you want to open chat in a new window.
					</li>
					<li>
						After selecting preferred configurations, you can go ahead and fill the chat data forms but that is not required. You can also
						use the populate fields button to fill the forms with random user data.
					</li>
					<li>Click the start chat button to initiate chat.</li>
					<li>
						Note that if chat data is provided, it will be automatically saved when the chat gets started and applied when the page
						refreshes. Chat data will not be saved if first name and last name are not present{' '}
					</li>
				</ul>
				See the <a href="/commdigital/digital/webchat/">web chat </a>documentation for more details
			</div>
			<DxAccordion title="Chat configuration (required)" showOpen={true}>
				{permissions ? (
					<AlertBlock
						title="You do not have the required permission(s) to continue to use this tool"
						alertType="critical"
						children={`Missing Permission(s): ${permissions}`}
					/>
				) : (
					<React.Fragment>
						{loadingConfigData || loadingDeployments ? (
							<LoadingPlaceholder text="Loading Chat Config" />
						) : (
							<React.Fragment>
								<div className="chat-configuration">
									<div>
										<DxItemGroup
											format="dropdown"
											items={deployemtItems}
											title="Deployments"
											description="Select a deployment or create a new one"
											onItemChanged={(item: DxItemGroupItem) => setSelectedDeployment(item.value)}
											disabled={configDisabled}
										/>
									</div>

									<div className="new-deployment-icon-container">
										<Tooltip text="Create New deployment">
											<GenesysDevIcon className="new-deployment-icon" onClick={createNewDeployment} icon={GenesysDevIcons.AppPlusStroke} />
										</Tooltip>
									</div>

									<div>
										<DxItemGroup
											format="dropdown"
											items={queueItems}
											title="Queues"
											description="Select a queue"
											onItemChanged={(item: DxItemGroupItem) => setSelectedQueue(item.label)}
											disabled={configDisabled}
										/>
									</div>
								</div>

								<DxToggle
									label="Open Chat in New Window?"
									initialValue={openInNewWindow}
									onChange={(value) => {
										if (value !== undefined) setOpenInNewWindow(value);
									}}
									description="Opens chat in new window when toggled"
									className="webchat-toggle"
								/>
								{openInNewWindow && (
									<AlertBlock title="Make sure popup blockers are disabled" className="toggle-warning" alertType="warning">
										<span>
											Popup blockers may prevent the new chat window from being opened. Make sure they are disabled before starting the
											chat.
										</span>
									</AlertBlock>
								)}
							</React.Fragment>
						)}
					</React.Fragment>
				)}
			</DxAccordion>
			<ChatDataForm onValueUpdated={updateChatData} />
			<div className="start-chat-button-container">
				<DxButton className="start-chat-button" onClick={startChat} disabled={!!permissions}>
					Start Chat
				</DxButton>
			</div>
			<DxAccordion title="Deployment Script Tag">
				<CodeFence title="Script Tag" value={scriptTag} language="html" />
			</DxAccordion>
			<DxAccordion title="Chat Code">
				<CodeFence title="Chat Code" value={config} language="javascript" />
			</DxAccordion>

			{chatInitiated ? (
				<>
					<iframe
						id="webchat-frame"
						src={`/webchatV1.html?env=${iframeUrlParam.region}&region=${encodeURIComponent(getChatregion(iframeUrlParam.region))}&orgGuid=${
							iframeUrlParam.orgGuid
						}&deploymentKey=${iframeUrlParam.deploymentKey}&openInNewWindow=${iframeUrlParam.openInNewWindow}&chatEnv=${
							iframeUrlParam.chatEnv
						}&chatData=${encodeURIComponent(JSON.stringify(iframeUrlParam.chatConfig))}`}
						title="Webchat"
					></iframe>
					<DxButton
						onClick={() => {
							setChatInitiated(false);
							setConfigDisabled(false);
						}}
						className="resetConfigButton"
					>
						Reset Configuration
					</DxButton>
				</>
			) : (
				''
			)}
		</div>
	);
}

export default WebChatV1;
