import React, { LegacyRef, useRef, useState } from 'react';
import PageContent from '../core/PageContent';

import Breadcrumb from '../breadcrumb/Breadcrumb';
import SiteNavigation from './anemia/sitenavigation/SiteNavigation';
import Header from './anemia/header/Header';
import SiteBanner from './anemia/sitebanner/SiteBanner';
import Footer from './anemia/footer/Footer';
import InPageNav from './anemia/inpagenav/InPageNav';
import { useRecoilValue } from 'recoil';
import { inPageHeadingsAtom } from '../../helpers/atoms/inPageHeadings';
import Toolbox from './anemia/toolbox/Toolbox';
import { inPageFiltersAtom } from '../../helpers/atoms/InPageFilters';
import InPageFilters from './anemia/inpagefilters/InPageFilters';
import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';
import { selectedThemeAtom } from '../../theme/ThemeAtom';

import './BootstrapBackfill.scss';
import './anemia/typography.scss';
import './anemia/movethisstuff.scss';
import './DefaultLayout2021.scss';
import '../../theme/themes.scss';
import AppSettings from '../../helpers/settings/AppSettings';

export default function DefaultLayout2021() {
	const headings = useRecoilValue(inPageHeadingsAtom);
	const filters = useRecoilValue(inPageFiltersAtom);
	const [showZoomZoom, setShowZoomZoom] = useState(false);
	const divRef = useRef<LegacyRef<HTMLDivElement>>();
	const theme = useRecoilValue(selectedThemeAtom());
	const isNavCollapsed = useRecoilValue(AppSettings.navigationCollapsedAtom());
	const isSidebarCollapsed = useRecoilValue(AppSettings.sidebarCollapsedAtom());

	// Determine sidebar content in order of importance
	let sidebarContent;
	if (filters) {
		sidebarContent = <InPageFilters />;
	} else if (headings) {
		sidebarContent = <InPageNav headings={headings} />;
	}

	return (
		<div className={`layout default-layout disappearing-scrollbars ${theme}`}>
			<SiteBanner />
			<Header />
			<div
				id="layout-body-div"
				className={`layout-body${isNavCollapsed ? ' nav-collapsed' : ''}${
					sidebarContent ? (isSidebarCollapsed ? ' sidebar-collapsed' : ' sidebar-visible') : ''
				}`}
				ref={divRef as any}
				onScroll={(event) =>
					(event.target as Element).id === 'layout-body-div' && setShowZoomZoom((event.target as Element).scrollTop > 200)
				}
			>
				<div className="layout-navigation">
					<GenesysDevIcon
						icon={isNavCollapsed ? GenesysDevIcons.AppChevronRight : GenesysDevIcons.AppChevronLeft}
						className="nav-collapse-icon"
						onClick={() => AppSettings.setNavigationCollapsed(!isNavCollapsed)}
					/>
					<SiteNavigation />
				</div>
				<div className={`layout-content`}>
					<Breadcrumb />
					<PageContent />
					<Footer />
					{showZoomZoom ? (
						<GenesysDevIcon
							className="zoomzoom"
							icon={GenesysDevIcons.AppZoomZoomUp}
							onClick={() => (divRef.current as any).scrollTo(0, 0)}
						/>
					) : undefined}
				</div>
				{sidebarContent && (
					<div className="layout-sidebar">
						<GenesysDevIcon
							icon={isSidebarCollapsed ? GenesysDevIcons.AppChevronLeft : GenesysDevIcons.AppChevronRight}
							className="sidebar-collapse-icon"
							onClick={() => AppSettings.setSidebarCollapsed(!isSidebarCollapsed)}
						/>
						<div className="layout-sidebar-content">{sidebarContent}</div>
					</div>
				)}
			</div>
			<Toolbox />
		</div>
	);
}
