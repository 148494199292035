import { useHistory } from 'react-router-dom';
import { History } from 'history';

let history: History<unknown>;

// This component should be added to App inside BrowserRouter so it can obtain a reference to the history object
export default function HistoryAccess() {
	history = useHistory();
	return null;
}

// This function can be imported by non-component classes to obtain access to the history object
export function GetHistory() {
	return history;
}
