/*
 *
 * SearchBar.tsx
 *
 */

export interface ISearchProps {
	enableHotkey?: boolean;
}

export interface SearchResult {
	id: string;
	title: string;
	url: string;
	origin?: Origin;
	excerptText?: string;
	excerptHighlights?: number[][];
	documentType?: string;
	indexId: string;
	queryId: string;
	relevanceFeedbackSubmitted?: boolean;
}

export interface SearchSuggestion {
	text: string;
	highlights?: number[][];
	type: SuggestionType;
}

export interface AttributeFilter {
	key: string;
	value: string;
}

export interface AttributeFilterGroup {
	type: FilterType;
	attributeFilters: (AttributeFilter|AttributeFilterGroup)[];
}

export interface Relevance {
	duration?: string;
	freshness?: boolean;
	rankOrder?: string;
	importance?: number;
	valueImportanceMap?: any;
}

export interface DocumentRelevanceOverrideConfiguration {
	name: string;
	relevance: Relevance;
}

export interface SearchRequest {
	queryText: string;
	includeSpellCheckSuggestions?: boolean;
	attributeFilterGroups?: AttributeFilterGroup[];
	documentRelevanceOverrideConfigurations?: DocumentRelevanceOverrideConfiguration[];
	visitorId?: string;
}

export interface PostFeedbackRequest {
    indexId?: string;
    queryId?: string;
    clickFeedbackItems?: ClickFeedbackItemRequest[];
    relevanceFeedbackItems?: RelevanceFeedbackItemRequest[];
}

export interface ClickFeedbackItemRequest {
    resultId?: string;
    clickTime?: string;
}

export interface RelevanceFeedbackItemRequest {
    resultId?: string;
    relevanceValue?: RelevanceValueRequest;
}

export enum RelevanceValueRequest {
    RELEVANT = "RELEVANT",
    NOT_RELEVANT = "NOT_RELEVANT"
}

export function getRelevanceValue(isRelevant: boolean) {
	if (isRelevant) {
		return RelevanceValueRequest.RELEVANT
	} else {
		return RelevanceValueRequest.NOT_RELEVANT
	}
}

/* REST models */
export interface Highlight {
	BeginOffset: number;
	EndOffset: number;
	TopAnswer?: boolean;
	Type?: string;
}

export interface DocumentTitle {
	Highlights: Highlight[];
	Text: string;
}

export interface ScoreAttributes {
	ScoreConfidence: string;
}

export interface DocumentExcerpt {
	Highlights: Highlight[];
	Text: string;
}

export interface DocumentAttributeValue {
	DateValue: number;
	LongValue: number;
	StringListValue: string[];
	StringValue: string;
}

export interface TextWithHighlightsValue {
	Highlights: Highlight[];
	Text: string;
}

export interface AdditionalAttributeValue {
	TextWithHighlightsValue: TextWithHighlightsValue;
}

export interface DocumentAttribute {
	Key: string;
	Value: DocumentAttributeValue;
	ValueType: string;
}

export interface AdditionalAttribute {
	Key: string;
	Value: AdditionalAttributeValue;
}

export interface ResultItem {
	Id: string;
	DocumentId?: string;
	DocumentTitle?: DocumentTitle;
	DocumentUri?: string;
	FeedbackToken?: string;
	ScoreAttributes?: ScoreAttributes;
	DocumentExcerpt?: DocumentExcerpt;
	DocumentAttributes?: DocumentAttribute[];
	AdditionalAttributes?: AdditionalAttribute[];
	Type?: string;
	QueryId: string;
	IndexId: string;
	RelevanceFeedbackSubmitted?: boolean;
}

export interface Correction {
	BeginOffset: number;
	CorrectedTerm: string;
	EndOffset: number;
	Term: string;
}

export interface SpellCorrectedQuery {
	Corrections?: Correction[];
	SuggestedQueryText?: string;
}

export interface QueryResults {
	QueryId: string;
	ResultItems?: ResultItem[];
	SpellCorrectedQueries?: SpellCorrectedQuery[];
}

export interface SuggestionValue {
	Text?: TextWithHighlightsValue;
}

export interface Suggestion {
	Id: string;
	Value?: SuggestionValue;
}

export interface QuerySuggestions {
	QuerySuggestionId: string;
	Suggestions?: Suggestion[];
}

export interface QueryResponse {
	QueryResults?: QueryResults;
	QuerySuggestions?: QuerySuggestions;
}

export interface OriginMap {
	[origin: string]: string;
}

export interface ContentTypeMap {
	[contentType: string]: string;
}

export interface FilterMap {
	[filter: string]: boolean;
}

export interface TopicMap {
	[topic: string]: string;
}

/* enums */

export enum Origin {
	DEVELOPER_CENTER = 'developer-center',
	RESOURCE_CENTER = 'resource-center',
}

export enum HomeContentType {
	BLOG = 'blog',
	BLUEPRINT = 'blueprint',
	GUIDE = 'guide',
	TECH_REF = 'techref',
	SDK_DOC = 'sdkdoc',
	OTHER = 'other',
}

export enum HomeTopic {
	ANALYTICS_DATA_MANAGEMENT = 'analyticsdatamanagement',
	AUTHORIZATION = 'authorization',
	BILLING = 'billing',
	COMM_DIGITAL = 'commdigital',
	GDPR_PRIVACY = 'gdprprivacy',
	NOTIFICATIONS_ALERTS = 'notificationsalerts',
	ORGANIZATION = 'organization',
	PLATFORM = 'platform',
	ROUTING = 'routing',
	TELEPHONY = 'telephony',
	USER_AGENT_MAN = 'useragentman',
}

export enum HelpContentType {
	ARTICLE = 'article',
	FAQ = 'faq',
}

export enum FilterType {
	AND = 'and',
	OR = 'or',
	NOT = 'not',
}

export enum SuggestionType {
	SUGGESTION = 'suggestion',
	HISTORY = 'history',
	SPELL_CHECK = 'spell-check',
}

/* global */

export const originMap: OriginMap = {
	'resource-center': 'Resource Center',
	'developer-center': 'Developer Center',
};

export const contentTypeMap: ContentTypeMap = {
	blog: 'Dev Blogs',
	blueprint: 'Blueprints',
	guide: 'Guides',
	techref: 'Technical Reference',
	sdkdoc: 'SDK Documentation',
	other: 'Other',
	article: 'Articles',
	faq: 'FAQ',
};

export const topicMap: TopicMap = {
	analyticsdatamanagement: 'Analytics & Data Management',
	authorization: 'Authorization',
	billing: 'Billing',
	commdigital: 'Communication & Digital Channels',
	gdprprivacy: 'GDPR & Privacy',
	notificationsalerts: 'Notifications & Alerts',
	organization: 'Organization',
	platform: 'Platform',
	routing: 'Routing & Conversation Handling',
	telephony: 'Telephony',
	useragentman: 'User & Agent Management',
};

/**
 *
 * SearchTools.tsx
 *
 */

export interface FilterUpdate<T> {
	newFilters: FilterMap;
	newFilterVal: boolean;
	filter: T | undefined;
}

export interface HighlightLimits {
	startIndex: number;
	endIndex: number;
}

/**
 *
 * SearchAtom.ts
 *
 */

export interface SearchCache {
	searchTerm: string;
	sourceFilters: FilterMap;
	homeContentFilters: FilterMap;
	homeTopicFilters: FilterMap;
	helpContentFilters: FilterMap;
	topicFiltersEnabled: boolean;
	queryResponse: QueryResponse;
	isSuggestionRemoved?: boolean;
	timestamp: number;
}

export interface RecentSearch {
	searchTerm: string;
	searchCount: number;
	lastSearchedDate: number;
}

export interface SearchFilterConfig {
	sourceFilters: FilterMap;
	homeContentFilters: FilterMap;
	homeTopicFilters: FilterMap;
	helpContentFilters: FilterMap;
	topicFiltersEnabled: boolean;
	changeInitiator: string | undefined;
}

export interface SearchData {
	id: string;
	results: SearchResult[] | undefined;
	searchSuggestions: SearchSuggestion[] | undefined;
	autocompleteText: string | undefined;
	isLoading: boolean | undefined;
}

export interface PartialSearchData {
	id: string;
	results?: SearchResult[] | undefined;
	searchSuggestions?: SearchSuggestion[] | undefined;
	autocompleteText?: string | undefined;
	isLoading?: boolean | undefined;
}
