import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { DxItemGroup, DxTextbox, DxButton } from 'genesys-react-components';

import { subscribe } from '../../../helpers/atoms/ChannelAtoms';
import { selectedAccountAtom } from '../../../helpers/atoms/AccountsAtom';
import { MinimalTopic } from './notificationtopics/NotificationDefinitions';
import { isWebSocketCompatible } from './notificationtopics/TopicContent';

import './TopicParameterEditor.scss';

interface Iprops {
	topic: MinimalTopic[];
	channelId: string;
}

export default function TopicParameterEditor(props: Iprops) {
	const [selectedtopic, setSelectedTopic] = useState('');
	const [selectedAccount] = useRecoilState(selectedAccountAtom);
	const { topic, channelId } = props;

	let editParameters;
	const userPermissions = selectedAccount?.me?.authorization?.permissions;

	if (selectedtopic) {
		editParameters = (
			<div>
				<DxTextbox inputType="text" className="parametereditor-input-field" value={selectedtopic} onChange={setSelectedTopic} />
				<DxButton type="primary" className="subscribe-btn" onClick={() => subscribe(channelId, selectedtopic)}>
					Subscribe{' '}
				</DxButton>
			</div>
		);
	}

	function checkPermission(permission: string) {
		if (userPermissions) {
			for (const p of userPermissions) {
				if (p.startsWith(permission)) {
					return true;
				}
			}
		}

		return false;
	}

	function checkAuthorization(permissions?: string[], enforced?: boolean) {
		if (permissions === undefined || enforced === undefined) {
			return false;
		}

		if (permissions.length === 0) {
			return true;
		}

		if (enforced === false) {
			return true;
		}
		return permissions.every(checkPermission);
	}

	return (
		<div className="model-container">
			<div>
				<DxItemGroup
					format="dropdown"
					className="parametereditor-input-field"
					onItemChanged={(item) => setSelectedTopic(item.value)}
					items={[{ label: 'Select a topic', value: '' }].concat(
						topic.map((t) => {
							return {
								label: t.id,
								value: t.id,
								disabled: !checkAuthorization(t.requiresPermissions, t.enforced) || !isWebSocketCompatible(t),
							};
						})
					)}
				/>
			</div>
			{editParameters}
		</div>
	);
}
