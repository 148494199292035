import React, { useEffect, useState } from 'react';
import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';
import { DxButton } from 'genesys-react-components';

import { NormalizedProperty, OpenAPIDefinition, ValueUpdatedCallback, UpdateSource } from '../../../../../helpers/openapi/OpenAPITypes';
import ModelProperty from './ModelProperty';
import { SchemaToPropertyInfo } from '../../../../../helpers/openapi/OpenAPITools';
import SwaggerCache from '../../../../../helpers/openapi/SwaggerCache';

import './MapContainer.scss';
import Tooltip from '../../../../tooltip/Tooltip';

interface IProps {
	property: NormalizedProperty;
	onValueUpdated?: ValueUpdatedCallback;
	initialValue?: IMapData;
	swagger: OpenAPIDefinition;
	isHeaderContainer?: boolean;
	isNestedMapContainer?: boolean;
	isPrimitiveObject?: boolean;
	updateSource?: UpdateSource;
}

// Internal representation of the data for the editor
interface IMapEditorData {
	key: string;
	value?: any;
}

// Actual representation of the data (i.e. a JSON object used in an API request)
interface IMapData {
	[propertyName: string]: any | undefined;
}

const mapDataToEditorMapData = (property?: NormalizedProperty, mapData?: IMapData): IMapEditorData[] => {
	if (!mapData) return [];
	const isStringToObjectMap =
		property?.schema?.additionalProperties?.type === 'object' &&
		!property?.schema?.additionalProperties?.__modelName &&
		!property?.schema?.additionalProperties?.properties;
		
	return Object.entries(mapData)
		.filter(([key, value]) => {
			const isJsonMismatch: boolean = isStringToObjectMap && typeof value != "object";
			// if the JSON property doesn't match the swagger model, remove it from the editor(s)
			if (isJsonMismatch) {
				console.warn("Request body JSON input does not match the schema. Removing mismatched input from the request body");
				return false;
			}
			return true;
		})
		.map(([key, value]) => {
			return { key, value };
		});
};

export default function MapContainer(props: IProps) {
	const [values, setValues] = useState<IMapEditorData[]>(mapDataToEditorMapData(props.property, props.initialValue));

	// Transform initial value to internal values
	useEffect(() => {
		if (!props.initialValue) return;
		if (
			Object.entries(props.initialValue).every(([key, value]) => {
				const existingValue = values.find((v) => v.key === key);
				// No key match
				if (existingValue === undefined) return false;
				// Yes exact value match
				if (existingValue.value === value) return true;
				// Check for equivelancy by JSON serialization; the resulting payload is ultimately what matters
				if (JSON.stringify(existingValue.value) === JSON.stringify(value)) return true;
				return false;
			})
		)
			return;

		setValues(mapDataToEditorMapData(props.property, props.initialValue));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.initialValue]);

	// Raise external notification on values changed
	useEffect(() => {
		if (props.onValueUpdated) {
			props.onValueUpdated(props.property.propertyName, formatValuesForTransport());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values]);

	// Transforms to JSON data. E.g. [{"key":"keyname","value":"the value"}] to {"keyname":"the value"}
	const formatValuesForTransport = (): IMapData | undefined => {
		const mapData: IMapData = {};
		values.forEach((item) => (mapData[item.key] = item.value));
		// console.log('formatValuesForTransport', JSON.stringify(mapData));
		return Object.keys(mapData).length > 0 ? mapData : undefined;
	};

	const getDefaultValue = (type?: string) => {
		switch (type) {
			case 'object':
				return {};
			case 'array':
				return [];
			default:
				return '';
		}
	};

	const buildElements = () => {
		if (!props.property.schema.additionalProperties && !props.isHeaderContainer && !props.isNestedMapContainer && !props.isPrimitiveObject) return;
		// the value property
		let pVal: NormalizedProperty;
		if (props.property.schema.additionalProperties) {
			// When additionalProperties is set, use whatever type it specifies for the property's value
			pVal = SchemaToPropertyInfo(SwaggerCache.resolveModelRef(props.swagger, props.property.schema.additionalProperties));
		} else if (props.property.schema.type === 'object') {
			// When the property itself is just an object without a specified type for the value, assume it's a string
			// This is a special case to prevent the else case from creating a recursively nested object of objects where you can never actually provide a value.
			pVal = {
				id: '',
				schema: { type: 'string' },
				propertyName: 'value',
				typeDisplay: 'string',
				className: 'string',
			};
		} else {
			// ??? Just pass it through since it's something else
			pVal = props.property;
		}

		// Make sure there's a name to display
		pVal.propertyName = pVal.propertyName || 'value';

		// true if map container has key of type string and value is an object not defined in the swagger definition
		// This prevents the value from rendering as a known definition from swagger named "object", which doesn't exist so it won't have any properties
		const isStringToObjectMap =
			props.property.schema.additionalProperties?.type === 'object' &&
			!props.property.schema.additionalProperties?.__modelName &&
			!props.property.schema.additionalProperties?.properties;
		// the key property
		const pKey = {
			id: `${pVal.id || pVal.propertyName}-key`,
			schema: { type: 'string' },
			propertyName: 'key',
			typeDisplay: 'String',
			className: '',
		};
		return values.map((item, i) => {
			return (
				<div key={i} className="map-contents-container">
					<div className="map-contents">
						<ModelProperty
							property={pKey}
							arrayIndex={i}
							onValueUpdated={(propertyName, value, arrayIndex) => valueUpdated(propertyName, value, arrayIndex, '')}
							initialValue={item.key}
							swagger={props.swagger}
							updateSource={props.updateSource}
						/>
						<ModelProperty
							property={pVal}
							arrayIndex={i}
							onValueUpdated={(propertyName, value, arrayIndex) =>
								valueUpdated(propertyName, value, arrayIndex, getDefaultValue(pVal.schema.type))
							}
							initialValue={item.value}
							swagger={props.swagger}
							isStringToObjectMap={isStringToObjectMap}
							updateSource={props.updateSource}
						/>
					</div>
					<Tooltip text="Remove item">
						<DxButton onClick={() => removeElement(i)} className="remove-icon">
							<GenesysDevIcon icon={GenesysDevIcons.AppTimes} />
						</DxButton>
					</Tooltip>
				</div>
			);
		});
	};

	const removeElement = (position: number) => {
		let newValues = [...values];
		newValues.splice(position, 1);
		setValues(newValues);
	};

	/**
	 * Adds a new map type editor component when user clicks the "Add" button
	 */
	const addElement = () => {
		setValues([
			...values,
			{
				key: '',
				value: undefined,
			},
		]);
	};

	const valueUpdated = (propertyName: string, value: any, arrayIndex?: number, defaultValue?: any) => {
		if (arrayIndex === undefined) {
			console.warn('NO ARRAY INDEX!!!!');
			return;
		}
		if (propertyName !== 'key' && propertyName !== 'value') {
			console.warn('invalid property name', propertyName);
			return;
		}
		let newValues = [...values];
		// Updates key or value
		newValues[arrayIndex][propertyName] = value || defaultValue;
		setValues(newValues);
	};

	return (
		<div className="map-container">
			<DxButton type="secondary" className="add-button" onClick={addElement}>
				Add Object Property <GenesysDevIcon icon={GenesysDevIcons.AppPlusSolid} className="add-icon" />
			</DxButton>
			{buildElements()}
		</div>
	);
}
