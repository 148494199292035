import React from 'react';
import { Row, Col, Input } from 'reactstrap';

import './MarkdownEditor.scss';
import MarkdownDisplay from '../markdowndisplay/MarkdownDisplay';

class MarkdownEditor extends React.Component {
	constructor() {
		super();
		this.state = {};
	}

	render() {
		const updateMarkdown = (md) => this.setState({ markdown: md });
		return (
			<div className='markdown-editor'>
				<div className='alert alert-info'>
					<strong>Proof of Concept</strong><br />
					This is a work in progress demonstrating editing markdown content live in the DX-UI app. 
					Type markdown on the left. See the output on the right.
				</div>
				<Row>
					<Col>
						<Input
							type='textarea'
							onChange={(e) => {
								// This seems to work fine without debouncing
								updateMarkdown(e.target.value);
							}}
						/>
					</Col>
					<Col>
						<div>
							<MarkdownDisplay markdown={this.state.markdown} />
						</div>
					</Col>
				</Row>
			</div>
		);
	}
}

export default MarkdownEditor;
