import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { DxTabbedContent, DxTabPanel } from 'genesys-react-components';

import { ModelSchema, OpenAPIDefinition, OperationDetails, UpdateSource } from '../../../../helpers/openapi/OpenAPITypes';
import { getRequestDataBodyAtom } from '../../../../helpers/atoms/APIExplorerRequestCache';
import AppSettings from '../../../../helpers/settings/AppSettings';
import SwaggerCache from '../../../../helpers/openapi/SwaggerCache';
import ModelJsonDisplay from '../display/ModelJsonDisplay';
import ModelSchemaDisplay from '../display/ModelSchemaDisplay';
import ModelEditor from '../modeleditor/ModelEditor';

interface IProps {
	operationDetails: OperationDetails;
	source?: string;
}

export default function BodyEditor(props: IProps) {
	const [swagger, setSwagger] = useState<OpenAPIDefinition>();
	const readingMode = useRecoilValue(AppSettings.apiExplorerReadingModeAtom());
	const [bodyData, setBodyData] = useRecoilState(getRequestDataBodyAtom(props.operationDetails.operation.operationId));
	const [updateSource, setUpdateSource] = useState<UpdateSource>(UpdateSource.INIT);
	const [bodySchema, setBodySchema] = useState<ModelSchema | undefined>();

	// Constructor
	useEffect(() => {
		(async () => setSwagger(await SwaggerCache.get(props.source)))();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Update once swagger is loaded
	useEffect(() => {
		// Resolve body schema
		const bodyParam = props.operationDetails.operation.parameters.find((param: any) => param.in === 'body');
		if (bodyParam && bodyParam.schema && swagger) {
			setBodySchema(SwaggerCache.resolveModelRef(swagger, bodyParam.schema as ModelSchema));
		}
	}, [props.operationDetails.operation.parameters, swagger]);

	// Return reading mode
	if (readingMode) {
		return (
			<DxTabbedContent>
				<DxTabPanel title="Schema">
					{bodySchema && swagger && (
						<ModelSchemaDisplay
							className="reading-mode-model"
							modelName={bodySchema.__modelName}
							schema={bodySchema}
							definition={swagger}
							showExpanded={true}
							isRequest={true}
						/>
					)}
				</DxTabPanel>
				<DxTabPanel title="JSON" className="reading-mode-json">
					{bodySchema && swagger && <ModelJsonDisplay isRequest={true} schema={bodySchema} swagger={swagger} />}
				</DxTabPanel>
			</DxTabbedContent>
		);
	}

	const valueUpdated = (_propertyName: any, value: any, updateSource: UpdateSource) => {
		setBodyData(value);
		setUpdateSource(updateSource);
	};

	// Return editor
	return (
		<div className="model-body-container">
			{bodySchema && swagger && (
				<ModelEditor
					schema={bodySchema}
					isRequest={true}
					propertyName={bodySchema.__modelName}
					onValueUpdated={valueUpdated}
					swagger={swagger}
					initialValue={bodyData}
					updateSource={updateSource}
				/>
			)}
		</div>
	);
}
