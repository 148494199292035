import React from 'react';
import AuthorResolver from '../../helpers/AuthorResolver';

import './AuthorInfo.scss';

interface IProps {
	author: string;
}

function AuthorInfo(props: IProps) {
	var authorData = AuthorResolver.getData(props.author);

	return (
		<div className="blog-author-bio">
			{AuthorResolver.getImage(props.author, 'author-image')}
			<div className="author-content">
				<p>
					<span className="author-name">{authorData.name}</span>
					<br />
					<span className="author-subtitle">
						{authorData.title}, {authorData.company}
					</span>
				</p>
				<p className="author-bio-text">{authorData.bio}</p>
			</div>
		</div>
	);
}

export default AuthorInfo;
