import React from 'react';
import Url from 'url-parse';
import { withRouter } from 'react-router-dom';

/*
 * This component is based on react-router-component's CaptureClicks component
 * The project is no longer maintained (last commit > 1y) and is licensed under MIT
 * https://github.com/STRML/react-router-component/blob/master/lib/CaptureClicks.js
 */
class ClickCapture extends React.Component {
	catchClick(e) {
		if (!e) return;

		// Ignore canceled events, modified clicks, and right clicks.
		if (e.defaultPrevented) {
			return;
		}

		if (e.metaKey || e.ctrlKey || e.shiftKey) {
			return;
		}

		if (e.button !== 0) {
			return;
		}

		// Legacy swagger docs click handlers
		var el = e.target;
		while (el) {
			if (el) {
				// Swagger link copiers
				if (typeof el.className === 'string' && el.className.includes('copy-link-button-direct')) {
					e.preventDefault();
					navigator.clipboard.writeText(`${window.location.href}#${el.getAttribute('data-key')}`);
					return;
				} else if (typeof el.className === 'string' && el.className.includes('copy-link-button-markdown')) {
					e.preventDefault();
					navigator.clipboard.writeText(`[${el.getAttribute('data-name')}](${window.location.href}#${el.getAttribute('data-key')})`);
					return;
				}

				// Swagger doc expander
				if (typeof el.className === 'string' && el.className.includes('resource-header')) {
					e.preventDefault();
					el.parentNode.querySelector('.resource-body').classList.toggle('expanded');
					return;
				}

				// Request/response panel switcher
				if (
					typeof el.className === 'string' &&
					(el.className.includes('request-container') || el.className.includes('response-container'))
				) {
					// Find ID of content element
					const contentId = e.target.getAttribute('aria-controls');
					if (!contentId) return;

					e.preventDefault();

					// Tabs
					el.querySelectorAll('.nav-item a').forEach((tabEl) => tabEl.classList.remove('active'));
					e.target.classList.add('active');

					// Content
					el.querySelectorAll('.tab-content > div').forEach((contentEl) => contentEl.classList.remove('active', 'show'));
					el.querySelector(`.tab-content #${contentId}`).classList.add('active', 'show');

					return;
				}

				// Response code expander
				else if (typeof el.className === 'string' && el.className.includes('response-header')) {
					// Find ID of content element
					const contentId = e.target.getAttribute('aria-controls');
					if (!contentId) return;

					e.preventDefault();

					// Find content element
					const contentEl = el.parentNode.querySelector(`#${contentId}`);
					if (!contentEl) return;

					// Hide/show
					if (typeof el.className === 'string' && contentEl.className.includes('show')) {
						contentEl.classList.remove('show');
						el.querySelector('span.chevron-expander').setAttribute('data-glyph', 'chevron-right');
					} else {
						contentEl.classList.add('show');
						el.querySelector('span.chevron-expander').setAttribute('data-glyph', 'chevron-bottom');
					}

					return;
				}

				// Move up a node
				el = el.parentNode;
			}
		}

		// Ignore clicks from non-a elements.
		el = e.target;
		while (el && el.nodeName !== 'A') {
			el = el.parentNode;
		}
		if (!el) {
			return;
		}

		// Ignore the click if the element has a target.
		if (el.target && el.target !== '_self') {
			return;
		}

		// Ignore the click if it's a download link. (We use this method of
		// detecting the presence of the attribute for old IE versions.)
		if (el.attributes.download) {
			return;
		}

		// Ignore hash (used often instead of javascript:void(0) in strict CSP envs)
		if (el && el.getAttribute('href') && el.getAttribute('href').startsWith('#')) {
			return;
		}

		// Use a regular expression to parse URLs instead of relying on the browser
		// to do it for us (because IE).
		var url = new Url(el.href);
		var windowURL = new Url(window.location.href);

		// Ignore links that don't share a protocol and host with ours.
		if (url.protocol !== windowURL.protocol || url.host !== windowURL.host || url.port !== windowURL.port) {
			return;
		}

		// Ignore 'rel="external"' links.
		if (el.rel && /(?:^|\s+)external(?:\s+|$)/.test(el.rel)) {
			return;
		}

		// Prevent :focus from sticking; preventDefault() stops blur in some browsers
		el.blur();
		e.preventDefault();

		// Rebuild URL
		let navUrl = url.pathname + (url.search && url.search.length > 1 ? url.search : '') + (url.hash && url.hash.length > 1 ? url.hash : '');

		// Push URL into history to cause BrowserRouter to route to it
		this.props.history.push(navUrl);
	}

	render() {
		return <div onClick={this.catchClick.bind(this)}>{this.props.children}</div>;
	}
}

export default withRouter(ClickCapture);
