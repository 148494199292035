import React, { useEffect, useState } from 'react';
import { DxButton, DxItemGroupItem, DxItemGroupItemValue } from 'genesys-react-components';
import qs from 'qs';
import { useHistory } from 'react-router-dom';

import Card, { CardStyles } from './Card';
import { addToast, ToastType } from '../../helpers/atoms/ToastAtom';
import { ContentMediaData } from '../../helpers/AssetLoaderTypes';
import { GenesysDevIcons } from 'genesys-dev-icons';
import LoadingPlaceholder from '../loadingplaceholder/LoadingPlaceholder';
import Paginator, { Pagination } from '../paginator/Paginator';
import AssetLoader from '../../helpers/AssetLoader';
import { FilterGroup, setFilters } from '../../helpers/atoms/InPageFilters';
import TaxonomyCategories from '../../helpers/TaxonomyCategories';

import './CardCatalog.scss';

interface IProps {
	source?: any;
	cardStyle?: CardStyles;
	useCategorySubtitle?: boolean;
	subtypeFilterLabel?: string;
}

interface SubtypeMap {
	[subtype: string]: string;
}

const MIN_PAGE_SIZE = 6;
const normalSort = (a: String, b: String) => (a.toLowerCase() < b.toLowerCase() ? -1 : a.toLowerCase() > b.toLowerCase() ? 1 : 0);
const camelizeRegex = /(^|\s)(.)/gm;

export default function CardCatalog(props: IProps) {
	const [cardData, setCardData] = useState<ContentMediaData[] | undefined>();
	const [filteredCards, setFilteredCards] = useState<ContentMediaData[] | undefined>();
	const [pagination, setPagination] = useState<Pagination>({ startItem: 1, endItem: MIN_PAGE_SIZE, page: 1, pageSize: MIN_PAGE_SIZE });
	const [tagFilters, setTagFilters] = useState<DxItemGroupItemValue[]>([]);
	const [subtypes, setSubtypes] = useState<SubtypeMap>();
	const [selectedSubtype, setSelectedSubtype] = useState<string>();
	const [categoryFilters, setCategoryFilters] = useState<DxItemGroupItemValue[]>([]);
	const history = useHistory();

	// Constructor
	useEffect(() => {
		if (props.source) {
			AssetLoader.get(props.source, true)
				.then((data: ContentMediaData[]) => {
					// Ensure display order is maintained
					data.sort((a, b) => (a.order === b.order ? 0 : a < b ? 1 : -1));
					setCardData(data);
					setFilteredCards(data);

					// Scrub and normalize data
					const subtypeMap: SubtypeMap = {};
					data.forEach((val) => {
						// Resolve and overwrite legacy category numbers
						if (Number.isInteger(val.category)) {
							val.category = TaxonomyCategories.getKeyFromLegacyId(parseInt(val.category || ''));
						}

						// Set subtitle to category display
						if (props.useCategorySubtitle && val.category) {
							val.subtitle = TaxonomyCategories.categoryKeyData[val.category]?.displayName;
						}

						// Collect subtype
						if (val.subtype) {
							let subtypeName = val.subtype;
							subtypeName = subtypeName.replaceAll(
								camelizeRegex,
								(substring: string, m1: string, m2: string) => `${m1}${(m2 || '').toUpperCase()}`
							);
							subtypeMap[subtypeName] = val.subtype;
						}
					});

					// Set subtypes
					if (Object.keys(subtypeMap).length > 0) {
						subtypeMap['All'] = '';
						setSubtypes(subtypeMap);
					}

					// Set selected subtype
					const queryParams = qs.parse(window.location.search.replace(/^\?/, ''));
					if (queryParams['subtype']) setSelectedSubtype(`${queryParams['subtype'] || ''}`);

					// Scrape data for filters
					const filterData = [] as FilterGroup[];
					filterData.push({
						label: 'Tags',
						options:
							// Split tag data by commas, flatten, and remove duplicates
							Array.from(new Set(data.map((val) => (val.tags || '').split(',').map((val) => val.trim())).flat()).values())
								// Remove empty entries
								.filter((val) => val !== '' && val !== undefined)
								// Sort alphabetically
								.sort(normalSort)
								// Translate to filter items
								.map((val) => {
									return { label: val, value: val } as DxItemGroupItem;
								}),
						onItemsChanged: (items: DxItemGroupItemValue[]) => setTagFilters(items),
					});
					filterData.push({
						label: 'Category',
						options:
							// Normalize categories and remove duplicates
							Array.from(new Set(data.map((val) => val.category || '')).values())
								// Remove empty entries
								.filter((val) => val !== '' && val !== undefined)
								// Sort alphabetically
								.sort(normalSort)
								// Translate to filter items
								.map((val) => {
									return { label: TaxonomyCategories.categoryKeyData[val]?.displayName, value: val } as DxItemGroupItem;
								}),
						onItemsChanged: (items: DxItemGroupItemValue[]) => setCategoryFilters(items),
					});
					setFilters(filterData);
				})
				.catch((err) => {
					console.error(err);
					addToast({ title: 'Error loading guide data', message: err.message || 'unknown error', toastType: ToastType.Critical });
				});
		}

		return () => {
			setFilters(undefined);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!cardData) return;

		// Filter tags
		let activeFilters = tagFilters.filter((f) => f.isSelected).map((f) => f.item.value);
		let cards = [...cardData];
		if (activeFilters.length > 0) {
			cards = cards.filter((card) => activeFilters.some((value) => card.tags?.includes(value)));
		}

		// Filter categories
		activeFilters = categoryFilters.filter((f) => f.isSelected).map((f) => f.item.value);
		if (activeFilters.length > 0) {
			cards = cards.filter((card) => activeFilters.some((value) => card.category?.includes(value)));
		}

		// Filter subtype
		if (selectedSubtype) {
			cards = cards.filter((card) => card.subtype === selectedSubtype);
		}

		// Update subtype query param
		const queryParams = qs.parse(window.location.search.replace(/^\?/, ''));
		if (selectedSubtype !== undefined && selectedSubtype !== (queryParams['subtype'] || '')) {
			if (selectedSubtype) queryParams['subtype'] = selectedSubtype;
			else delete queryParams['subtype'];
			let queryString = Object.keys(queryParams)
				.map((p) => `${p}=${encodeURIComponent(`${queryParams[p] || ''}`)}`)
				.join('&');
			if (queryString) queryString = '?' + queryString;
			const l = window.location;
			history.push(`${l.pathname}${queryString}${l.hash}`);
		}

		setFilteredCards(cards);
	}, [cardData, categoryFilters, tagFilters, selectedSubtype, history]);

	return filteredCards ? (
		<div className="card-catalog">
			{subtypes && (
				<div className="subtype-container">
					{props.subtypeFilterLabel && <span className="subtype-filter-label">{props.subtypeFilterLabel}</span>}
					<div className="subtype-filter">
						{Object.entries(subtypes).map(([display, value]) => (
							<DxButton
								key={display}
								type={(selectedSubtype || '') === value ? 'primary' : 'secondary'}
								onClick={() => setSelectedSubtype(value)}
							>
								{display}
							</DxButton>
						))}
					</div>
				</div>
			)}
			<div className="card-list">
				{filteredCards.slice(pagination.startItem - 1, pagination.endItem).map((d, i) => (
					<div className="card-container" key={i}>
						<Card data={d} icon={d.icon as GenesysDevIcons | undefined} cardStyle={props.cardStyle} />
					</div>
				))}
			</div>
			<Paginator itemCount={filteredCards.length} minPageSize={MIN_PAGE_SIZE} onPaginationChanged={(p) => setPagination(p)} />
		</div>
	) : (
		<LoadingPlaceholder />
	);
}
