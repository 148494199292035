import { DxItemGroupItem, ItemChangedCallback, ItemGroupChangedCallback } from 'genesys-react-components';
import { atom } from 'recoil';
import { setRecoil } from 'recoil-nexus';

export interface FilterGroup {
	label: string;
	options: DxItemGroupItem[];
	mode?: 'multi' | 'single';
	onItemChanged?: ItemChangedCallback;
	onItemsChanged?: ItemGroupChangedCallback;
}

export const inPageFiltersAtom = atom({
	key: 'inPageFiltersAtom',
	default: undefined as FilterGroup[] | undefined,
});

export function setFilters(filters: FilterGroup[] | undefined) {
	if (filters && filters.length === 0) filters = undefined;
	setRecoil(inPageFiltersAtom, filters);
}
