import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';
import React from 'react';
import { Account } from '../../helpers/accounts/Account';
import { removeAccount } from '../../helpers/atoms/AccountsAtom';
import { RegionShortnames } from '../../helpers/platformapi/Region';

import './AccountCard.scss';
import silhouette from './silhouette.svg';

interface IProps {
	active?: boolean;
	account?: Account;
	onClick?: AccountCardClickHandler;
	allowRemove?: boolean;
	onRemoved?: AccountCardClickHandler;
}

interface AccountCardClickHandler {
	(account: Account): void;
}

export default function AccountCard(props: IProps) {
	if (!props.account) return null;

	const img = props.account.profileImageUri || silhouette;
	let accountCardClass = 'account-card';
	if (props.active) accountCardClass += ' active';

	let onclick: React.MouseEventHandler<HTMLDivElement> | undefined;
	if (props.onClick) {
		onclick = (e) => {
			if (props.onClick && props.account) props.onClick(props.account);
			e.stopPropagation();
		};
	}

	let removeButton;
	if (props.allowRemove) {
		accountCardClass += ' removable';
		removeButton = (
			<div
				className="col-remove-button"
				onClick={(e) => {
					e.stopPropagation();
					if (props.account) removeAccount(props.account);
					if (props.onRemoved && props.account) props.onRemoved(props.account);
				}}
			>
				<GenesysDevIcon icon={GenesysDevIcons.AppTimes} className="remove-button" />
			</div>
		);
	}

	return (
		<div className={accountCardClass} onClick={onclick}>
			<div className="col-region">
				<span>{RegionShortnames.get(props.account.region)}</span>
			</div>
			<div className="col-image">
				<img src={img} alt="profile pic" />
			</div>
			<div className="col-info">
				<span title={props.account.name}>{props.account.name}</span>
				<span title={props.account.orgName}>{props.account.orgName}</span>
				{props.account.safeMode ? (
					<span title="SafeMode enabled">
						SafeMode Enabled <GenesysDevIcon icon={GenesysDevIcons.AppInfoSolid} />
					</span>
				) : undefined}
			</div>
			{removeButton}
		</div>
	);
}
