import { GenesysDevIcons } from 'genesys-dev-icons';

import {
    FilterMap,
    HomeContentType,
    HelpContentType,
    Origin,
    SearchResult,
    HighlightLimits,
	FilterUpdate,
	HomeTopic,
	PostFeedbackRequest,
	getRelevanceValue
} from "./SearchTypes";
import AssetLoader from '../../helpers/AssetLoader';

const FEEDBACK_TIMEOUT = 10000;

export class SearchTools {

	// processes updates to the Developer Center topic filters
	updateHomeTopicFilters(e: React.ChangeEvent<HTMLInputElement>, homeTopicFilters: FilterMap): FilterUpdate<HomeTopic> {
		const newFilterVal: boolean = e.target.checked;
		let newFilters: FilterMap = Object.assign({}, homeTopicFilters);
		let filter: HomeTopic | undefined;
		
		Object.entries(HomeTopic).forEach(([k, v]) => {
			if (v === e.target.value) {
				filter = v;
			}
		});
		return {
			newFilters,
			newFilterVal,
			filter
		}
	}	

    // processes updates to the home content filters 
    updateHomeContentFilters(e: React.ChangeEvent<HTMLInputElement>, homeContentFilters: FilterMap): FilterUpdate<HomeContentType> {
        const newFilterVal: boolean = e.target.checked;
		let newFilters: FilterMap = Object.assign({}, homeContentFilters);
		let filter: HomeContentType | undefined;

		Object.entries(HomeContentType).forEach(([k, v]) => {
			if (v === e.target.value) {
				filter = v;
			}
		});

        return {
            newFilters,
            newFilterVal,
            filter
        }
    }

    // processes updates to the help content filters 
    updateHelpContentFilters(e: React.ChangeEvent<HTMLInputElement>, helpContentFilters: FilterMap): FilterUpdate<HelpContentType> {
        const newFilterVal: boolean = e.target.checked;
		let newFilters: FilterMap = Object.assign({}, helpContentFilters);
		let filter: HelpContentType | undefined;

		Object.entries(HelpContentType).forEach(([k, v]) => {
			if (v === e.target.value) {
				filter = v;
			}
		});
		
        return {
            newFilters,
            newFilterVal,
            filter
        }
    }

    // processes updates to the help content filters 
    updateSourceFilters(e: React.ChangeEvent<HTMLInputElement>, sourceFilters: FilterMap): FilterUpdate<Origin> {
        const newFilterVal: boolean = e.target.checked;
		let newFilters: FilterMap = Object.assign({}, sourceFilters);
		let filter: Origin | undefined;
		
		Object.entries(Origin).forEach(([k, v]) => {
			if (v === e.target.value) {
				filter = v;
			}
		});

        return {
            newFilters,
            newFilterVal,
            filter
        }
    }

    // caculates the start index, end index, and max offset for a highlight of a search result
    calcHighlightLimits(result: SearchResult, highlight: number[]): HighlightLimits {
        let startIndex: number;
		let endIndex: number;
		const maxOffset: number = 30;

		// set start index for excerpt
		if (highlight[0] - maxOffset <= 0) {
			startIndex = 0;
		} else {
			startIndex = highlight[0] - maxOffset;
		}

		// set end index for excerpt
		if (highlight[1] + maxOffset >= result.excerptText!.length - 1) {
			endIndex = result.excerptText!.length - 1;
		} else {
			endIndex = highlight[1] + maxOffset;
		}

        return {
            startIndex,
            endIndex
        }
    }

    // formats the breadcrumb path for readability
    formatBreadcrumbPath(documentType: string): string {
        const documentTypeUpper: string = documentType.toUpperCase();
		const documentTypeLower: string = documentType.toLowerCase();
		const capitalizedFirstLetter: string = documentTypeUpper.charAt(0);
		return capitalizedFirstLetter + documentTypeLower.substring(1);
    }

    // selects a content icon based on document type
    getContentIcon(documentType: string): GenesysDevIcons {
        let contentIcon: GenesysDevIcons = GenesysDevIcons.DestPages;;

		if (documentType === HomeContentType.BLOG) {
			contentIcon = GenesysDevIcons.DestBlog;
		}
		if (documentType === HomeContentType.BLUEPRINT) {
			contentIcon = GenesysDevIcons.DestBlueprint;
		}
		if (documentType === HomeContentType.GUIDE) {
			contentIcon = GenesysDevIcons.DestGuide;
		}
		if (documentType === HomeContentType.TECH_REF) {
			contentIcon = GenesysDevIcons.DestTechRef;
		}
		if (documentType === HomeContentType.SDK_DOC) {
			contentIcon = GenesysDevIcons.DestSdkDoc;
		}
		if (documentType === HomeContentType.OTHER) {
			contentIcon = GenesysDevIcons.DestPages;
		}
		if (documentType === HelpContentType.ARTICLE || documentType === HelpContentType.FAQ) {
			contentIcon = GenesysDevIcons.AppExternalLink;
		}

        return contentIcon;
    }

	isValidSearchTerm(searchTerm: string) {
		return searchTerm.length >= 2 && searchTerm.length <= 60;
	}

	async submitClickFeedback(resultId: string, indexId: string, queryId: string) {
		const reqData: PostFeedbackRequest = {
			indexId,
			queryId,
			clickFeedbackItems: [{
				resultId,
				clickTime: Date.now().toString()
			}]
		};

		try {
			await AssetLoader.post('/search/feedback', reqData, 'application/json', undefined, FEEDBACK_TIMEOUT);
		} catch (err: any) {
			console.error('failed to submit click feedback', err);
		}
	}

	async submitRelevanceFeedback(isRelevant: boolean, resultId: string, indexId: string, queryId: string): Promise<void> {
		const reqData: PostFeedbackRequest = {
			indexId,
			queryId,
			relevanceFeedbackItems: [{
				resultId,
				relevanceValue: getRelevanceValue(isRelevant)
			}]
		};

		return AssetLoader.post('/search/feedback', reqData, 'application/json', undefined, FEEDBACK_TIMEOUT);
	}
}