import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';
import { DxAccordion, DxAccordionGroup, DxButton } from 'genesys-react-components';

import { CategorizedOperations, OperationDetails, TagInfo, visibilityMap } from '../../../helpers/openapi/OpenAPITypes';
import SwaggerCache from '../../../helpers/openapi/SwaggerCache';
import { selectedThemeAtom } from '../../../theme/ThemeAtom';
import SiteBanner from '../anemia/sitebanner/SiteBanner';
import AppSettings from '../../../helpers/settings/AppSettings';
import { AnemiaTheme, ApiResourceProps, LogoChoice, ToolboxApp } from '../../../types';
import ThemeSelector from '../../../theme/ThemeSelector';
import AccountSwitcher from '../../accountswitcher/AccountSwitcher';
import Toaster from '../../toaster/Toaster';
import { AddApiResource, toolboxApiResourcesAtom } from '../../../helpers/atoms/ToolboxAtom';
import { removeApiResource } from '../../../helpers/atoms/ToolboxAtom';
import OpenAPIExplorer from '../../tools/openapi/OpenAPIExplorer';
import Feedback from '../../feedback/Feedback';
import DxLink from '../../dxlink/DxLink';
import OperationFilterWidget from '../../tools/openapi/OperationFilterWidget';
import LoadingPlaceholder from '../../loadingplaceholder/LoadingPlaceholder';
import Toolbox from '../anemia/toolbox/Toolbox';

// Logos for the header
import GenesysLogoPride from '../../../images/genesys-logo-pride.svg';
import GenesysLogo from '../../../images/genesys-logo-default.svg';

import '../BootstrapBackfill.scss';
import '../anemia/typography.scss';
import '../anemia/movethisstuff.scss';
// This import of the default layout's stylesheet is intentional; this layout is meant to be a derivation of the default layout
import '../DefaultLayout2021.scss';
import '../../../theme/themes.scss';
import './ApiExplorerLayout.scss';
import Tag from '../../cards/Tag';

export default function ApiExplorerLayout() {
	const [apiOperations, setApiOperations] = useState<CategorizedOperations>();
	const [filteredApiOperations, setFilteredApiOperations] = useState<CategorizedOperations>();
	const [tags, setTags] = useState<TagInfo[]>([]);
	const [hasDeprecatedOp, setHasDeprecatedOp] = useState<boolean | undefined>(false);
	const theme = useRecoilValue(selectedThemeAtom());
	const isNavCollapsed = useRecoilValue(AppSettings.navigationCollapsedAtom());
	const logoChoice = useRecoilValue(AppSettings.logoChoiceOption());
	const toolboxApiResources = useRecoilValue(toolboxApiResourcesAtom);
	const selectedOperation = useRecoilValue(AppSettings.apiExplorerStandaloneSelectedOperationAtom());

	useEffect(() => {
		(async () => {
			const newOps = await SwaggerCache.getCategorizedOperations(SwaggerCache.overrideSwaggerUrl);
			const swagger = await SwaggerCache.get(SwaggerCache.overrideSwaggerUrl);
			const newTags: TagInfo[] = [];

			// Create a list of tag info for ones that have operations
			Object.keys(newOps).forEach((tag) => {
				newTags.push(
					swagger.tags.find((t) => t.name === tag) || {
						name: tag,
						description: '',
					}
				);
			});

			newTags.sort((a, b) => (a.name === b.name ? 0 : a.name < b.name ? -1 : 1));

			//check if window hash is a link for an operation
			const parameters = window.location.hash.slice(1);
			if (parameters && newOps) {
				//find operation key to match hash
				let operation: ApiResourceProps | undefined;
				Object.entries(newOps as { [tag: string]: OperationDetails[] }).forEach(([tag, operations]) => {
					// Iterate operations
					let foundOp = operations.find((operation) => operation.key === parameters);
					if (foundOp) {
						if (foundOp.operation.deprecated === true) {
							setHasDeprecatedOp(undefined);
						}
						operation = { path: foundOp.path, verb: foundOp.verb };
						return;
					}
				});

				if (operation) {
					const props = { path: operation.path, verb: operation.verb } as ApiResourceProps;
					AddApiResource({
						title: SwaggerCache.makeOperationTitle(operation.verb, operation.path),
						appType: ToolboxApp.ApiExplorer,
						props,
					});

					AppSettings.setApiExplorerStandaloneSelectedOperation(props);
				}
			}

			setTags(newTags);
			setApiOperations(newOps);
		})();
	}, []);

	useEffect(() => {
		if (toolboxApiResources.length === 0) {
			AppSettings.setNavigationCollapsed(false);
		}
	}, [toolboxApiResources]);

	let themeLogo;
	switch (theme) {
		case AnemiaTheme.DefaultDark: {
			themeLogo = logoChoice === LogoChoice.Pride ? GenesysLogoPride : GenesysLogo;
			break;
		}
		default: {
			themeLogo = logoChoice === LogoChoice.Pride ? GenesysLogoPride : GenesysLogo;
			break;
		}
	}

	const filteredTags = tags.filter((tag) => Object.keys(filteredApiOperations || {}).includes(tag.name));
	const hasResources = toolboxApiResources && toolboxApiResources.length > 0;

	return (
		<div className={`layout default-layout api-explorer-layout disappearing-scrollbars ${theme}`}>
			<SiteBanner />
			{/* layout-header is ripped off from the Header component and uses its styles */}
			<div className="layout-header">
				<div className="api-explorer-logo">
					<img src={themeLogo} className="header-image" alt="Genesys Cloud Developer Center" />
					<span className="header-image-text">API Explorer</span>
				</div>
				<DxButton type="secondary" onClick={() => (window.location.href = './api-explorer')}>
					Return to Developer Center
				</DxButton>
				<ThemeSelector />
				<div className="account-switcher-header-container">
					<AccountSwitcher className="header-account-switcher" />
				</div>
				<Toaster />
			</div>
			<div id="layout-body-div" className={`layout-body${isNavCollapsed ? ' nav-collapsed' : ''}`}>
				{!apiOperations && <LoadingPlaceholder text="Loading API definition" />}
				{apiOperations && (
					<React.Fragment>
						<div className="layout-navigation">
							<GenesysDevIcon
								icon={isNavCollapsed ? GenesysDevIcons.AppChevronRight : GenesysDevIcons.AppChevronLeft}
								className="nav-collapse-icon"
								onClick={() => AppSettings.setNavigationCollapsed(!isNavCollapsed)}
							/>
							<div className="site-navigation operation-list">
								<span className="h7">API Resources</span>
								<OperationFilterWidget
									apiOperations={apiOperations}
									onOperationsFiltered={setFilteredApiOperations}
									deprecatedFilter={hasDeprecatedOp}
								/>
								<DxAccordionGroup>
									{filteredTags.map((tag) => (
										<DxAccordion
											key={tag.name}
											className="category-container"
											title={
												<div className="category-header">
													<span className="category-name">{tag.name}</span>
													{tag.description && <span className="cateogry-description">{tag.description}</span>}
												</div>
											}
										>
											{filteredApiOperations &&
												filteredApiOperations[tag.name]?.map((operation) => (
													<DxButton
														key={operation.key}
														type="link"
														className={`operation-info operation-${operation.verb.toLowerCase()}`}
														onClick={() => {
															const props = { path: operation.path, verb: operation.verb } as ApiResourceProps;
															AddApiResource({
																title: operation.title,
																appType: ToolboxApp.ApiExplorer,
																props,
															});
															AppSettings.setApiExplorerStandaloneSelectedOperation(props);
														}}
													>
														<div className="operation-key" title={operation.key}>
															<span className="verb">{operation.verb.toUpperCase()}</span>
															<span>{operation.path}</span>
															{operation.operation.deprecated && <Tag>Deprecated</Tag>}
															{operation.operation['x-genesys-visibility'] && (
																<Tag className={`tag-${operation.operation['x-genesys-visibility']}`}>
																	{visibilityMap[operation.operation['x-genesys-visibility']]}
																</Tag>
															)}
														</div>
														<div className="operation-description">{operation.description}</div>
													</DxButton>
												))}
										</DxAccordion>
									))}
								</DxAccordionGroup>
							</div>
						</div>
						<div className={`layout-content`}>
							<div className="banner-notice">
								<strong>Standalone API Explorer Beta</strong>
								<br />
								Give this new standalone API Explorer view a try! Have feedback or suggestions? Let us know what you think by posting in the{' '}
								<DxLink forceNewTab={true} href="/forum/c/site-feedback/3">
									Dev Forum Site Feedback
								</DxLink>{' '}
								category or using the page feedback widget at the bottom of the page.
							</div>
							{!hasResources && <div className="no-operations">Select API resources from the left-hand menu to get started</div>}
							{hasResources && (
								<React.Fragment>
									<div className="operation-tab-container">
										{toolboxApiResources.map((item, i) => (
											<div
												key={item.title}
												className={`operation-info operation-${(item.props as ApiResourceProps).verb.toLowerCase()} ${
													selectedOperation &&
													item.title === SwaggerCache.makeOperationTitle(selectedOperation.verb, selectedOperation.path)
														? 'selected'
														: ''
												}`}
												onClick={() => AppSettings.setApiExplorerStandaloneSelectedOperation(item.props as ApiResourceProps)}
											>
												<div className="operation-key" title={item.title}>
													<span className="verb">{(item.props as ApiResourceProps).verb.toUpperCase()}</span>
													<span>{(item.props as ApiResourceProps).path}</span>
												</div>
												<DxButton
													type="link"
													className="remove-operation-button"
													onClick={() => {
														// Select new tab when removing active tab
														if (
															selectedOperation &&
															item.title === SwaggerCache.makeOperationTitle(selectedOperation.verb, selectedOperation.path)
														) {
															// Last tab in list
															if (i === toolboxApiResources.length - 1) {
																if (toolboxApiResources.length > 1) {
																	AppSettings.setApiExplorerStandaloneSelectedOperation(
																		toolboxApiResources[i - 1].props as ApiResourceProps
																	);
																} else {
																	AppSettings.setApiExplorerStandaloneSelectedOperation(undefined);
																}
															} else {
																AppSettings.setApiExplorerStandaloneSelectedOperation(toolboxApiResources[i + 1].props as ApiResourceProps);
															}
														}

														removeApiResource(item);
													}}
												>
													<GenesysDevIcon icon={GenesysDevIcons.AppTimes} />
												</DxButton>
											</div>
										))}
									</div>
									{selectedOperation && (
										<OpenAPIExplorer
											verb={selectedOperation.verb}
											path={selectedOperation.path}
											showExpanded={true}
											preventCollapse={true}
										/>
									)}
								</React.Fragment>
							)}
							<Feedback />
						</div>
					</React.Fragment>
				)}
			</div>
			<Toolbox />
		</div>
	);
}
