import { StorageProvider } from '../../../types';
import { addToast, ToastType } from '../../../helpers/atoms/ToastAtom';

const DB_VERSION = 3;

export class IndexedDBProvider implements StorageProvider {
	private db: any;
	private storeName = '';
	private isDBAvailable = true;

	isAvailable(): boolean {
		return typeof window.indexedDB !== 'undefined';
	}

	initialize(storeName: string): void {
		this.storeName = storeName;
		var request = window.indexedDB.open(storeName, DB_VERSION);
		request.onerror = (event) => {
			this.isDBAvailable = false;
			console.error("Why didn't you allow my web app to use IndexedDB?!", event);
			addToast({
				title: 'IndexedDB not enabled',
				message:
					"The app will be unable to persist settings and cached data without access to the browser's IndexedDB. This means that all local settings, like dark theme selection and logged in accounts, will be lost when the app is refreshed.",
				toastType: ToastType.Warning,
			});
		};
		request.onupgradeneeded = (event: any) => {
			// console.log('init::onupgradeneeded', event);
			var db = event?.target?.result;
			db.createObjectStore(storeName, { keyPath: 'key' });
		};
		request.onsuccess = (event: any) => {
			// console.log('init::onsuccess', event);
			this.db = event.target.result;
		};
	}
	async getItem(key: string) {
		var item = await new Promise((resolve, reject) => {
			this.getItemImpl(key, resolve, reject);
		}).catch(() => {
			return undefined;
		});
		// console.log(`key=${key}; val:`, item);
		return item;
	}

	getItemImpl(key: string, resolve: Function, reject: Function): void {
		if (!this.isAvailable() || !this.isDBAvailable) {
			return reject('Indexed DB is not available');
		}

		if (!this.db) {
			setTimeout(this.getItemImpl.bind(this, key, resolve, reject), 100);
			return;
		}

		var transaction = this.db.transaction([this.storeName]);
		var objectStore = transaction.objectStore(this.storeName);
		var request = objectStore.get(key);
		request.onerror = (event: any) => {
			console.error('getItemImpl::onerror', event);
			reject(event);
		};
		request.onsuccess = (event: any) => {
			// console.log('getItemImpl::onsuccess', request.result);
			resolve(request.result?.value);
		};
	}
	setItem(key: string, value: any) {
		var transaction = this.db.transaction([this.storeName], 'readwrite');
		transaction.oncomplete = (event: any) => {
			// console.log('setItem::transaction::oncomplete', event);
		};
		transaction.onerror = (event: any) => {
			console.error('setItem::transaction::onerror', event);
		};

		var objectStore = transaction.objectStore(this.storeName);
		var request = objectStore.put({ key, value });
		request.onsuccess = (event: any) => {
			// console.log('setItem::request::onsuccess', event);
		};
		request.onerror = (event: any) => {
			console.error('setItem::request::onerror', event);
		};
	}
	deleteItem(key: string): void {
		return;
	}
	purge() {
		return new Promise<void>((resolve: any, reject: any) => {
			var transaction = this.db.transaction([this.storeName], 'readwrite');
			transaction.oncomplete = (event: any) => {
				// console.log('purge::transaction::oncomplete', event);
				resolve();
			};
			transaction.onerror = (event: any) => {
				console.error('purge::transaction::onerror', event);
				reject(event);
			};

			var objectStore = transaction.objectStore(this.storeName);
			var request = objectStore.clear();
			request.onsuccess = (event: any) => {
				// console.log('purge::request::onsuccess', event);
				resolve();
			};
			request.onerror = (event: any) => {
				console.error('purge::request::onerror', event);
				reject(event);
			};
		});
	}
}
