import React, { useEffect, useState } from 'react';
import { DxCheckbox, DxTextbox, DxToggle } from 'genesys-react-components';

import { CategorizedOperations, OperationDetails } from '../../../helpers/openapi/OpenAPITypes';

import './OperationFilterWidget.scss';
import { GenesysDevIcons } from 'genesys-dev-icons';

export type HttpVerb = 'delete' | 'get' | 'head' | 'patch' | 'post' | 'put';

export type HttpVerbFilter = {
	[verb in HttpVerb]: boolean;
};

export const defaultHttpVerbFilter = () => {
	return {
		delete: false,
		get: false,
		head: false,
		patch: false,
		post: false,
		put: false,
	};
};

interface IProps {
	apiOperations: CategorizedOperations;
	openApiSource?: string;
	searchLabel?: string;
	initialSearchTerm?: string;
	tagAllowList?: string[];
	verbFilter?: HttpVerbFilter;
	deprecatedFilter?: boolean;
	hideInterface?: boolean;
	onOperationsFiltered: { (filteredOperations: CategorizedOperations): void };
}

export default function OperationFilterWidget(props: IProps) {
	const [searchTerm, setSearchTerm] = useState(props.initialSearchTerm || '');
	const [httpVerbFilter, setHttpVerbFilter] = useState<HttpVerbFilter>(props.verbFilter || defaultHttpVerbFilter());
	const [deprecatedFilter, setDeprecatedFilter] = useState<boolean | undefined>(props.deprecatedFilter || false);

	// Direct filter triggers
	useEffect(() => {
		filterOperations();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchTerm, httpVerbFilter, props.apiOperations, props.tagAllowList, deprecatedFilter]);

	// Indirect filter triggers
	useEffect(() => {
		// Only propigate prop updates when they've changed
		if (props.initialSearchTerm && props.initialSearchTerm !== searchTerm) setSearchTerm(props.initialSearchTerm || '');
		if (props.verbFilter && JSON.stringify(props.verbFilter) !== JSON.stringify(httpVerbFilter))
			setHttpVerbFilter(props.verbFilter || defaultHttpVerbFilter());
		if (props.deprecatedFilter !== deprecatedFilter) setDeprecatedFilter(props.deprecatedFilter);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.initialSearchTerm, props.verbFilter, props.deprecatedFilter]);

	const filterOperations = () => {
		if (!props.apiOperations) return;

		// Prepare vars for filtering logic
		const newOps: CategorizedOperations = {};
		let filterText = searchTerm.trim().toLowerCase();
		// Strip trailing wildcard; search is always "contains"
		filterText = filterText.replace(/\*$/, '');
		let verbFilters: string[] | undefined = Object.entries(httpVerbFilter)
			.filter(([, filterEnabled]) => filterEnabled === true)
			.map(([verb]) => verb);
		if (verbFilters.length === 0) verbFilters = undefined;

		// Short circuit to full list when no filters are active
		if (!searchTerm && !props.tagAllowList && !verbFilters && deprecatedFilter === undefined) {
			raiseFilteredEvent(props.apiOperations || {});
			return;
		}
		// Iterate categories
		Object.entries(props.apiOperations as { [tag: string]: OperationDetails[] }).forEach(([tag, operations]) => {
			// Iterate operations in each ca
			operations.forEach((operation) => {
				// Filter text
				if (!operation.path.toLowerCase().includes(filterText) && !operation.description.toLowerCase().includes(filterText)) return;
				// Filter tags
				if (props.tagAllowList && !props.tagAllowList.includes(tag)) return;
				// Filter verbs
				if (verbFilters && !verbFilters.includes(operation.verb.toLowerCase())) return;

				//  Lazy init category
				if (!newOps[tag]) newOps[tag] = [];

				//Filter deprecated
				if (deprecatedFilter === true && !operation.operation.deprecated) return;
				if (deprecatedFilter === false && operation.operation.deprecated) return;

				// Add operation to category
				newOps[tag]!.push(operation);
			});
		});

		// Iterate filtered categories to sort
		sortOperations(newOps);

		// Notify
		raiseFilteredEvent(newOps);
	};

	const raiseFilteredEvent = (ops: CategorizedOperations) => {
		if (props.onOperationsFiltered) props.onOperationsFiltered(ops);
	};

	const sortOperations = (operations: CategorizedOperations) => {
		Object.values(operations as { [tag: string]: OperationDetails[] }).forEach((operations) => {
			operations.sort((a, b) => {
				// Same path, sort by verb
				if (a.path === b.path) return a.verb > b.verb ? 1 : -1;

				const aParts = a.path.split('/');
				const bParts = b.path.split('/');

				// Sort by matching part, but one is shorter (e.g. data vs. dataactions)
				if (b.path.startsWith(a.path)) return -1;
				if (a.path.startsWith(b.path)) return 1;

				// Same length, sort alphabetically
				for (let i = 0; i < aParts.length; i++) {
					// Same part, move on
					if (aParts[i] === bParts[i]) continue;

					// Sort alphabetically
					return aParts[i] > bParts[i] ? 1 : -1;
				}

				// This can't logically happen, but makes the linter happy
				return 0;
			});
		});
	};

	const handleCheckboxInput = (value: boolean, verb: HttpVerb) => {
		let temp = { ...httpVerbFilter };
		temp[verb] = value;
		setHttpVerbFilter(temp);
	};

	const handleCheckboxInputDeprecated = (value: boolean | undefined) => {
		setDeprecatedFilter(value);
	};

	// This causes the component to initialize and provide a filtered list, but not be user editable. Used by certain API Explorer modes.
	if (props.hideInterface === true) return <React.Fragment></React.Fragment>;

	return (
		<div className="operation-filter-widget">
			<DxTextbox
				label={props.searchLabel || 'API Operation Filter'}
				value={searchTerm}
				clearButton={true}
				placeholder="Type any part of a resource path or description to filter (e.g. users/me)"
				onChange={setSearchTerm}
			/>
			<div className="http-verb-filter">
				<DxCheckbox
					label="DELETE"
					itemValue="delete"
					className="verb-filter-checkbox"
					checked={httpVerbFilter?.delete}
					onCheckChanged={(val) => {
						handleCheckboxInput(val, 'delete');
					}}
				/>
				<DxCheckbox
					label="GET"
					itemValue="get"
					className="verb-filter-checkbox"
					checked={httpVerbFilter.get}
					onCheckChanged={(val) => {
						handleCheckboxInput(val, 'get');
					}}
				/>
				<DxCheckbox
					label="HEAD"
					itemValue="head"
					className="verb-filter-checkbox"
					checked={httpVerbFilter.head}
					onCheckChanged={(val) => {
						handleCheckboxInput(val, 'head');
					}}
				/>
				<DxCheckbox
					label="PATCH"
					itemValue="patch"
					className="verb-filter-checkbox"
					checked={httpVerbFilter.patch}
					onCheckChanged={(val) => {
						handleCheckboxInput(val, 'patch');
					}}
				/>
				<DxCheckbox
					label="POST"
					itemValue="post"
					className="verb-filter-checkbox"
					checked={httpVerbFilter.post}
					onCheckChanged={(val) => {
						handleCheckboxInput(val, 'post');
					}}
				/>
				<DxCheckbox
					label="PUT"
					itemValue="put"
					className="verb-filter-checkbox"
					checked={httpVerbFilter.put}
					onCheckChanged={(val) => {
						handleCheckboxInput(val, 'put');
					}}
				/>
			</div>
			<DxToggle
				label="Show Deprecated"
				initialValue={deprecatedFilter}
				isTriState={true}
				value={deprecatedFilter}
				onChange={(val) => {
					handleCheckboxInputDeprecated(val);
				}}
				falseIcon={GenesysDevIcons.AppTimes}
				trueIcon={GenesysDevIcons.AppCheck}
			/>
		</div>
	);
}
