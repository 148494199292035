import { IMediaTypes } from './AvailableMediaTypeDefinitions';

import './AvailableMediaTypeTile.scss';

interface IProps {
	type: IMediaTypes;
}

export default function AvailableMediaTypeTile(props: IProps) {
	const { type } = props || [];
	var listSubTypes = null;
	if (type.subMediaTypes) {
		listSubTypes = type.subMediaTypes.map((subType) => <li key={subType}>{subType}</li>);
	}
	return (
		<div className={`mediatype-tile subtypes-${props.type.subMediaTypes.length > 0 ? 'available' : 'na'}`}>
			<div className="tile-container">
				<div className="tile-text media-type">{type.mediaType}</div>
				<div className="tile-text sub-types">
					<ul>{listSubTypes}</ul>
				</div>
			</div>
		</div>
	);
}
