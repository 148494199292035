import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';
import { DxItemGroup, DxItemGroupItem } from 'genesys-react-components';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import AppSettings from '../../helpers/settings/AppSettings';

import './Paginator.scss';

interface IProps {
	itemCount: number;
	minPageSize?: number;
	onPaginationChanged: PaginationChanged;
}

export interface Pagination {
	page: number;
	pageSize: number;
	startItem: number;
	endItem: number;
}

export interface PaginationChanged {
	(pagination: Pagination): void;
}

interface Page {
	pageNumber: number;
	isCurrentPage: boolean;
	isPlaceholder?: boolean;
}

export default function Paginator(props: IProps) {
	const minPageSize = props.minPageSize || 10;
	const [itemCount, setItemCount] = useState(props.itemCount);
	const pageSize = useRecoilValue(AppSettings.paginatorPageSizeAtom());
	const [currentPage, setCurrentPage] = useState(1);

	const PAGE_SIZES = [minPageSize, minPageSize * 2, minPageSize * 3, minPageSize * 5, minPageSize * 8];

	// Constructor
	useEffect(() => {
		// Update the page size to be the next largest value if the saved value isn't in the list
		if (!PAGE_SIZES.includes(pageSize)) {
			let didSet = false;
			PAGE_SIZES.some((knownSize) => {
				// Iterate until we exceed the value and set it
				if (pageSize < knownSize) {
					AppSettings.setPaginatorPageSize(knownSize);
					didSet = true;
					return true;
				}
				return false;
			});

			// Set to last item if we didn't find a bigger one
			if (!didSet) {
				AppSettings.setPaginatorPageSize(PAGE_SIZES[PAGE_SIZES.length - 1]);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const totalPageCount = Math.ceil(itemCount / pageSize);
	const pages = [] as Page[];
	if (totalPageCount <= 10) {
		// Show all the pages, it's not too many
		for (let i = 1; i <= totalPageCount; i++) {
			pages.push({
				pageNumber: i,
				isCurrentPage: i === currentPage,
			});
		}
	} else {
		// It's too many, need to clamp the list
		let startClamp = -1;
		let endClamp = -1;

		// Add the current page
		pages.push({
			pageNumber: currentPage,
			isCurrentPage: true,
		});

		// Add two pages before the current page
		let i = 1;
		while (startClamp < 0) {
			if (i < 3 && currentPage - i > 0) {
				pages.splice(0, 0, {
					pageNumber: currentPage - i,
					isCurrentPage: false,
				});
			} else {
				startClamp = currentPage - i + 1;
			}
			i++;
		}

		// Add beginning
		if (startClamp === 2) {
			pages.splice(0, 0, {
				pageNumber: 1,
				isCurrentPage: false,
			});
		} else if (startClamp > 2) {
			pages.splice(0, 0, {
				pageNumber: -1,
				isCurrentPage: false,
				isPlaceholder: true,
			});
			pages.splice(0, 0, {
				pageNumber: 1,
				isCurrentPage: false,
			});
		}

		// Add one page after the current page
		i = 1;
		while (endClamp < 0) {
			if (i < 2 && currentPage + i <= totalPageCount) {
				pages.push({
					pageNumber: currentPage + i,
					isCurrentPage: false,
				});
			} else {
				endClamp = currentPage + i - 1;
			}
			i++;
		}

		// Add end
		if (endClamp === totalPageCount - 1) {
			pages.push({
				pageNumber: totalPageCount,
				isCurrentPage: false,
			});
		} else if (endClamp < totalPageCount - 1) {
			pages.push({
				pageNumber: -2,
				isCurrentPage: false,
				isPlaceholder: true,
			});
			pages.push({
				pageNumber: totalPageCount,
				isCurrentPage: false,
			});
		}
	}

	// Handles the logic to change the page number
	const setPageNumber = (pageNumber: number) => {
		if (pageNumber < 1) pageNumber = 1;
		if (pageNumber > totalPageCount) pageNumber = totalPageCount;
		if (pageNumber === currentPage) return;

		// Update UI
		setCurrentPage(pageNumber);
	};

	const pageSizeChanged = (item: DxItemGroupItem, isSelected: boolean) => {
		let i = parseInt(item.value);
		const newMaxPages = Math.ceil(itemCount / i);
		if (currentPage > newMaxPages) setCurrentPage(newMaxPages);
		AppSettings.setPaginatorPageSize(i);
	};

	useEffect(() => {
		setItemCount(props.itemCount);
		if ((currentPage - 1) * pageSize > props.itemCount) setCurrentPage(1);
		// This should only trigger when the item count changes to avoid excessive re-rendering
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.itemCount]);

	useEffect(() => {
		// Let subscriber know
		if (props.onPaginationChanged) {
			props.onPaginationChanged({
				page: currentPage,
				pageSize: pageSize,
				startItem: currentPage * pageSize - pageSize + 1,
				endItem: Math.min(currentPage * pageSize, itemCount),
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage, pageSize, itemCount]);

	return (
		<div className="paginator">
			<GenesysDevIcon icon={GenesysDevIcons.AppChevronLeft} onClick={() => setPageNumber(currentPage - 1)} className="page-button" />
			{pages.map((page) =>
				page.isPlaceholder ? (
					<span key={page.pageNumber}>...</span>
				) : (
					<button
						key={page.pageNumber}
						type="button"
						onClick={page.isCurrentPage ? undefined : () => setPageNumber(page.pageNumber)}
						className={page.isCurrentPage ? 'selected' : ''}
						disabled={page.isCurrentPage}
					>
						{page.pageNumber}
					</button>
				)
			)}
			<GenesysDevIcon icon={GenesysDevIcons.AppChevronRight} onClick={() => setPageNumber(currentPage + 1)} className="page-button" />
			<span className="results-text">results per page:</span>
			<DxItemGroup
				className="page-sizes"
				format="dropdown"
				items={PAGE_SIZES.map((s) => {
					return { label: `${s}`, value: `${s}`, isSelected: s === pageSize };
				})}
				onItemChanged={pageSizeChanged}
			/>
		</div>
	);
}
