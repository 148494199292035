import { parse } from 'svg-parser';

// Util function for getting the SVG viewbox (eg. [0, 0, 128, 128])
export async function getSvgDimensions(file: File): Promise<Array<number>> {
	// Get the text content and parse svg to HAST object
	const value = await file.text();
	const parsedSVG = parse(value);

	if (!parsedSVG) throw Error('Invalid SVG.');

	// Try to get the svg tag
	const rootChildren = parsedSVG.children;
	const svgTag = rootChildren.find((c) => c.type === 'element' && c.tagName?.toLocaleLowerCase() === 'svg') as svgParser.ElementNode;
	if (!svgTag) throw Error("SVG tag can't be foundation. This might be an invalid SVG.");

	if (!svgTag.properties || svgTag.properties.xmlns !== 'http://www.w3.org/2000/svg') {
		throw Error('SVG namespace (xmnls) does not exist or is invalid.');
	}

	// Try to get viewBox string
	const viewBox = svgTag.properties?.viewBox as string;
	if (!viewBox) throw Error('Viewbox not found and SVG dimensions cannot be determined.');

	// Get dimensions
	let viewBoxArr = [];
	if (viewBox.includes(',')) {
		viewBoxArr = viewBox.split(',').map((x) => parseFloat(x));
	} else {
		viewBoxArr = viewBox.split(' ').map((x) => parseFloat(x));
	}
	if (viewBoxArr.length < 4) throw Error('Invalid viewbox value.');

	let dimensions = [viewBoxArr[2], viewBoxArr[3]];

	return dimensions;
}

// Convert string to camel case
export function camelize(text: string): string {
	const a = text.toLowerCase().replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''));
	return a.substring(0, 1).toLowerCase() + a.substring(1);
}
