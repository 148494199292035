import { atom } from 'recoil';
import { getRecoil, setRecoil } from 'recoil-nexus';

export interface Toast {
	title?: string;
	message: string;
	link?: string;
	linkText?: string;
	key?: any;
	toastType?: ToastType;
	timeoutSeconds?: number;
}

export enum ToastType {
	Info = 'info',
	Warning = 'warning',
	Critical = 'critical',
	Success = 'success',
}

// Users need enough time to read the toast before it disappears
export const MinimumToastTimeoutSeconds = 10;

export const toastAtom = atom({
	key: 'toasts',
	default: [] as Toast[],
});

// addToast adds a new toast to the list
export function addToast(toast: Toast) {
	if (!toast.key) toast.key = Date.now();
	const toasts = getRecoil(toastAtom);
	setRecoil(toastAtom, [toast, ...toasts]);
	// This trace message is intentional and should not be removed. This leaves evidence of the toast message in the console.
	console.log(`[TOAST::${toast.toastType}] ${toast.title ? `${toast.title} - ` : ''}${toast.message} ${toast.link || ''}`);

	// Set timer to clear the toast
	if (toast.timeoutSeconds && toast.timeoutSeconds >= MinimumToastTimeoutSeconds) {
		setTimeout(() => removeToast(toast), toast.timeoutSeconds * 1000);
	}
}

// removeToast removes the specified element from the list
export function removeToast(toast: Toast) {
	// Find toast
	let toasts = getRecoil(toastAtom);
	const idx = toasts.indexOf(toast);
	if (idx < 0) return;

	// Remove toast
	const newToasts = [...toasts];
	newToasts.splice(idx, 1);
	setRecoil(toastAtom, newToasts);
}
