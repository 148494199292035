import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';
import { DxButton, DxTextbox } from 'genesys-react-components';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import AssetLoader from '../../helpers/AssetLoader';
import { addToast, ToastType } from '../../helpers/atoms/ToastAtom';

import DxLink from '../dxlink/DxLink';
import AlertBlock from '../markdown/alertblock/AlertBlock';

import './Feedback.scss';
import yeti from './whoa-buddy-yeti.png';

interface IProps {}

interface FeedbackData {
	id: string;
	page: string;
	helpful?: boolean;
	metrics: FeedbackMetrics;
	email?: string;
	message?: string;
	name?: string;
	[key: string]: string | boolean | FeedbackMetrics | undefined;
}

enum MetricKey {
	Understandable = 'Understandable',
	Accurate = 'Accurate',
	Detail = 'Detail',
	Mood = 'Mood',
}

type FeedbackMetrics = {
	[key in MetricKey]?: number | undefined;
};

type DisplayState = 'closed' | 'open' | 'complete';

const EMAIL_REGEX = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export default function Feedback(props: IProps) {
	const [displayState, setDisplayState] = useState<DisplayState>('closed');
	const [feedback, setFeedback] = useState<FeedbackData>({
		id: uuidv4(),
		page: window.location.href,
		metrics: {},
		name: '',
		email: '',
		message: '',
	});
	const [isNameValid, setIsNameValid] = useState<boolean | undefined>();
	const [isEmailValid, setIsEmailValid] = useState<boolean | undefined>();
	const [isMessageValid, setIsMessageValid] = useState<boolean | undefined>();
	const [validationError, setValidationError] = useState<string | undefined>();
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	// feedback changed
	useEffect(() => {
		// console.log('feedback', feedback, feedbackId.current);

		// Validate
		const tempIsNameValid = !feedback.name ? undefined : feedback.name.trim().length >= 3;
		const tempIsEmailValid = !feedback.email
			? undefined
			: feedback.email.match(EMAIL_REGEX) !== null; /* && !feedback.email.toLowerCase().endsWith('@genesys.com'); */ //HACK: enable employee submissions during beta
		const tempIsMessageValid = !feedback.message ? undefined : feedback.message.trim().length >= 15;

		// Update valid states
		setIsNameValid(tempIsNameValid);
		setIsEmailValid(tempIsEmailValid);
		setIsMessageValid(tempIsMessageValid);

		// Set error message
		if (tempIsNameValid === false) {
			setValidationError('Your name must be at least three characters long');
		} else if (tempIsEmailValid === false) {
			setValidationError('Your email address is invalid');
		} else if (tempIsMessageValid === false) {
			setValidationError('Your message must be at least 15 characters long');
		} else if (feedback.email && feedback.email.toLowerCase().endsWith('@genesys.com')) {
			// Block employee submissions - internals should use internal communications!
			setIsEmailValid(false);
			setValidationError(
				"The Developer Center Feedback form is for external customer and partner use only. If you are a Genesys employee and believe you have found a defect within the documentation, please follow your department's standard defect reporting process. To report issues with the site itself or if you are unsure how to get assistance via internal channels, please join us in the GC Developer Engagement chat room."
			);
		} else {
			setValidationError(undefined);
		}
	}, [feedback]);

	const displayOpen = () => {
		const updateData = (name: string, value: string) => {
			const f = Object.assign({}, feedback);
			f[name] = value;
			setFeedback(f);
		};
		const setFeedbackMetric = (metricKey: MetricKey, value: number) => {
			const f = Object.assign({}, feedback);
			f.metrics[metricKey] = value;
			setFeedback(f);
		};
		const metricsRow = (question: string, metricKey: MetricKey) => (
			<div className="metric-row">
				<span className="metric-question">{question}</span>
				<span className={`metric-icon${feedback.metrics[metricKey] !== 1 ? ' dim' : ''}`} onClick={() => setFeedbackMetric(metricKey, 1)}>
					🤯
				</span>
				<span className={`metric-icon${feedback.metrics[metricKey] !== 2 ? ' dim' : ''}`} onClick={() => setFeedbackMetric(metricKey, 2)}>
					🤕
				</span>
				<span className={`metric-icon${feedback.metrics[metricKey] !== 3 ? ' dim' : ''}`} onClick={() => setFeedbackMetric(metricKey, 3)}>
					🤨
				</span>
				<span className={`metric-icon${feedback.metrics[metricKey] !== 4 ? ' dim' : ''}`} onClick={() => setFeedbackMetric(metricKey, 4)}>
					😄
				</span>
			</div>
		);

		const enableSubmit = isNameValid !== false && isEmailValid !== false && isMessageValid !== false;

		return (
			<div className="feedback-open">
				<h3 className="title">Feedback Questionnaire</h3>
				<p>Thanks for letting us know!</p>
				<p>
					Need help? Come engage with the Genesys Cloud developer community on the{' '}
					<DxLink href="https://developer.genesys.cloud/forum/" forceNewTab={true}>
						Genesys Cloud Developer Forum
					</DxLink>{' '}
					or open a case with{' '}
					<DxLink href="https://help.mypurecloud.com/articles/contact-genesys-cloud-care/" forceNewTab={true}>
						Genesys Cloud Customer Care
					</DxLink>
				</p>
				<p>
					Have a moment for a few more questions? If there's anything you'd like to let us know, fill out the form and we'll get back to
					you.
				</p>
				<div className="metrics-container">
					{metricsRow('Did you find the content understandable?', MetricKey.Understandable)}
					{metricsRow('Is the content accurate?', MetricKey.Accurate)}
					{metricsRow('How is the level of detail?', MetricKey.Detail)}
					{metricsRow("What's your mood?", MetricKey.Mood)}
				</div>
				<form>
					<div className="input-row">
						<DxTextbox
							className={isNameValid === false ? 'invalid' : ''}
							label="Name"
							placeholder="Name (Optional)"
							onChange={(value) => updateData('name', value)}
						/>
						<DxTextbox
							className={isEmailValid === false ? 'invalid' : ''}
							inputType="email"
							label="Email Address"
							placeholder="Email Address (Optional)"
							onChange={(value) => updateData('email', value)}
						/>
					</div>
					<div className="input-row">
						<DxTextbox
							className={`feedback-message${isMessageValid === false ? ' invalid' : ''}`}
							inputType="textarea"
							label="Message"
							placeholder="Leave a message (Optional)"
							onChange={(value) => updateData('message', value)}
						/>
					</div>
					{!validationError ? undefined : (
						<AlertBlock className="validation-error" alertType="critical">
							{validationError}
						</AlertBlock>
					)}
					<div className="input-row submit-row">
						<DxButton disabled={!enableSubmit || isSubmitting} onClick={submitFeedback}>
							Submit Feedback
						</DxButton>
						<DxButton disabled={isSubmitting} type="secondary" onClick={() => setDisplayState('complete')}>
							Cancel
						</DxButton>
					</div>
				</form>
			</div>
		);
	};

	const submitFeedback = () => {
		// Submit feedback
		setIsSubmitting(true);
		AssetLoader.put('/_lambda/feedback', feedback, 'application/json')
			.then(() => {
				setIsSubmitted(true);
				setIsSubmitting(false);

				// Close form
				setDisplayState('complete');
			})
			.catch((err) => {
				setIsSubmitted(true);
				setIsSubmitting(false);

				console.error('Failed to submit feedback:', err?.message || '', err);
				addToast({
					toastType: ToastType.Critical,
					title: 'Feedback submission failed',
					message:
						'There was an error submitting your feedback, but we still would like to hear from you. Please report this error and provide your feedback on the developer forum.',
					link: 'https://developer.genesys.cloud/forum/',
					linkText: 'Genesys Cloud Developer Forum',
				});
			});
	};

	const displayComplete = () => (
		<div className="feedback-complete" style={{ backgroundImage: `url(${yeti})` }}>
			<div className="thanks-content">
				<div className="thanks-heading">
					<GenesysDevIcon icon={GenesysDevIcons.AppHandshake} />
					<span>Thanks for the feedback!</span>
				</div>
				<p>
					{isSubmitted
						? "We'll be in touch with you soon. In the meantime, check out these additional resources:"
						: 'Check out these additional resources:'}
				</p>
				<ul>
					<li>
						<DxLink href="https://help.mypurecloud.com/">Resource Center</DxLink> for product documentation
					</li>
					<li>
						<DxLink href="https://developer.genesys.cloud/forum/">Developer Forum</DxLink> for API and customization discussions
					</li>
					<li>
						<DxLink href="https://community.genesys.com/communities/purecloud?CommunityKey=bab95e9c-6bbe-4a13-8ade-8ec0faf733d4">
							Community Forum
						</DxLink>{' '}
						for general product discussions
					</li>
					<li>
						<DxLink href="https://help.mypurecloud.com/articles/contact-genesys-cloud-care/">Customer Care</DxLink> for product and platform
						support
					</li>
				</ul>
			</div>
		</div>
	);

	const displayClosed = () => (
		<div className="feedback-closed">
			<span>Was this page helpful?</span>
			<GenesysDevIcon className="thumbs-icon" icon={GenesysDevIcons.AppThumbsUp} onClick={() => initialFeedback(true)} />
			<GenesysDevIcon className="thumbs-icon" icon={GenesysDevIcons.AppThumbsDown} onClick={() => initialFeedback(false)} />
		</div>
	);

	const initialFeedback = (isPositive: boolean) => {
		setFeedback({ ...feedback, helpful: isPositive === true });
		setDisplayState('open');
	};

	let content;
	switch (displayState) {
		case 'open': {
			content = displayOpen();
			break;
		}
		case 'complete': {
			content = displayComplete();
			break;
		}
		default: {
			content = displayClosed();
		}
	}

	return <div className={`feedback${displayState === 'complete' ? ' complete' : ''}`}>{content}</div>;
}
