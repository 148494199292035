import React, { ReactNode, useState } from 'react';
import { DxButton } from 'genesys-react-components';

import Tooltip from '../tooltip/Tooltip';

interface IProps {
	tooltipText: string;
	onClick?: { (): void };
	className?: string;
	children: ReactNode;
	type?: 'primary' | 'secondary' | 'link';
}

export default function TooltipButton(props: IProps) {
	const [isTooltipActive, setIsTooltipActive] = useState<boolean>(false);

	const onClick = () => {
		// Tooltip control
		setIsTooltipActive(true);
		setTimeout(() => setIsTooltipActive(false), 1000);

		// Notify parent
		if (props.onClick) props.onClick();
	};

	return (
		<Tooltip text={props.tooltipText} className={props.className || ''} isShowing={isTooltipActive} position="top">
			<DxButton type={props.type || 'primary'} onClick={onClick}>
				{props.children}
			</DxButton>
		</Tooltip>
	);
}
