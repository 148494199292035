import React, { useEffect, useState } from 'react';

import AssetLoader from '../../helpers/AssetLoader';
import DataTable, { DataTableRow } from '../markdown/datatable/DataTable';
import LoadingPlaceholder from '../loadingplaceholder/LoadingPlaceholder';

import './Changelog.scss';
import AlertBlock from '../markdown/alertblock/AlertBlock';

interface IChangelogData {
	timestamp: string;
	path: string;
	changeType: ChangeType;
	fingerprint: string;
	origin: string;
	title: string;
}

enum ChangeType {
	ADDED = 'added',
	CHANGED = 'changed',
	REMOVED = 'removed',
}

/**
 * Displays the most recent additions, removals, and changes to dx-ui pages.
 * Number of results to display is configurable on the page via user input.
 *
 * @returns changelog
 */
export default function Changelog() {
	const [error, setError] = useState<string>(''); // displays if there is an error loading changelog data
	const [rowData, setRowData] = useState<DataTableRow[] | undefined>();

	// Constructor
	useEffect(() => {
		AssetLoader.get('/data/changelog.json', true, undefined)
			.then((data: IChangelogData[]) => {
				const newRowData: DataTableRow[] = data.map((entry: IChangelogData) => {
					return {
						cells: [
							{ content: entry.title },
							{ content: entry.changeType },
							{ content: new Date(Number.parseInt(entry.timestamp) || 0).toLocaleDateString() },
							{ content: formatPath(entry.path, entry.changeType) },
						],
					} as DataTableRow;
				});
				setRowData(newRowData);
			})
			.catch((err) => {
				console.error(err);
				setError(err.message || 'unknown error');
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Returns plain text path for pages that were removed, otherwise returns a link
	 *
	 * @param path the path to the file
	 * @param changeType the type of change made to the file
	 * @returns the formatted path
	 */
	function formatPath(path: string, changeType: ChangeType): string {
		if (changeType === ChangeType.REMOVED) {
			return `/${path}`;
		} else {
			return `[/${path}](/${path})`;
		}
	}

	return (
		<div>
			{error && (
				<AlertBlock alertType="critical" title="Error loading changelog">
					{error}
				</AlertBlock>
			)}
			{rowData && (
				<DataTable
					filterable={true}
					sortable={true}
					headerRow={{
						cells: [{ content: 'Page Title' }, { content: 'Change Type' }, { content: 'Change Date' }, { content: 'Link' }],
					}}
					rows={rowData}
				/>
			)}
			{!rowData && !error && <LoadingPlaceholder />}
		</div>
	);
}
