import React from 'react';
import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';
import { useRecoilValue } from 'recoil';

import Tooltip from '../../../tooltip/Tooltip';
import { OperationDetails } from '../../../../helpers/openapi/OpenAPITypes';
import AppSettings from '../../../../helpers/settings/AppSettings';
import { selectedAccountAtom } from '../../../../helpers/atoms/AccountsAtom';

import './PermissionsDisplay.scss';

interface IProps {
	operationDetails: OperationDetails;
}

export default function PermissionsDisplay(props: IProps) {
	const readingMode = useRecoilValue(AppSettings.apiExplorerReadingModeAtom());
	const selectedAccount = useRecoilValue(selectedAccountAtom);

	let content;
	let permissionType: string = props.operationDetails.operation['x-inin-requires-permissions']?.type || '';
	let overallPermission: boolean;
	if (permissionType.toLowerCase() === 'any') {
		overallPermission = false;
	} else if (permissionType.toLowerCase() === 'all') {
		overallPermission = true;
	} else {
		overallPermission = true;
	}
	if (!props.operationDetails.operation['x-inin-requires-permissions']) {
		content = (
			<p>
				<em>No permissions required</em>
			</p>
		);
	} else {
		content = (
			<div>
				<p>
					Apps making this request must have {props.operationDetails.operation['x-inin-requires-permissions'].type} of these permissions:
				</p>
				<ul>
					{props.operationDetails.operation['x-inin-requires-permissions'].permissions.map((p) => {
						const userHasPermission: boolean = selectedAccount?.me?.authorization?.permissions?.some(
							(permission: any) => permission.startsWith(p)
						) as boolean;
						if (permissionType.toLowerCase() === 'any') {
							if (userHasPermission) {
								overallPermission = true;
							}
						}
						if (permissionType.toLowerCase() === 'all') {
							if (!userHasPermission) {
								overallPermission = false;
							}
						}
						return (
							<li key={p}>
								{p}
								{!readingMode && selectedAccount && (
									<Tooltip text={userHasPermission ? 'Active account has this permission' : 'Active account is missing this permission'}>
										<GenesysDevIcon
											className={`permission-icon permission-icon--${userHasPermission ? 'check' : 'x'}`}
											icon={userHasPermission ? GenesysDevIcons.AppCheck : GenesysDevIcons.AppTimes}
										/>
									</Tooltip>
								)}
							</li>
						);
					})}
				</ul>
			</div>
		);
	}

	return (
		<div className="permissions-display">
			<h3>
				Required Permissions
				{!readingMode && selectedAccount && (
					<Tooltip text={overallPermission ? 'Active account has this permission' : 'Active account is missing this permission'}>
						<GenesysDevIcon
							className={`permission-icon-large permission-icon--${overallPermission ? 'check' : 'x'}`}
							icon={overallPermission ? GenesysDevIcons.AppCheckSolid : GenesysDevIcons.AppTimesSolid}
						/>
					</Tooltip>
				)}
			</h3>
			{content}
		</div>
	);
}
