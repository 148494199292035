import React from 'react';

import { default as NavMan } from '../../helpers/NavigationManager';
import { Link } from 'react-router-dom';

function RelativeSitemap() {
	const printSitemap = (sitemap, depth) => {
		if (depth === undefined) depth = 2;
		if (depth < 1) return null;
		return (
			<ul>
				{Object.entries(sitemap)
					.filter(
						([key, value]) =>
							typeof value === 'object' &&
							key !== 'index' &&
							value !== null &&
							((value.isDir && value.index && NavMan.isVisible(value.index)) || (value.title && NavMan.isVisible(value)))
					)
					.map(([key, value]) => {
						if (value.isDir) {
							return (
								<li key={key}>
									<Link to={value.index.link}>{value.index.title}</Link>
									{printSitemap(value, depth - 1)}
								</li>
							);
						}
						return (
							<li key={key}>
								<Link to={value.link}>{value.title}</Link>
							</li>
						);
					})}
			</ul>
		);
	};
	return printSitemap(NavMan.getRelativeSitemap(NavMan.pathname), NavMan.getPage(NavMan.pathname).levels);
}

export default RelativeSitemap;
