import React, { useState } from 'react';
import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';

import TopicContent from './TopicContent';
import { Topic } from './NotificationDefinitions';

import './TopicTile.scss';

interface IProps {
	topic: Topic;
}

export default function TopicTile(props: IProps) {
	const { topic } = props || [];
	const isLinkedTo = window.location.hash.toLocaleLowerCase() === `#${props.topic.idKey.toLowerCase()}`;
	const [isExpanded, setIsExpanded] = useState(isLinkedTo || false);
	let content;

	if (isExpanded) {
		content = <TopicContent topic={topic} />;
	}

	return (
		<div id={topic.idKey} className={`topics-tile transport-${props.topic.transports.join('and')}`}>
			<div className="tile-container">
				<div
					onClick={(e) => {
						setIsExpanded(!isExpanded);
					}}
					className="tile-info-wrapper"
				>
					<div className={`topic${isExpanded ? ' expanded' : ''}`}>
						<div className="tile-text topic-id">{topic.id}</div>
						<div className="tile-text">{topic.description}</div>
					</div>
					{<GenesysDevIcon icon={isExpanded ? GenesysDevIcons.AppChevronUp : GenesysDevIcons.AppChevronDown} />}
				</div>

				{content}
			</div>
		</div>
	);
}
