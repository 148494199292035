import { Moment } from 'moment';

/**
 * Named MinimalTopic because there is a special case in the code where
 * I only request for a couple of fields for a topic.
 * There was no need to request for all the fields and parse it like I have for
 * the main Topic type.
 */
export interface MinimalTopic {
	requiresPermissions?: string[];
	enforced?: boolean;
	transports: string[];
	id: string;
}

//This is the main topic type being used.
export interface Topic extends MinimalTopic {
	idKey: string;
	description: string;
	exampleSchema: Schema;
	uriPaths: string[];
	requiredPermissions: RequiredPermissions;
	topicParameters: string[];
	visibility?: Visibility;
}

// https://github.com/MyPureCloud/platform-client-sdk-javascript/blob/master/build/index.d.ts#L8692
export interface ChannelEntityListing {
	entities?: Array<Channel>;
}

// https://github.com/MyPureCloud/platform-client-sdk-javascript/blob/master/build/index.d.ts#L8686
export interface Channel {
	connectUri?: string;
	id?: string;
	expires?: string;
}

export interface RequiredPermissions {
	permission: string;
	permissionList: string[];
	enforced: boolean;
}

export interface Schema {
	id: string;
	type: string;
	description?: string;
	properties: any[];
}

export interface Permissions {
	permissionDetails?: PermissionDetails[];
	requiresCurrentUser: boolean;
	requiresPermissions: string[];
	requiresDivisionPermissions: boolean;
	requiresCurrentUserOrPermission: boolean;
	enforced: boolean;
}

export interface PermissionDetails {
	type: string;
	permissions: string[];
	allowsCurrentUser: boolean;
	enforced: boolean;
}

export interface IProps {
	source: string;
}

export interface TopicEvent {
	eventData: any;
	time: Moment;
	connectUrl: string;
	isPinned: boolean;
}

export enum Visibility {
	Public = 'Public',
	Preview = 'Preview',
}
