import axios, { AxiosInstance, AxiosRequestConfig, Method } from 'axios';
import { AccountData, EntityData, EntityCallback, GetEntityFuncOuter, GetEntityFuncOuterNoParam, GetEntityFuncInner, EntityResponse } from '../../types';
import { Models } from '../platformapi/PlatformAPITypes';
import { Region } from '../platformapi/Region';

export class Account {
	public initialized = false;
	public token: string;
	public region: Region;
	public me?: Models.UserMe;
	public profileImageUri?: string;
	public api: AxiosInstance;
	public safeMode: boolean;
	public getDivisions: GetEntityFuncOuter;
	public getQueues: GetEntityFuncOuter;
	public getUsers: GetEntityFuncOuter;
	public getSystemPresences: GetEntityFuncOuterNoParam;
	public getOrgPresences: GetEntityFuncOuter;
	public getEntities: GetEntityFuncInner;
	public defaultCallback: EntityCallback;

	private _userId: string;
	private _divisionCache?: any[];
	private _queueCache?: any[];
	private _userCache?: any[];
	private _systemPresenceCache?: any[];
	private _orgPresenceCache?: any[];
	private _isLoading: boolean;

	constructor(token: string, region: Region) {
		this.token = token;
		this.region = region;
		this._userId = '';
		this._isLoading = false;
		this.safeMode = false;

		this.api = axios.create({
			baseURL: `https://api.${encodeURIComponent(this.region)}`,
			headers: {
				Authorization: `bearer ${this.token}`,
			},
		});

		this.defaultCallback = (entity: any): EntityData => {
			return {id: entity.id, name: entity.name};
		};

		this.getEntities = async (baseUri: string, cb?: EntityCallback, entities?: EntityData[], nextUri?: string, customConfig?: AxiosRequestConfig): Promise<EntityData[]> => {
			const requestConfig: AxiosRequestConfig = customConfig || {
				method: "GET" as Method,
				params: { pageSize: "100" }
			}
			requestConfig.url = nextUri || baseUri;
			try {
				const response: EntityResponse = await this.api.request(requestConfig);
				if (cb) {
					if (response.status === 429) {
						console.log('429');
						const retryAfter: number = response.headers?.['retry-after'] || 10000;
						await new Promise((resolve) => setTimeout(resolve, retryAfter));
						return this.getEntities(baseUri, cb, entities, nextUri, customConfig);
					} else if (response.status === 200) {
						let entitySlice: EntityData[] = response.data?.entities?.map((entity: any) => {
							return cb(entity);
						}) || [];
						const newEntities: EntityData[] = (entities || []).concat(entitySlice);
						let entityData: any = response?.data || {};
						if (entityData.nextUri) {
							return this.getEntities(baseUri, cb, newEntities, entityData.nextUri, customConfig);
						} else {
							return newEntities;
						}
					} else {
						return [] as EntityData[];
					}
				} else {
					return response.data as EntityData[];
				}
			} catch (err: any) {
				console.error("failed to load entities for the logged-in user's organization", err);
				return entities || [];
			}
		}

		this.getDivisions = async (divisions?: EntityData[]): Promise<EntityData[]> => {
			const baseUri = "/api/v2/authorization/divisions";
			return this.getEntities(baseUri, this.defaultCallback, divisions);
		}

		this.getQueues = async (queues?: EntityData[]): Promise<EntityData[]> => {
			const baseUri = "/api/v2/routing/queues";
			return this.getEntities(baseUri, this.defaultCallback, queues);
		}

		this.getUsers = async (users?: EntityData[]): Promise<EntityData[]> => {
			const baseUri = "/api/v2/users";
			return this.getEntities(baseUri, this.defaultCallback, users);
		}

		this.getSystemPresences = async (): Promise<EntityData[]> => {
			const baseUri = "/api/v2/systempresences";
			const config: AxiosRequestConfig = {
				method: "GET" as Method,
			}
			return this.getEntities(baseUri, undefined, undefined, undefined, config);
		}
		
		this.getOrgPresences = async (orgPresences?: EntityData[]): Promise<EntityData[]> => {
			const baseUri = "/api/v2/presencedefinitions";
			const cb = (entity: any): EntityData => {
				const orgPresenceName: string = Object.values(entity.languageLabels || {}).length > 0 
					? Object.values(entity.languageLabels)[0] 
					: entity.systemPresence || '';
				return {id: entity.id, name: orgPresenceName};
			};
			return this.getEntities(baseUri, cb, orgPresences);
		}
	}

	public async init(): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			this.api
				.get('/api/v2/users/me?expand=organization,authorization')
				.then((res) => {
					this.me = res.data;

					// Sort images
					this.me?.images?.sort((a: Models.UserImage, b: Models.UserImage) =>
						parseInt(a.resolution || ''.substring(1)) > parseInt(b.resolution || ''.substring(1)) ? 1 : -1
					);
					// Use smallest image
					if (this.me?.images && this.me?.images.length > 0) this.profileImageUri = (this.me?.images || [])[0].imageUri;
					this.initialized = true;
					resolve();
				})
				.catch(reject);
		});
	}

	public asData(): AccountData {
		return { token: this.token, region: this.region, userId: this.userId };
	}

	get userId() {
		return this.me?.id || this._userId;
	}

	get isLoading() {
		return this._isLoading;
	}

	get divisionCache() {
		return this._divisionCache;
	}

	get queueCache() {
		return this._queueCache;
	}

	get userCache() {
		return this._userCache;
	}

	get systemPresenceCache() {
		return this._systemPresenceCache;
	}

	get orgPresenceCache() {
		return this._orgPresenceCache;
	}

	get orgName() {
		return this.me?.organization?.name;
	}

	get name() {
		return this.me?.name || 'Loading...';
	}

	public setUserId(value?: string) {
		this._userId = value || '';
	}

	public setApi(api: AxiosInstance) {
		this.api = api;
	}

	public setDivisionCache(value: any[]) {
		this._divisionCache = value;
	}

	public setQueueCache(value: any[]) {
		this._queueCache = value;
	}

	public setUserCache(value: any[]) {
		this._userCache = value;
	}

	public setSystemPresenceCache(value: any[]) {
		this._systemPresenceCache = value;
	}

	public setOrgPresenceCache(value: any[]) {
		this._orgPresenceCache = value;
	}

	public setIsLoading(value: boolean) {
		this._isLoading = value;
	}
}
