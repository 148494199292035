import AssetLoader from './AssetLoader';

import { default as ContentPages } from './ContentPages';
import { ContextualNavigationChanged, Page, PathnameChanged, Sitemap, SitemapLoaded } from './NavigationManager.types';

const removeIndexRegex = /(.+?\/)(?:index.html)?$/i;
const pathWildcardRegex = /^(.+?)(\*?)$/i;

class NavigationManager {
	isSitemapLoaded: boolean;
	sitemap: Sitemap;
	pathname: string;
	contextualNavigation: Page[];
	contextualNavigationLevel: number;
	contextualNavigationPathname: string;
	sitemapLoadedHandlers: SitemapLoaded[];
	pathnameChangedHandlers: PathnameChanged[];
	contextualNavigationChangedHandlers: ContextualNavigationChanged[];
	page?: Page;

	constructor() {
		this.isSitemapLoaded = false;
		this.sitemap = {} as Sitemap;
		this.pathname = '/';
		this.contextualNavigation = [];
		this.contextualNavigationLevel = 0;
		this.contextualNavigationPathname = '';
		this.sitemapLoadedHandlers = [];
		this.pathnameChangedHandlers = [];
		this.contextualNavigationChangedHandlers = [];

		// Load sitemap
		AssetLoader.get('/data/sitemap.json', true, undefined)
			.then((response) => {
				this.isSitemapLoaded = true;
				this.sitemap = response;

				// Add content pages to sitemap
				Object.entries(ContentPages).forEach(([pathname, page]) => {
					const parts = pathname.split('/').filter((e) => e !== '');

					// Drill down into sitemap
					let sm = this.sitemap;
					const len = pathname.endsWith('/') ? parts.length : parts.length - 1;
					for (let i = 0; i < len; i++) {
						if (!sm[parts[i]]) {
							sm[parts[i]] = {
								isDir: true,
								path: sm.path + parts[i] + '/',
							};
						}
						sm = sm[parts[i]];
					}

					// Normalize page
					if (!page.link) page.link = pathname;

					// Add page to sitemap
					if (pathname.endsWith('/')) {
						sm.index = page as Page;
					} else {
						sm[parts[parts.length - 1]] = page;
					}
				});
				raiseSitemapLoaded();
				// Also trigger events for things that depend on the sitemap
				// raisePathnameChanged();
				// raiseContextualNavigationChanged();
				this.setPathname(this.pathname);
			})
			.catch(console.error);
	}

	/*** CALLBACKS ***/

	// Sets a callback for when the sitemap is loaded
	onSitemapLoaded(callback: SitemapLoaded, unset = false) {
		setCallback(this.sitemapLoadedHandlers, callback, unset);
		if (callback && !unset) {
			// Ensure anyone that's late to the game can still be event driven
			if (this.isSitemapLoaded) callback(this.sitemap);
		}
	}

	// Sets a callback for when the URL changes
	onPathnameChanged(callback: PathnameChanged, unset = false) {
		setCallback(this.pathnameChangedHandlers, callback, unset);
	}

	onContextualNavigationChanged(callback: ContextualNavigationChanged, unset = false) {
		setCallback(this.contextualNavigationChangedHandlers, callback, unset);
	}

	/** NAVIGATION FUNCTIONS ***/

	// Sets the pathname and triggers notifications
	setPathname(pathname: string) {
		const page = this.getPage(pathname);
		this.page = page as Page;
		this.pathname = pathname;
		raisePathnameChanged();

		// Initialize nav to sitemap (default)
		this.setContextualNavigation(pathname, this.getNavLinks(instance.getRelativeSitemap(pathname) as Page, true, true) || [], 0);
	}

	setPage(page: Page) {
		this.page = page;
	}

	setContextualNavigation(pathname: string, links = [] as Page[], level = 1) {
		// Ignore data if old path still matches and new data is lower priority
		if (this.isPathnameMatch(this.contextualNavigationPathname, pathname) && level < this.contextualNavigationLevel) return;

		// Check for path match
		if (!this.isPathnameMatch(pathname, this.pathname)) return;

		// Set data
		this.contextualNavigation = links;
		this.contextualNavigationLevel = level;
		this.contextualNavigationPathname = pathname;

		// Raise event
		raiseContextualNavigationChanged();
	}

	/*** PUBLIC HELPERS ***/

	// Gets a page by its path
	getPage(pathname: String, returnDirs = false) {
		if (!pathname) return;

		// Quick return for root
		if (pathname === '/') return returnDirs ? this.sitemap : this.sitemap.index;

		// Drill down to current directory
		let context = this.sitemap;
		let parts = pathname.split('/').filter((part) => part !== '');
		let found;
		if (parts.length > 0) {
			found = parts.every((part) => {
				if (!context[part]) return false;
				context = context[part];
				return true;
			});
		}

		// Abort if page not found
		if (!found) return;

		// Figure out what to return
		const ret = context.isDir && !returnDirs ? context.index : context;
		return ret;
	}

	getNavLinks(context: Page, includePages = true, includeDirs = true) {
		if (!context) return [];
		let siblings = [] as Page[];

		const match = /(.*\/).+?[^/]$/.exec(context.link);
		if (match) {
			context = this.getPage(match[1], true) as Page;
		}
		Object.entries(context).forEach(([key, page]) => {
			if (typeof page !== 'object') return;
			if (includeDirs && page.isDir && page.index && this.isVisible(page.index)) {
				siblings.push(page.index);
			} else if (includePages && !page.isDir && this.isVisible(page) && !page.link.endsWith('/')) {
				siblings.push(page);
			}
		});

		// Sort
		siblings
			.sort((a, b) => {
				if (a.order && !b.order) return 1; // Only A has order
				if (!a.order && b.order) return -1; // Only B has order
				if (b.order < a.order) return -1; // Order of A/B is incorrect (smaller goes first)
				if (a.order < b.order) return 1; // Order of A/B is correct (smaller goes first)
				return b.title.localeCompare(a.title, undefined, { numeric: true, sensitivity: 'base' }); // Sort by title
			})
			.reverse();

		return siblings;
	}

	// Returns the sitemap relative the provided path
	getRelativeSitemap(pathname: string): Sitemap | Page | undefined {
		// Drill down to current directory
		let context = this.sitemap;
		let parts = pathname.split('/').filter((part) => part !== '');
		if (parts.length > 0) {
			parts.every((part) => {
				if (!context[part] || !context[part].isDir) return false;
				context = context[part];
				return true;
			});
		}

		return context;
	}

	// Function for normalizing paths
	scrubLink(link: string) {
		let ret = link.replace('/index.html', '/');
		return ret;
	}

	// Determines if a page should be displayed in navigation and list contexts
	isVisible(page?: Page): Boolean {
		if (!page) return false;

		// Look at index page if is dir
		if (page.index && typeof page.index === 'object') return this.isVisible(page.index);

		// Look at page
		return page && (page.forceShow || (!page.notoc && !page.private && !page.redirect && !page.hardRedirect));
	}

	// Determines if two URLs refer to the same resource
	isSameLink(a: string, b: string) {
		if (a === b) return true;
		const x = removeIndexRegex.exec(a);
		const y = removeIndexRegex.exec(b);
		return x && y ? x[1] === y[1] : false;
	}

	// Checks to see if a path is a wildcard or exact matchf
	isPathnameMatch(needle: string, haystack: string) {
		const match = needle.match(pathWildcardRegex);
		if (!match) return false;
		if (match[2] && !haystack.toLowerCase().startsWith(match[1].toLowerCase())) return;
		if (!match[2] && match[1].toLowerCase() !== haystack) return;
		return true;
	}
}

// Singleton instance
const instance = new NavigationManager();
export default instance;

/*** PRIVATE FUNCTIONS ***/

// Sets or removes a callback for a given list of callbacks
function setCallback(list: any[], callback: any, unset = false) {
	if (callback && !unset) {
		list.push(callback);
	} else if (callback && unset) {
		let i = list.indexOf(callback);
		if (i >= 0) list.splice(i, 1);
	}
}

// Invoke sitemap loaded callbacks
function raiseSitemapLoaded() {
	instance.sitemapLoadedHandlers.forEach((callback) => {
		callback(instance.sitemap);
	});

	// Also trigger events for things that depend on the sitemap
	// raisePathnameChanged();
	// raiseContextualNavigationChanged();
}

// Invoke Path changed callbacks
function raisePathnameChanged() {
	instance.pathnameChangedHandlers.forEach((callback) => {
		callback(instance.pathname);
	});
}

// Invoke Path changed callbacks
function raiseContextualNavigationChanged() {
	instance.contextualNavigationChangedHandlers.forEach((callback) => {
		callback(instance.contextualNavigation);
	});
}
