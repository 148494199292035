import React from 'react';
import AssetLoader from '../../../helpers/AssetLoader';

function ContentLink(props) {
	return <div>
		Click to download file: <a href={AssetLoader.contentHost + props.url} download target="_blank" rel="noopener noreferrer">{props.url}</a>
	</div>;
}

export default ContentLink;
