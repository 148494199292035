import { atom } from 'recoil';
import AssetLoader from '../AssetLoader';
import { getRecoil, setRecoil } from 'recoil-nexus';
import { SearchTools } from '../../components/search/SearchTools';

import {
	QueryResponse,
	FilterMap,
	SearchResult,
	SearchSuggestion,
	Suggestion,
	SuggestionValue,
	TextWithHighlightsValue,
	SuggestionType,
	FilterType,
	Origin,
	HelpContentType,
	QuerySuggestions,
	QueryResults,
	ResultItem,
	DocumentTitle,
	DocumentAttribute,
	DocumentAttributeValue,
	Highlight,
	DocumentExcerpt,
	SearchCache,
	SearchFilterConfig,
	SearchData,
	PartialSearchData,
	SearchRequest,
	AttributeFilterGroup,
	AttributeFilter,
	DocumentRelevanceOverrideConfiguration,
	RecentSearch
} from '../../components/search/SearchTypes';

import { addToast, ToastType } from '../../helpers/atoms/ToastAtom';

const profanityFilter = require('leo-profanity');
const searchTools = new SearchTools();

/*
 * TITLE_WEIGHT and ORIGIN_WEIGHT control the relative importance of the title and origin fields in search results.
 * They are values on a 1-10 scale with 1 being the lowest importance and 10 being the highest.
 */
const TITLE_WEIGHT = 7;
const ORIGIN_WEIGHT = 9;

// The number of times a search request should be retried on timeout
const TIMEOUT_RETRIES = 3;
// timeout duration for a search request
const SEARCH_TIMEOUT = 10000;
// the minimum number of results required for a search to be included in the recent searches atom
const MIN_RECENT_SEARCH_RESULT_COUNT = 5;

if (process.env.REACT_APP_SEARCH_HOST) {
	AssetLoader.setContentHost(process.env.REACT_APP_SEARCH_HOST);
}

export const searchCacheAtom = atom({
	key: 'searches',
	default: [] as SearchCache[],
});

export const searchFilterConfigAtom = atom({
	key: 'searchFilterConfig',
	default: {
		sourceFilters: {
			'resource-center': false,
			'developer-center': true
		},
		homeContentFilters: {
			'blog': true,
			'blueprint': true,
			'guide': true,
			'techref': true,
			'sdkdoc': false,
			'other': true
		},
		homeTopicFilters: {
			'analyticsdatamanagement': true,
			'authorization': true,
			'billing': true,
			'commdigital': true,
			'gdprprivacy': true,
			'notificationsalerts': true,
			'organization': true,
			'platform': true,
			'routing': true,
			'telephony': true,
			'useragentman': true
		},
		helpContentFilters: {
			'article': true,
			'faq': true
		},
		topicFiltersEnabled: false,
		changeInitiator: undefined
	} as SearchFilterConfig
});

export const recentSearchesAtom = atom({
	key: 'recentSearches',
	default: [] as RecentSearch[],
});

export const searchDataAtom = atom({
	key: 'searchData',
	default: undefined as SearchData[] | undefined
});

// addSearchCache adds a new search to the cache
export function addSearchCache(search: SearchCache) {
	const searches = getRecoil(searchCacheAtom);
	setRecoil(searchCacheAtom, [search, ...searches]);
}

// removeSearchCache removes the specified element from the list
export function removeSearchCache(search: SearchCache) {
	// Find search
	let searches = getRecoil(searchCacheAtom);
	const idx = searches.indexOf(search);
	if (idx < 0) return;

	// Remove search
	const newSearches = [...searches];
	newSearches.splice(idx, 1);
	setRecoil(searchCacheAtom, newSearches);
}

// updateSearchCache removes the specified element from the list
export function updateSearchCache(search: SearchCache, updatedSearch: SearchCache) {
	// Find search
	let searches = getRecoil(searchCacheAtom);
	const idx = searches.indexOf(search);
	if (idx < 0) return;

	// Update search
	const newSearches = [...searches];
	newSearches.splice(idx, 1, updatedSearch);
	setRecoil(searchCacheAtom, newSearches);
}

// addSearchData adds search data to the list
export function addSearchData(newSearchData: SearchData) {
	const searchData = getRecoil(searchDataAtom);
	setRecoil(searchDataAtom, [newSearchData, ...(searchData || [])]);
}

export function updateSearchData(partialData: PartialSearchData) {
	let dataArray = getRecoil(searchDataAtom);
	const idx = (dataArray ||[]).findIndex((data: SearchData) => data.id === partialData.id);
	if (!dataArray || idx < 0) {
		// add search data
		const newData: SearchData = {
			id: partialData.id,
			results: partialData.results,
			searchSuggestions: partialData.searchSuggestions,
			autocompleteText: partialData.autocompleteText || '',
			isLoading: partialData.isLoading || false
		}; 
		addSearchData(newData);
	} else {
		// Update search data
		let updatedData: SearchData = Object.assign({}, dataArray[idx]);
		const partialKeys: string[] = Object.keys(partialData);
		if (partialKeys.includes('results')) updatedData.results = partialData.results;
		if (partialKeys.includes('searchSuggestions')) updatedData.searchSuggestions = partialData.searchSuggestions;
		if (partialKeys.includes('autocompleteText')) updatedData.autocompleteText = partialData.autocompleteText;
		if (partialKeys.includes('isLoading')) updatedData.isLoading = partialData.isLoading;
		const updatedDataArray = [...dataArray];
		updatedDataArray.splice(idx, 1, updatedData);
		setRecoil(searchDataAtom, updatedDataArray);
	}
}

// addRecentSearch adds a new search to recent searches
export function addRecentSearch(search: RecentSearch) {
	const searches = getRecoil(recentSearchesAtom);
	setRecoil(recentSearchesAtom, [search, ...searches]);
}

// removeRecentSearch removes the specified element from the list
export function removeRecentSearch(search: RecentSearch) {
	// Find search
	let searches = getRecoil(recentSearchesAtom);
	const idx = searches.indexOf(search);
	if (idx < 0) return;
	// Remove search
	const newSearches = [...searches];
	newSearches.splice(idx, 1);
	setRecoil(recentSearchesAtom, newSearches);
}

// upsertRecentSearch updates the specified element in the list if it exists, otherwise it adds the record
export function upsertRecentSearch(newSearch: RecentSearch) {
	// Find search
	let searches = getRecoil(recentSearchesAtom);
	let currentSearch: RecentSearch | undefined = searches.find((s: RecentSearch) => s.searchTerm === newSearch.searchTerm);
	if (currentSearch) {
		const idx = searches.indexOf(currentSearch);
		if (idx < 0) return;
		// Update search
		const newSearches = [...searches];
		newSearches.splice(idx, 1, newSearch);
		setRecoil(recentSearchesAtom, newSearches);
	} else {
		setRecoil(recentSearchesAtom, [newSearch, ...searches]);
	}
}

// sortRecentSearches sorts recent searches by a combination of last searched time and search count
export function sortRecentSearches() {
	let searches = getRecoil(recentSearchesAtom);
	const timestampSorted: RecentSearch[] = [...searches].sort((a, b) => {
		if (a.lastSearchedDate > b.lastSearchedDate) {
			return 1;
		}
		if (a.lastSearchedDate < b.lastSearchedDate) {
			return -1;
		}
		return 0;
	});
	const searchCountSorted: RecentSearch[] = [...searches].sort((a, b) => {
		if (a.searchCount > b.searchCount) {
			return 1;
		}
		if (a.searchCount < b.searchCount) {
			return -1;
		}
		return 0;
	});
	const scores: { [searchTerm: string]: number } = {};
	/* 
	 * The score for each search is equal to its ordinal value based on timestamp plus half of its ordinal value based on search count.
	 * The higher the score, the closer to the top it should be displayed in the recent searches list.
	 * This algorithm prioritizes the most recent searches, but also considers the frequency of searches in the ordering.
	 */
	timestampSorted.forEach((search, i) => scores[search.searchTerm] = i);
	searchCountSorted.forEach((search, i) => scores[search.searchTerm] = scores[search.searchTerm] + Math.floor(i / 2));
	const sortedSearches: RecentSearch[] = [...searches].sort((a, b) => {
		const aScore: number = scores[a.searchTerm];
		const bScore: number = scores[b.searchTerm];
		if (aScore > bScore) {
			return -1;
		}
		if (aScore < bScore) {
			return 1;
		}
		return 0;
	});
	setRecoil(recentSearchesAtom, sortedSearches);
}

// execute search
export async function executeSearch(
	id: string,
	searchTerm: string,
	searchText: string,
	sourceFilters: FilterMap,
	helpContentFilters: FilterMap,
	homeContentFilters: FilterMap,
	homeTopicFilters: FilterMap,
	topicFiltersEnabled: boolean,
	visitorId: string | undefined,
	timeoutRetries = TIMEOUT_RETRIES
) {
	if (!searchTools.isValidSearchTerm(searchTerm)) {
		const newData: PartialSearchData = {
			id,
			results: undefined,
			searchSuggestions: undefined,
			autocompleteText: '',
			isLoading: false
		}
		updateSearchData(newData);
		return;
	}

	let queryResponse: QueryResponse | undefined;
	let cachedSearch: SearchCache | undefined;
	let searchTimestamp: number | undefined;

	const searchCache = getRecoil(searchCacheAtom);

	// check for cached results for search term
	if (searchCache.length) {
		cachedSearch = searchCache.find((cached: SearchCache) => {
			return cached.searchTerm === searchTerm && cached.sourceFilters === sourceFilters
				&& cached.helpContentFilters === helpContentFilters && cached.homeContentFilters === homeContentFilters
				&& cached.homeTopicFilters === homeTopicFilters && cached.topicFiltersEnabled === topicFiltersEnabled;
		});
	}

	// pull results cache if available, else execute query
	if (cachedSearch) {
		queryResponse = cachedSearch.queryResponse;
	} else {
		// append asterisk to match partial word searches
		const searchRequestData: SearchRequest = {
			queryText: searchTerm,
			includeSpellCheckSuggestions: true
		}

		const attributeFilterGroups: AttributeFilterGroup[] = buildFilterGroups(sourceFilters, homeContentFilters, homeTopicFilters, helpContentFilters, topicFiltersEnabled);
		if (attributeFilterGroups.length) {
			searchRequestData.attributeFilterGroups = attributeFilterGroups;
		}

		const documentRelevanceOverrideConfigurations: DocumentRelevanceOverrideConfiguration[] = buildRelevanceConfigs(sourceFilters);
		if (documentRelevanceOverrideConfigurations.length) {
			searchRequestData.documentRelevanceOverrideConfigurations = documentRelevanceOverrideConfigurations;
		}

		if (visitorId) searchRequestData.visitorId = visitorId;

		try {
			searchTimestamp = Date.now();
			const data: QueryResponse = await AssetLoader.post('/search', searchRequestData, 'application/json', undefined, SEARCH_TIMEOUT);
			queryResponse = data;
			// cache search result
			addSearchCache({searchTerm, sourceFilters, homeContentFilters, homeTopicFilters, helpContentFilters, topicFiltersEnabled, queryResponse, timestamp: searchTimestamp});
		} catch (err: any) {
			/*
			 * Retry after timeouts.
			 * According to AWS docs lambda timeouts return a 504 code.
			 * However in testing, the API gateway consistently returned a 502 when the lambda timed out.
			 */
			const isTimeout: boolean = err.code === 'ECONNABORTED' || err.response?.status === 504 || err.response?.status === 502;
			if (timeoutRetries > 0 && isTimeout) {
				console.error(err);
				timeoutRetries--;
				executeSearch(id, searchTerm, searchText, sourceFilters, helpContentFilters, homeContentFilters, homeTopicFilters, topicFiltersEnabled, visitorId, timeoutRetries);
				return;
			}
			addToast({
				title: 'Failed to execute search',
				message: err.message || 'unknown error',
				toastType: ToastType.Critical
			})
			console.error(err);
			//produce no results on error

			const newData: PartialSearchData = { id, results: undefined, isLoading: false }
			updateSearchData(newData);
		} // eslint-disable-next-line react-hooks/exhaustive-deps

	}

	if (queryResponse) {
		const { 
			QuerySuggestions: querySuggestions = {} as QuerySuggestions,
			QueryResults: queryResults = {} as QueryResults
		} = queryResponse;

		const { ResultItems: resultItems} = queryResults;
		const { Suggestions: suggestions } = querySuggestions;
		const { SpellCorrectedQueries: spellCorrectedQueries } = queryResults;

		let mappedResults: SearchResult[] = [];

		/* 
		 * Process search results for display.
		 * If no results are present and this is a wildcard search, try the search again without the wildcard
		 */
		if (resultItems && resultItems.length) {
			if (resultItems.length >= MIN_RECENT_SEARCH_RESULT_COUNT) {
				let searchCount: number;
				let lastSearchedDate: number;

				const prevSearches: SearchCache[] = searchCache.filter((search: SearchCache) => search.searchTerm === searchTerm);
				// sort by most recent
				prevSearches.sort((a, b) => {
					if (a.timestamp > b.timestamp) {
						return -1;
					}
					if (a.timestamp < b.timestamp) {
						return 1;
					}
					return 0;
				});
				
				if (searchTimestamp) {
					searchCount = prevSearches.length + 1;
					lastSearchedDate = searchTimestamp;
				} else {
					searchCount = prevSearches.length;
					lastSearchedDate = prevSearches[0].timestamp
				}

				const recentSearch: RecentSearch = {
					searchTerm,
					searchCount,
					lastSearchedDate
				};
				upsertRecentSearch(recentSearch);
				sortRecentSearches();
			}
			mappedResults = resultItems.map((result: ResultItem) => {
				const {
					DocumentTitle: documentTitle = {} as DocumentTitle,
					DocumentAttributes: documentAttributes = [] as DocumentAttribute[],
					DocumentExcerpt: documentExcerpt = {} as DocumentExcerpt,
				} = result;

				const { Text: title = ''} = documentTitle;
				const {
					Text: excerptText = '',
					Highlights: highlights = []
				} = documentExcerpt;

				const linkAttribute: DocumentAttribute | undefined = documentAttributes.find((attr: DocumentAttribute) => {
					const { Key: key = '' } = attr;
					return key === 'link';
				});
				const originAttribute: DocumentAttribute | undefined = documentAttributes.find((attr: DocumentAttribute) => {
					const { Key: key = '' } = attr;
					return key === 'origin';
				});
				const documentTypeAttribute: DocumentAttribute | undefined = documentAttributes.find((attr: DocumentAttribute) => {
					const { Key: key = '' } = attr;
					return key === 'documentType';
				});

				const { Value: originValue = {} as DocumentAttributeValue } = originAttribute || {};
				const { StringValue: originString = '' } = originValue;
				let origin: Origin | undefined;

				if (originString === Origin.DEVELOPER_CENTER) {
					origin = Origin.DEVELOPER_CENTER;
				}
				if (originString === Origin.RESOURCE_CENTER) {
					origin = Origin.RESOURCE_CENTER;
				}

				return {
					id: result.Id,
					title,
					url: linkAttribute?.Value?.StringValue ? `/${linkAttribute.Value.StringValue}` : '',
					origin,
					documentType: documentTypeAttribute?.Value?.StringValue || '',
					excerptText,
					excerptHighlights: highlights.map((highlight: Highlight) => [highlight.BeginOffset, highlight.EndOffset]),
					indexId: result.IndexId,
					queryId: result.QueryId,
					relevanceFeedbackSubmitted: result.RelevanceFeedbackSubmitted
				};
			})
			.filter((mappedResult: SearchResult) => mappedResult.id && mappedResult.title && mappedResult.url
				&& mappedResult.origin && mappedResult.indexId && mappedResult.queryId);
		}

		let unmodifiedSpellCorrectedQuery: string = '';
		let spellCorrectedQuery: string = '';

		if (spellCorrectedQueries && spellCorrectedQueries.length) {
			unmodifiedSpellCorrectedQuery = spellCorrectedQueries
				.filter((spellCorrectedQuery) => {
					return spellCorrectedQuery?.SuggestedQueryText === profanityFilter.clean(spellCorrectedQuery?.SuggestedQueryText)
				}) 
				.map((spellCorrectedQuery) => spellCorrectedQuery.SuggestedQueryText)?.[0] || '';
			
			// strip asterisk from end of spell correct suggestions if present
			spellCorrectedQuery = unmodifiedSpellCorrectedQuery.endsWith('*') 
				? unmodifiedSpellCorrectedQuery.substring(0, unmodifiedSpellCorrectedQuery.length - 1)
				: unmodifiedSpellCorrectedQuery;
		}
		
		// process search suggestions for display
		let mappedSuggestions: SearchSuggestion[] = [];
		if (suggestions && suggestions.length) {
			mappedSuggestions = suggestions
				.map((suggestion: Suggestion) => {
					const { Value: value = {} as SuggestionValue } = suggestion;
					const { Text: text = {} as TextWithHighlightsValue } = value;
					const { 
						Text: innerText = '',
						Highlights: highlights = [] 
					} = text;

					return {
						// strip asterisk from end of suggestion if present
						text: innerText.endsWith('*') ? innerText.substring(0, innerText.length - 1) : innerText,
						highlights: highlights.map((highlight: Highlight) => [highlight.BeginOffset, highlight.EndOffset]),
						type: SuggestionType.SUGGESTION
					};
				})
				.filter((suggestion: SearchSuggestion) => suggestion.text);						
		}

		// if search cache cantains relevant searches, add the top two searches to the suggestion list
		if (searchCache.length) {
			// a relevant cached search has a term that starts with the current search term and returned search results
			const relevantSearches: SearchCache[] = searchCache.filter((cached: SearchCache) => {
				return cached.searchTerm.toLowerCase().startsWith(searchTerm.toLowerCase())
					&& cached.searchTerm.toLowerCase() !== searchTerm.toLowerCase()
					&& cached.queryResponse?.QueryResults?.ResultItems?.length
					&& !cached.isSuggestionRemoved 
			});
			if (relevantSearches.length) {
				const mappedCacheSearches: SearchSuggestion[] = relevantSearches
					.slice(0, 2)
					.map((relevantSearch) => {
						return {
							text: profanityFilter.clean(relevantSearch.searchTerm),
							highlights: [[searchTerm.length, relevantSearch.searchTerm.length]],
							type: SuggestionType.HISTORY
						}
					});
				
					mappedSuggestions.unshift(...mappedCacheSearches);
			}
		}

		// if a spell correction is available, add it to the top of the suggestion list
		if (spellCorrectedQuery) {
			mappedSuggestions.unshift({ 
				text: spellCorrectedQuery,
				type: SuggestionType.SPELL_CHECK
			});
		}

		mappedResults.splice(12);

		let newAutocompleteText: string = '';

		// set new autocomplete text is suggestions are present
		if (mappedSuggestions.length) {
			const firstMatchingSuggestion: SearchSuggestion | undefined = mappedSuggestions
				.filter((s: SearchSuggestion) => s.text.toLowerCase().startsWith(searchTerm.toLowerCase()) && s.type !== SuggestionType.HISTORY)
				.shift();
			
			const suggestionText: string = firstMatchingSuggestion?.text || '';

			if (suggestionText !== searchTerm && suggestionText !== searchText) {
				newAutocompleteText = suggestionText;
			}
		}

		const newData: PartialSearchData = {
			id,
			results: mappedResults,
			searchSuggestions: mappedSuggestions,
			autocompleteText: newAutocompleteText,
			isLoading: false
		};
		updateSearchData(newData);
	} else {
		const newData: PartialSearchData = { id, results: undefined, isLoading: false }
		updateSearchData(newData);
	}
}

// configures the relevance tuning for the request based on the user-defined search filters
function buildRelevanceConfigs(sourceFilters: FilterMap): DocumentRelevanceOverrideConfiguration[] {
	const relevanceConfigs: DocumentRelevanceOverrideConfiguration[] = [];

	const titleRelevanceBoost: DocumentRelevanceOverrideConfiguration = {
		name: "_document_title",
		relevance: {
			importance: TITLE_WEIGHT
		}
	}
	relevanceConfigs.push(titleRelevanceBoost);

	const areAllSourceFiltersSelected: boolean = Object.entries(sourceFilters).every(([_, filterVal]) => filterVal);
	if (areAllSourceFiltersSelected) {
		const sourceRelevanceBoost: DocumentRelevanceOverrideConfiguration = {
			name: "origin",
			relevance: {
				valueImportanceMap: {
					"developer-center": ORIGIN_WEIGHT,
				}
			}
		}
		relevanceConfigs.push(sourceRelevanceBoost);
	}

	return relevanceConfigs;
}

// returns true if query execution should be aborted and search results set empty
function buildFilterGroups(sourceFilters: FilterMap, homeContentFilters: FilterMap, homeTopicFilters: FilterMap, helpContentFilters: FilterMap, topicFiltersEnabled: boolean): AttributeFilterGroup[] {
	const attributeFilterGroups: AttributeFilterGroup[] = [];
	let mappedSourceFilters: AttributeFilter[] = [];
	let mappedContentFilters: (AttributeFilter|AttributeFilterGroup)[] = [];
	let mappedTopicFilters: AttributeFilter[] = [];

	const areAllSourceFiltersDeselected: boolean = Object.entries(sourceFilters).every(([_, filterVal]) => !filterVal);
	const areAllSourceFiltersSelected: boolean = Object.entries(sourceFilters).every(([_, filterVal]) => filterVal);
	const areAllHomeFiltersDeselected: boolean = Object.entries(homeContentFilters).every(([_, filterVal]) => !filterVal);
	const areAllHelpFiltersDeselected: boolean = Object.entries(helpContentFilters).every(([_, filterVal]) => !filterVal);
	const isOnlySource: boolean = Object.entries(sourceFilters).filter(([_, filterVal]) => filterVal)?.length === 1 || false;

	// no content sources are selected
	if (areAllSourceFiltersDeselected) {
		for (const [origin, _] of Object.entries(sourceFilters)) { // eslint-disable-line @typescript-eslint/no-unused-vars
			mappedSourceFilters.push({
				key: 'origin',
				value: origin,
			});
		}

		// filter out all origin types
		attributeFilterGroups.push({
			type: FilterType.NOT,
			attributeFilters: mappedSourceFilters
		});
	
		return attributeFilterGroups;
	}
	
	// all content sources are selected, and no content types are selected
	else if (areAllSourceFiltersSelected && areAllHomeFiltersDeselected && areAllHelpFiltersDeselected) {
		for (const [origin, _] of Object.entries(sourceFilters)) { // eslint-disable-line @typescript-eslint/no-unused-vars
			mappedSourceFilters.push({
				key: 'origin',
				value: origin,
			});
		}

		// filter out all origin types
		attributeFilterGroups.push({
			type: FilterType.OR,
			attributeFilters: mappedSourceFilters
		});

		// resource center filters
		mappedContentFilters.push({
			key: 'documentType',
			value: 'article',
		});
		mappedContentFilters.push({
			key: 'documentType',
			value: 'page',
		});
		mappedContentFilters.push({
			key: 'documentType',
			value: 'faq'
		});

		for (const [contentType, _] of Object.entries(homeContentFilters)) { // eslint-disable-line @typescript-eslint/no-unused-vars
			mappedContentFilters.push({
				key: 'documentType',
				value: contentType,
			});
		}

		attributeFilterGroups.push({
			type: FilterType.NOT,
			attributeFilters: mappedContentFilters
		});	

		// topic filters can be ignored since all filters are deselected and no documents will come back in any search
		return attributeFilterGroups;
	}

	// if home is only selected content source, and no home content filters are selected
	else if (sourceFilters[Origin.DEVELOPER_CENTER] && isOnlySource && areAllHomeFiltersDeselected) {
		mappedSourceFilters.push({
			key: 'origin',
			value: Origin.DEVELOPER_CENTER,
		});

		attributeFilterGroups.push({
			type: FilterType.OR,
			attributeFilters: mappedSourceFilters
		});

		for (const [contentType, _] of Object.entries(homeContentFilters)) { // eslint-disable-line @typescript-eslint/no-unused-vars
			mappedContentFilters.push({
				key: 'documentType',
				value: contentType,
			});
		}

		attributeFilterGroups.push({
			type: FilterType.NOT,
			attributeFilters: mappedContentFilters
		});	

		// topic filters can be ignored since all Developer Center filters are deselected and no documents will come back in any search
		return attributeFilterGroups;
	}

	// if help is only selected content source, and no help content filters are selected
	else if (sourceFilters[Origin.RESOURCE_CENTER] && isOnlySource && areAllHelpFiltersDeselected) {
		mappedSourceFilters.push({
			key: 'origin',
			value: Origin.RESOURCE_CENTER,
		});

		attributeFilterGroups.push({
			type: FilterType.OR,
			attributeFilters: mappedSourceFilters
		});

		// resource center filters
		mappedContentFilters.push({
			key: 'documentType',
			value: 'article',
		});
		mappedContentFilters.push({
			key: 'documentType',
			value: 'page',
		});
		mappedContentFilters.push({
			key: 'documentType',
			value: 'faq',
		});

		attributeFilterGroups.push({
			type: FilterType.NOT,
			attributeFilters: mappedContentFilters
		});	
		
		// topic filters can be ignored since the Developer Center source is deselected
		return attributeFilterGroups;
	}
	
	else {
		for (const [origin, isSelected] of Object.entries(sourceFilters)) {
			if (isSelected) {
				mappedSourceFilters.push({
					key: 'origin',
					value: origin,
				});
			}
		}

		attributeFilterGroups.push({
			type: FilterType.OR,
			attributeFilters: mappedSourceFilters
		});
		
		// only add content type filters for dev center if the dev center source filter is selected
		if (sourceFilters[Origin.DEVELOPER_CENTER]) {
			for (const [contentType, isSelected] of Object.entries(homeContentFilters)) {
				if (isSelected) {
					mappedContentFilters.push({
						key: 'documentType',
						value: contentType,
					});
				}
			}
			
			if (topicFiltersEnabled) {
				const areAllTopicFiltersDisabled: boolean = Object.entries(homeTopicFilters).every(([_, filterVal]) => !filterVal);
				if (areAllTopicFiltersDisabled) {
					for (const topic of Object.keys(homeTopicFilters)) {
						mappedTopicFilters.push({
							key: 'topic',
							value: topic,
						});
					}
					attributeFilterGroups.push({
						type: FilterType.NOT,
						attributeFilters: mappedTopicFilters
					});
				} else {
					for (const [topic, isSelected] of Object.entries(homeTopicFilters)) {
						if (isSelected) {
							mappedTopicFilters.push({
								key: 'topic',
								value: topic,
							});
						}
					}
					attributeFilterGroups.push({
						type: FilterType.OR,
						attributeFilters: mappedTopicFilters
					});
				}
			}
		}

		// only add content type filters for resource center if the resource center source filter is selected
		if (sourceFilters[Origin.RESOURCE_CENTER]) {
			for (const [contentType, isSelected] of Object.entries(helpContentFilters)) {
				if (isSelected) {
					if (contentType === HelpContentType.ARTICLE) {
						mappedContentFilters.push({
							key: 'documentType',
							value: 'article'
						});
						mappedContentFilters.push({
							key: 'documentType',
							value: 'page'
						});
					} 
					else if (contentType === HelpContentType.FAQ) {
						mappedContentFilters.push({
							key: 'documentType',
							value: 'faq'
						});
					}
				}
			}
		}

		if (mappedContentFilters.length) {
			attributeFilterGroups.push({
				type: FilterType.OR,
				attributeFilters: mappedContentFilters
			});	
		}

		return attributeFilterGroups;
	}
}