import { atom, atomFamily } from 'recoil';
import { getRecoil, setRecoil } from 'recoil-nexus';
import moment from 'moment';

import { selectedAccountAtom } from './AccountsAtom';
import { addToast, ToastType } from './ToastAtom';
import { AddNotificationChannel, removeItem } from './ToolboxAtom';
import { TopicEvent, Channel } from '../../components/tools/notifications/notificationtopics/NotificationDefinitions';
import AppSettings from '../settings/AppSettings';
import { ToolboxApp, ToolboxItem } from '../../types';

//Atoms

const cachedChannelsAtom = atom({ key: 'cachedChannels', default: [] as string[] });

export const eventsAtom = atomFamily({ key: 'events', default: [] as TopicEvent[] });

//updates channelContent component when a new topic gets added
export const updateSubscriptions = atom({ key: 'updateSubscriptions', default: true });

//updates TopicContent when a channel gets created
export const channelsUpdated = atom({ key: 'updateChannels', default: true });

export async function addChannel() {
	const selectedAccount = getRecoil(selectedAccountAtom);
	if (selectedAccount) {
		selectedAccount.api
			.request({
				method: 'post',
				url: `/api/v2/notifications/channels`,
			})
			.then((res) => {
				let channelobj: Channel = JSON.parse(JSON.stringify(res.data));
				const channelsState = getRecoil(channelsUpdated);
				openSocket(channelobj);
				addChanneltoToolbox(channelobj);
				setRecoil(channelsUpdated, !channelsState);
				addToast({
					title: 'Success: new channel created',
					message: `Channel ${channelobj.id} was successfully established. Open the toolbox in the bottom right corner of your screen to manage notifications.`,
					toastType: ToastType.Success,
					timeoutSeconds: 30,
				});
			})
			.catch((err) => {
				addToast({ title: 'Failed to add channel', message: err.message, toastType: ToastType.Critical });
			});
	} else {
		addToast({ title: 'Error: no active account', message: 'Please add or select an account', toastType: ToastType.Critical });
	}
}

function addChanneltoToolbox(channel: Channel) {
	AddNotificationChannel({ title: `${channel.id}`, props: channel });
}

export async function subscribe(channelId: string, topic: any) {
	const selectedAccount = getRecoil(selectedAccountAtom);
	if (selectedAccount) {
		selectedAccount.api
			.request({
				method: 'post',
				url: `/api/v2/notifications/channels/${channelId}/subscriptions`,
				data: [{ id: `${topic}` }],
			})
			.then(() => {
				const subscribed = getRecoil(updateSubscriptions);
				setRecoil(updateSubscriptions, !subscribed);
				addToast({
					title: 'Successfully subscribed to topic',
					message: `Subscribed to ${topic}. Open the toolbox in the bottom right corner of your screen to manage notifications.`,
					toastType: ToastType.Success,
					timeoutSeconds: 30,
				});
			})
			.catch((error) => {
				addToast({ title: 'Error: failed to subscribe to topic', message: error.message, toastType: ToastType.Critical });
			});
	}
}

export function openSocket(channel: Channel) {
	const cachedChannels = getRecoil(cachedChannelsAtom);

	if (!channel.id || !channel.connectUri || cachedChannels.includes(channel.id)) {
		return;
	}

	setRecoil(cachedChannelsAtom, [...cachedChannels, channel.id]);

	try {
		let ws = new WebSocket(channel.connectUri);
		ws.onopen = function () {
			console.log('ws opened');
		};
		ws.onmessage = function (event: any) {
			let eventData = JSON.parse(event.data);
			//remove heartbeat messages
			if (eventData['topicName'] === 'channel.metadata') {
				return;
			}
			let eventObj: TopicEvent = {
				eventData: eventData,
				time: moment(),
				connectUrl: event.currentTarget.url,
				isPinned: false,
			};

			let events = getRecoil(eventsAtom(eventObj.connectUrl));
			setRecoil(eventsAtom(eventObj.connectUrl), [...events, eventObj]);
		};
		ws.onclose = function () {
			removeItem({ title: `${channel.id}`, appType: ToolboxApp.Notifications, props: channel });
			console.log('websocket closed');
		};
	} catch (error) {
		console.log(error);
	}
}

export function updateChannelItems() {
	const selectedAccount = getRecoil(selectedAccountAtom);
	if (selectedAccount) {
		let channelItems: ToolboxItem[] = [];
		let account = selectedAccount;
		account.api
			.request({
				method: 'GET',
				url: `/api/v2/notifications/channels`,
			})
			.then((res) => {
				channelItems = res.data.entities.map((channel: Channel) => {
					openSocket(channel);
					return { title: `${channel.id}`, appType: ToolboxApp.Notifications, props: channel, disableUserRemove: true };
				});
				updateToolboxAtomItems(channelItems);
			})
			.catch((error) => {
				if (error.response.status === 429) {
					const retryAfter: number = error.response.headers?.['retry-after'] || 10;
					new Promise((resolve) => setTimeout(resolve, retryAfter + 1000)).then(() => {
						return updateChannelItems();
					});
				}
				addToast({ title: 'Failed to update channels', message: error.message, toastType: ToastType.Critical });
			});
	} else {
		updateToolboxAtomItems();
	}
}

function updateToolboxAtomItems(channelItems?: ToolboxItem[]) {
	const savedChannelItems = { ...getRecoil(AppSettings.toolboxItemsAtom()) };
	if (channelItems) {
		savedChannelItems[ToolboxApp.Notifications] = [...channelItems];
	} else {
		savedChannelItems[ToolboxApp.Notifications] = [];
	}
	setRecoil(AppSettings.toolboxItemsAtom(), savedChannelItems);
}

export function pinEvent(event: TopicEvent) {
	let events = getRecoil(eventsAtom(event.connectUrl));
	const idx = events.indexOf(event);

	//Because I was getting a readonly error when editing the events array above
	let parsedEvents: TopicEvent[] = JSON.parse(JSON.stringify(getRecoil(eventsAtom(event.connectUrl))));

	if (event.isPinned === false) {
		if (idx >= 0) {
			parsedEvents[idx].isPinned = true;
			setRecoil(eventsAtom(event.connectUrl), [...parsedEvents]);
		} else {
			addToast({
				title: 'Error pinning event card:',
				message: 'An error occured while event card was being pinned',
				toastType: ToastType.Critical,
			});
		}
	} else {
		if (idx >= 0) {
			parsedEvents[idx].isPinned = false;
			setRecoil(eventsAtom(event.connectUrl), [...parsedEvents]);
		} else {
			addToast({
				title: 'Error unpinning event card',
				message: 'An error occured while event card was being unpinned',
				toastType: ToastType.Critical,
			});
		}
	}
}
