import React, { useEffect, useState } from 'react';
import { DxAccordion, DxButton, DxTextbox, DxAccordionGroup, DxItemGroup, DxItemGroupItem } from 'genesys-react-components';
import { GenesysDevIcons, GenesysDevIcon } from 'genesys-dev-icons';
import { useRecoilValue } from 'recoil';
import chance from 'chance';
import { v4 as uuidv4 } from 'uuid';

import { ChatData } from '../../../types';
import AlertBlock from '../../markdown/alertblock/AlertBlock';
import { SavedWebChatData } from '../../../types';
import AppSettings from '../../../helpers/settings/AppSettings';
import './ChatDataForm.scss';

interface CustomAttribute {
	name: string;
	value: string;
}

enum CustomAttributesType {
	NAME = 'name',
	VALUE = 'value',
}

interface IProps {
	onValueUpdated?: any;
}

function ChatDataForm(props: IProps) {
	const [customAttributes, setCustomAttributes] = useState<CustomAttribute[]>([]);
	const [id, setId] = useState<string>(uuidv4());
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [address, setAddress] = useState('');
	const [city, setCity] = useState('');
	const [state, setState] = useState('');
	const [zip, setZip] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [locale, setLocale] = useState('');
	const [customField1Label, setCustomField1Label] = useState('');
	const [customField1, setCustomField1] = useState('');
	const [customField2Label, setCustomField2Label] = useState('');
	const [customField2, setCustomField2] = useState('');
	const [customField3Label, setCustomField3Label] = useState('');
	const [customField3, setCustomField3] = useState('');
	const [welcomeMessage, setWelcomeMessage] = useState('');
	const [selectedSavedItem, setSelectedSavedItem] = useState('');
	const [savedDataDropdownItems, setSavedDataDropdownItems] = useState<DxItemGroupItem[]>([]);
	const savedData = useRecoilValue(AppSettings.webChatDataAtom());

	const ch = new chance();

	let chatData: ChatData = {
		id: id,
		firstName: firstName,
		lastName: lastName,
		addressStreet: address,
		addressCity: city,
		addressState: state,
		addressPostalCode: zip,
		phoneNumber: phone,
		email: email,
		locale: locale,
		customField1Label: customField1Label,
		customField1: customField1,
		customField2Label: customField2Label,
		customField2: customField2,
		customField3Label: customField3Label,
		customField3: customField3,
	};

	useEffect(() => {
		setFirstName('');
		setLastName('');
		setAddress('');
		setCity('');
		setState('');
		setZip('');
		setPhone('');
		setEmail('');
		setCustomField1('');
		setCustomField1Label('');
		setCustomField2('');
		setCustomField2Label('');
		setCustomField3('');
		setCustomField3Label('');
		setSelectedSavedItem('');
		setCustomAttributes([]);
		setSavedDataDropdownItems([]);

		if (savedData && savedData.length > 0) {
			const firstItem = savedData[0];

			const items: DxItemGroupItem[] = savedData.map((s: SavedWebChatData, i) => {
				if (i === 0) {
					return { label: s.name, value: s.id, isSelected: true };
				}
				return { label: s.name, value: s.id };
			});

			setSavedDataDropdownItems(items);

			setSelectedSavedItem(firstItem.id || '');

			setId(firstItem.data.id || '');
			setFirstName(firstItem.data.firstName || '');
			setLastName(firstItem.data.lastName || '');
			setAddress(firstItem.data.addressStreet || '');
			setCity(firstItem.data.addressCity || '');
			setState(firstItem.data.addressState || '');
			setZip(firstItem.data.addressPostalCode || '');
			setPhone(firstItem.data.phoneNumber || '');
			setEmail(firstItem.data.email || '');
			setCustomField1(firstItem.data.customField1 || '');
			setCustomField1Label(firstItem.data.customField1Label || '');
			setCustomField2(firstItem.data.customField2 || '');
			setCustomField2Label(firstItem.data.customField2Label || '');
			setCustomField3(firstItem.data.customField3 || '');
			setCustomField3Label(firstItem.data.customField3Label || '');
			setLocale(firstItem.data.locale || '');
			setWelcomeMessage(firstItem.data.welcomeMessage || '');

			if (firstItem.data['customAttr']) {
				setCustomAttributes(firstItem.data['customAttr']);
			}
		}
		props.onValueUpdated(chatData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [savedData]);

	function addCustomAttribute() {
		setCustomAttributes([...customAttributes, { name: '', value: '' }]);
	}

	function deleteCustomAttribute(index: number) {
		let filteredAttributes = customAttributes.filter((_, i) => {
			return i !== index;
		});

		chatData['customAttr'] = filteredAttributes;

		setCustomAttributes(filteredAttributes);

		props.onValueUpdated(chatData);
	}

	function updateAttributes(index: number, value: string, property: string) {
		let temp = [...customAttributes];

		//Was getting 'cannot assign to read only property' even after using the spread operator
		let attributes = temp.map((ca: CustomAttribute) => {
			let temp = { ...ca };
			return temp;
		});

		if (property === 'name') {
			attributes[index].name = value;
		} else if (property === 'value') {
			attributes[index].value = value;
		}

		chatData['customAttr'] = attributes;

		setCustomAttributes(attributes);

		props.onValueUpdated(chatData);
	}

	function valueUpdated(property: string, value: string) {
		switch (property) {
			case 'firstName':
				chatData.firstName = value;
				setFirstName(value);
				break;
			case 'lastName':
				chatData.lastName = value;
				setLastName(value);
				break;
			case 'address':
				chatData.addressStreet = value;
				setAddress(value);
				break;
			case 'city':
				chatData.addressCity = value;
				setCity(value);
				break;
			case 'state':
				chatData.addressState = value;
				setState(value);
				break;
			case 'zip':
				chatData.addressPostalCode = value;
				setZip(value);
				break;
			case 'phone':
				chatData.phoneNumber = value;
				setPhone(value);
				break;
			case 'email':
				chatData.email = value;
				setEmail(value);
				break;
			case 'locale':
				chatData.locale = value;
				setLocale(value);
				break;
			case 'customField1Label':
				chatData.customField1Label = value;
				setCustomField1Label(value);
				break;
			case 'customField1':
				chatData.customField1 = value;
				setCustomField1(value);
				break;
			case 'customField2Label':
				chatData.customField2Label = value;
				setCustomField2Label(value);
				break;
			case 'customField2':
				chatData.customField2 = value;
				setCustomField2(value);
				break;
			case 'customField3Label':
				chatData.customField3Label = value;
				setCustomField3Label(value);
				break;
			case 'customField3':
				chatData.customField3 = value;
				setCustomField3(value);
				break;
			default:
		}

		props.onValueUpdated(chatData);
	}

	function populate() {
		setFirstName(ch.first());
		setLastName(ch.last());
		setAddress(ch.address());
		setCity(ch.city());
		setState(ch.state());
		setZip(ch.zip());
		setPhone(ch.phone());
		setEmail(ch.email());
		setId(uuidv4());
		setLocale('en');
		setWelcomeMessage('Thanks for chatting using the dev tools chat page.');
	}

	function deleteSavedItem() {
		if (savedData && savedData.length > 0) {
			const filteredArr = savedData.filter((item: SavedWebChatData) => item.id !== selectedSavedItem);
			AppSettings.setWebChatData(filteredArr);
		}
	}

	function selectSavedItem(id: string) {
		setCustomAttributes([]);
		if (savedData) {
			const selectedItem = savedData.find((item: SavedWebChatData) => item.id === id);
			if (selectedItem) {
				setFirstName(selectedItem.data.firstName || '');
				setLastName(selectedItem.data.lastName || '');
				setAddress(selectedItem.data.addressStreet || '');
				setCity(selectedItem.data.addressCity || '');
				setState(selectedItem.data.addressState || '');
				setZip(selectedItem.data.addressPostalCode || '');
				setPhone(selectedItem.data.phoneNumber || '');
				setEmail(selectedItem.data.email || '');
				setId(selectedItem.data.id || '');
				setCustomField1(selectedItem.data.customField1 || '');
				setCustomField1Label(selectedItem.data.customField1Label || '');
				setCustomField2(selectedItem.data.customField2 || '');
				setCustomField2Label(selectedItem.data.customField2 || '');
				setCustomField3(selectedItem.data.customField3 || '');
				setCustomField3Label(selectedItem.data.customField3Label || '');
				if (selectedItem.data['customAttr']) {
					setCustomAttributes(selectedItem.data['customAttr']);
				}
			}
			setSelectedSavedItem(id);
		}
	}

	return (
		<DxAccordionGroup className="chatdataform">
			<DxAccordion title="Chat Data (Optional)" showOpen={true}>
				<AlertBlock
					title="Note: Starting a chat automatically saves the chat data present"
					alertType="info"
					children={
						'If a saved item is edited before iniatilizing a chat, the saved instance gets updated as well. Use the Populate Fields button if you are trying to save new chat data'
					}
					className="chatdataform-alert-block"
				/>
				<div className="saved-data-container">
					<div>
						<DxItemGroup
							items={savedDataDropdownItems}
							onItemChanged={(item: DxItemGroupItem) => selectSavedItem(item.value)}
							format="dropdown"
							title="Saved chat data"
							description="Most recent data gets selected automatically but you can select your preferred item"
						/>
					</div>
					<div className="delete-item-button">
						<DxButton onClick={deleteSavedItem}>Delete selected item</DxButton>
					</div>
				</div>

				<div className="inputFields-container">
					<div className="main-attributes-container">
						<DxTextbox
							label="First name"
							value={firstName}
							onChange={(value: string) => valueUpdated('firstName', value)}
							key={'firstName'}
						/>
						<DxTextbox label="Last name" value={lastName} onChange={(value: string) => valueUpdated('lastName', value)} key={'lastName'} />
						<DxTextbox label="Address" value={address} onChange={(value: string) => valueUpdated('address', value)} key={'address'} />
						<DxTextbox label="City" value={city} onChange={(value: string) => valueUpdated('city', value)} key={'city'} />
						<DxTextbox label="State" value={state} onChange={(value: string) => valueUpdated('state', value)} key={'state'} />
						<DxTextbox label="Zip" value={zip} onChange={(value: string) => valueUpdated('zip', value)} key={'zip'} />
						<DxTextbox label="Phone" value={phone} onChange={(value: string) => valueUpdated('phone', value)} key={'phone'} />
						<DxTextbox
							label="Email"
							inputType="email"
							value={email}
							onChange={(value: string) => valueUpdated('email', value)}
							key={'emai'}
						/>
						<DxTextbox
							label="Locale"
							inputType="text"
							value={locale}
							onChange={(value: string) => valueUpdated('locale', value)}
							key={'locale'}
						/>
						<DxTextbox
							label="Welcome message"
							inputType="text"
							initialValue="Thanks for chatting using the dev tools chat page"
							value={welcomeMessage}
							key={'welcomeMessage'}
						/>
						<DxButton onClick={populate}>Populate Fields</DxButton>
					</div>
					<div className="custom-data-container">
						<div className="custom-fields-row">
							<DxTextbox
								label="Field 1 name"
								placeholder="custom data field name"
								inputType="text"
								className="field-name"
								value={customField1Label}
								onChange={(value: string) => valueUpdated('customField1Label', value)}
								key={'field-1-name'}
							/>
							<DxTextbox
								label="Field 1 value"
								inputType="text"
								placeholder="custom data field value"
								value={customField1}
								onChange={(value: string) => valueUpdated('customField1', value)}
								key={'field-1-value'}
							/>
						</div>

						<div className="custom-fields-row">
							<DxTextbox
								label="Field 2 name"
								inputType="text"
								className="field-name"
								placeholder="custom data field name"
								value={customField2Label}
								onChange={(value: string) => valueUpdated('customField2Label', value)}
								key={'Field-2-name'}
							/>
							<DxTextbox
								label="Field 2 Value"
								placeholder="custom data field value"
								inputType="text"
								value={customField2}
								onChange={(value: string) => valueUpdated('customField2', value)}
								key={'field-2-value'}
							/>
						</div>

						<div className="custom-fields-row">
							<DxTextbox
								label="Field 3 Name"
								placeholder="custom data field name"
								inputType="text"
								className="field-name"
								value={customField3Label}
								onChange={(value: string) => valueUpdated('customField3Label', value)}
								key={'field-3-name'}
							/>
							<DxTextbox
								label="Field 3 Value"
								inputType="text"
								placeholder="custom data field value"
								value={customField3}
								onChange={(value: string) => valueUpdated('customField3', value)}
								key={'field-3-value'}
							/>
						</div>

						{customAttributes.map((attribute, i) => (
							<div className="custom-attributes" key={i}>
								<div className="attribute-fields">
									<DxTextbox
										label="Attribute Name"
										placeholder="Attribute Name"
										className="attribute-field"
										value={attribute.name}
										onChange={(value: string) => updateAttributes(i, value, CustomAttributesType.NAME)}
									/>
									<DxTextbox
										label="Attribute Value"
										placeholder="Attribute Value"
										className="attribute-field"
										value={attribute.value}
										onChange={(value: string) => updateAttributes(i, value, CustomAttributesType.VALUE)}
									/>
								</div>

								<div className="delete-attribute">
									<DxButton className="delete-button" onClick={() => deleteCustomAttribute(i)}>
										{<GenesysDevIcon icon={GenesysDevIcons.AppTimes} />}
									</DxButton>
								</div>
							</div>
						))}
						<DxButton type="primary" className="add-custom-attributes-button" onClick={addCustomAttribute}>
							<span>Add Custom Attributes</span>
							<GenesysDevIcon className="button-icon" icon={GenesysDevIcons.AppPlusStroke} />
						</DxButton>
					</div>
				</div>
			</DxAccordion>
		</DxAccordionGroup>
	);
}

export default ChatDataForm;
