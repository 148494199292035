import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';
import React, { ReactNode } from 'react';

import { Language, SdkInvocation, toSdkLanguageName } from '../../../../types';
import CodeFence from '../../../codefence/CodeFence';
import DxLink from '../../../dxlink/DxLink';

import './SdkInvocationDisplay.scss';

interface IProps {
	data: SdkInvocation;
	language: Language;
}

export default function SdkInvocationDisplay(props: IProps) {
	let language = 'json';
	let repoLink = 'https://github.com/MyPureCloud';
	let packageLink: ReactNode;
	let docLink = <DxLink href={`/devapps/sdk/docexplorer/${props.language}/`}>{toSdkLanguageName(props.language)} Documentation</DxLink>;

	switch (props.language) {
		case Language.Cli: {
			language = 'sh';
			repoLink = 'https://github.com/MyPureCloud/platform-client-sdk-cli';
			docLink = <DxLink href="/devapps/cli/">Platform API CLI</DxLink>;
			packageLink = <DxLink href={`/devapps/cli/`}>CLI Documentation</DxLink>;
			break;
		}
		case Language.Dotnet: {
			language = 'csharp';
			repoLink = 'https://github.com/MyPureCloud/platform-client-sdk-dotnet';
			packageLink = <DxLink href="https://www.nuget.org/packages/PureCloudPlatform.Client.V2/">Nuget</DxLink>;
			break;
		}
		case Language.Go: {
			language = 'go';
			repoLink = 'https://github.com/MyPureCloud/platform-client-sdk-go';
			docLink = <DxLink href="https://pkg.go.dev/github.com/MyPureCloud/platform-client-sdk-go">pkg.go.dev</DxLink>;
			break;
		}
		case Language.Ios: {
			language = 'swift';
			repoLink = 'https://github.com/MyPureCloud/platform-client-sdk-ios';
			break;
		}
		case Language.Java: {
			language = 'java';
			repoLink = 'https://github.com/MyPureCloud/platform-client-sdk-java';
			packageLink = <DxLink href="https://search.maven.org/artifact/com.mypurecloud/platform-client-v2">Maven Central</DxLink>;
			break;
		}
		case Language.WebMessagingJava: {
			language = 'java';
			repoLink = 'https://github.com/MyPureCloud/web-messaging-sdk-java';
			packageLink = <DxLink href="https://search.maven.org/artifact/cloud.genesys/web-messaging-sdk">Maven Central</DxLink>;
			break;
		}
		case 'purecloudjavaGuest' as Language:
		case Language.JavaGuest: {
			language = 'java';
			repoLink = 'https://github.com/MyPureCloud/purecloud-guest-chat-client-java';
			packageLink = <DxLink href="https://search.maven.org/artifact/com.mypurecloud/purecloud-guest-chat-client">Maven Central</DxLink>;
			docLink = <DxLink href={`/devapps/sdk/docexplorer/purecloudjava-guest/`}>{toSdkLanguageName(props.language)} Documentation</DxLink>;
			break;
		}
		case Language.Javascript: {
			language = 'js';
			repoLink = 'https://github.com/MyPureCloud/platform-client-sdk-javascript';
			packageLink = <DxLink href="https://www.npmjs.com/package/purecloud-platform-client-v2">NPM</DxLink>;
			break;
		}
		case Language.JavascriptGuest: {
			language = 'js';
			repoLink = 'https://github.com/MyPureCloud/purecloud-guest-chat-client-javascript';
			packageLink = <DxLink href="https://www.npmjs.com/package/purecloud-guest-chat-client">NPM</DxLink>;
			break;
		}
		case Language.Python: {
			language = 'python';
			repoLink = 'https://github.com/MyPureCloud/platform-client-sdk-python';
			packageLink = <DxLink href="https://pypi.org/project/PureCloudPlatformClientV2/">PyPI</DxLink>;
			break;
		}
	}

	return (
		<div className="sdk-invocation-display">
			<div className="sdk-info">
				<span>
					The documentation for the {toSdkLanguageName(props.language)} can be found at {docLink}.{' '}
					{packageLink && <React.Fragment>The package can be obtained from {packageLink}.</React.Fragment>}
				</span>
				<a href={repoLink} target="_blank" rel="noopener noreferrer" className="github-link">
					<GenesysDevIcon icon={GenesysDevIcons.BrandGithub} />
				</a>
			</div>
			<CodeFence className='sdk-example' value={props.data.example || `No example for ${props.language}`} language={language} />
		</div>
	);
}
