import { selectedAccountAtom } from '../../../helpers/atoms/AccountsAtom';
import { CancelTokenSource } from 'axios';
import { getRecoil } from 'recoil-nexus';
import { Models } from '../../../helpers/platformapi/PlatformAPITypes';

export async function createDeployment(cancelToken: CancelTokenSource | undefined, version: string) {
	return new Promise((resolve, reject) => {
		const selectedAccount = getRecoil(selectedAccountAtom);
		if (!selectedAccount) {
			reject('No active account');
		}

		let newDeployment;
		const requestUrl = '/api/v2/widgets/deployments';

		const body = {
			name: 'Developer Tools',
			description: 'Created by the Genesys Cloud Developer Tools',
			authenticationRequired: false,
			disabled: false,
			clientType: getClientType(version),
			clientConfig: {
				v1: {
					webChatSkin: 'modern-caret-skin',
				},
			},
		};

		selectedAccount?.api
			.request({ url: requestUrl, method: 'post', data: body, cancelToken: cancelToken?.token })
			.then((res) => {
				newDeployment = res.data;
				resolve(newDeployment);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export function loadDeployments(cancelToken: CancelTokenSource | undefined, version?: string) {
	return new Promise((resolve, reject) => {
		const selectedAccount = getRecoil(selectedAccountAtom);
		if (!selectedAccount) {
			reject('No active account');
		}

		if (version === 'messenger') {
			selectedAccount?.api
				.request({ url: '/api/v2/webdeployments/deployments', method: 'get', cancelToken: cancelToken?.token })
				.then((res) => {
					// Sort a-z
					res.data.entities.sort((a: Models.WebDeployment, b: Models.WebDeployment) => {
						if (a.name && b.name) {
							return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0;
						}
						return '';
					});

					let deploymentList: Models.WebDeployment[] = [];
					res.data.entities.forEach((deployment: Models.WebDeployment) => {
						if (deployment.status !== 'Active') {
							return;
						}

						deploymentList.push(deployment);
					});

					resolve(deploymentList);
				})
				.catch((err) => {
					reject(err);
				});
		} else {
			selectedAccount?.api
				.request({ url: '/api/v2/widgets/deployments', method: 'get', cancelToken: cancelToken?.token })
				.then((res) => {
					// Sort a-z
					res.data.entities.sort((a: Models.WidgetDeployment, b: Models.WidgetDeployment) => {
						if (a.name && b.name) {
							return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0;
						}
						return '';
					});

					let deploymentList: Models.WidgetDeployment[] = [];
					res.data.entities.forEach((deployment: Models.WidgetDeployment) => {
						if (deployment.disabled) return;

						if (version === 'one') {
							if (deployment.clientType?.includes('v2')) return;
						} else if (version === 'two') {
							if (deployment.clientType?.includes('v1')) return;
						}

						if (deployment.authenticationRequired === true) {
							deployment.name += ' (Requires Authentication, not supported with dev tools)';
						}

						deploymentList.push(deployment);
					});

					resolve(deploymentList);
				})
				.catch((err) => {
					reject(err);
				});
		}
	});
}

export async function loadQueues(cancelToken: CancelTokenSource | undefined) {
	const selectedAccount = getRecoil(selectedAccountAtom);

	if (!selectedAccount) {
		throw new Error('No selected account');
	}

	let queues: Models.Queue[] = [];

	try {
		let res = await selectedAccount?.api.request({
			url: '/api/v2/routing/queues',
			method: 'GET',
			params: { sortBy: 'name', pageSize: '100' },
			cancelToken: cancelToken?.token,
		});
		queues.push(...res?.data.entities);

		while (res?.data.nextUri) {
			res = await selectedAccount?.api.request({
				url: res?.data.nextUri,
				method: 'GET',
				cancelToken: cancelToken?.token,
			});
			queues.push(...res?.data.entities);
		}
	} catch (err: any) {
		throw err;
	}
	return queues;
}

const getClientType = (ver: string) => {
	if (ver === 'one') {
		return 'v1';
	}
	return 'v2';
};
