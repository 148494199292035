import React from 'react';

// Types
import { ModelSchema, OpenAPIDefinition, ValueUpdatedCallback, UpdateSource } from '../../../../../helpers/openapi/OpenAPITypes';
import { SchemaToPropertyInfo } from '../../../../../helpers/openapi/OpenAPITools';

// Wizard components
import ObjectContainer from './ObjectContainer';
import ArrayContainer from './ArrayContainer';
import MapContainer from './MapContainer';
import ModelProperty from './ModelProperty';

import './WizardEditor.scss';
import SwaggerCache from '../../../../../helpers/openapi/SwaggerCache';

interface IProps {
	schema: ModelSchema;
	propertyName?: string;
	onValueUpdated?: ValueUpdatedCallback;
	initialValue?: any;
	swagger: OpenAPIDefinition;
	updateSource: UpdateSource;
}

export default function WizardEditor(props: IProps) {
	// React.useEffect(() => console.log('property changed', props.initialValue), [props.initialValue]);

	if (!props.schema.type && props.schema.__isRecursive) {
		return (
			<div>
				{props.schema.__propertyName} ({props.schema.__modelName}) (recursive)
			</div>
		);
	}

	const updateValue = (propertyName: string, value: any) => {
		// console.log('PLEASE update the value', value);
		if (props.onValueUpdated) props.onValueUpdated(propertyName, value);
	};

	const normalizedProperty = SchemaToPropertyInfo(SwaggerCache.resolveModelRef(props.swagger, props.schema), props.propertyName);
	// console.log('normalizedProperty', normalizedProperty);

	switch (props.schema.type) {
		case 'object': {
			if (props.schema.__isPrimitiveObject) {
				return <ModelProperty property={normalizedProperty} onValueUpdated={updateValue} initialValue={props.initialValue} swagger={props.swagger} updateSource={props.updateSource}/>;
			} else if (props.schema.additionalProperties) {
				return <MapContainer property={normalizedProperty} onValueUpdated={updateValue} initialValue={props.initialValue} swagger={props.swagger} updateSource={props.updateSource}/>;
			} else {
				// console.log('&& Creating ObjectContainer:', props.initialValue);
				return (
					<ObjectContainer
						property={normalizedProperty}
						onValueUpdated={updateValue}
						initialValue={props.initialValue}
						isRootElement={true}
						swagger={props.swagger}
						updateSource={props.updateSource}
					/>
				);
			}
		}
		case 'array': {
			return (
				<ArrayContainer
					property={normalizedProperty}
					onValueUpdated={updateValue}
					initialValue={props.initialValue}
					swagger={props.swagger}
					updateSource={props.updateSource}
				/>
			);
		}
		default: {
			if (props.schema.__isRecursive) {
				console.warn('RECURSIVE', props);
				return <div>(recursive)</div>;
			}

			console.warn(`UNKNOWN TYPE: ${props.schema.type}`, props.schema);
			return <div>UNKNOWN TYPE: {props.schema.type}</div>;
		}
	}
}
