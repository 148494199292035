import React from 'react';
import { DxTabbedContent, DxTabPanel } from 'genesys-react-components';
import WebChatV1 from './WebChatV1';
import WebChatV2 from './WebChatV2';
import WebMessenger from './WebMessenger';

function WebChatAndMessenger() {
	return (
		<DxTabbedContent>
			<DxTabPanel title="Web messenger">
				<WebMessenger />
			</DxTabPanel>
			<DxTabPanel title="Web chat V2">
				<WebChatV2 />
			</DxTabPanel>
			<DxTabPanel title="Web chat V1">
				<WebChatV1 />
			</DxTabPanel>
		</DxTabbedContent>
	);
}

export default WebChatAndMessenger;
