import { atom, DefaultValue, RecoilState, selector } from 'recoil';
import { HeaderData, ParameterData } from '../../types';
import SettingsManager from '../settings/SettingsManager';
import { addToast, ToastType } from './ToastAtom';

export interface APIExplorerRequestCacheData {
	[collectionKey: string]: APIExplorerRequestCollection;
}

export interface APIExplorerRequestCollection {
	name: string;
	requestConfigs: { [operationId: string]: RequestConfig | undefined };
}

export interface RequestConfig {
	operationId: string;
	body?: any;
	headers?: HeaderData[];
	parameters?: ParameterData;
}

// Cache for request data selector atoms to avoid creating duplicates
const requestDataAtoms: { [key: string]: RecoilState<RequestConfig | undefined> } = {};
const requestDataPropertyAtoms: { [key: string]: RecoilState<any> } = {};

export const DEFAULT_COLLECTION_NAME = 'default';
const API_EXPLORER_REQUEST_CACHE = 'api-explorer-request-cache';

const apiExplorerRequestCacheAtom = atom({
	key: API_EXPLORER_REQUEST_CACHE,
	default: init(),
	effects_UNSTABLE: [
		({ onSet }) => {
			onSet((newRequestConfig) => {
				saveRequestConfig(newRequestConfig);
			});
		},
	],
});

async function init(): Promise<APIExplorerRequestCacheData> {
	let requestConfigList = {} as APIExplorerRequestCacheData;
	try {
		requestConfigList = ((await SettingsManager.getDirect(API_EXPLORER_REQUEST_CACHE)) || {}) as APIExplorerRequestCacheData;

		//Scrape data from query string on initialization
		const parameters = new URLSearchParams(window.location.search);
		const encodedConfig = parameters.get('requestConfig');
		if (encodedConfig) {
			try {
				let config: RequestConfig | undefined = JSON.parse(decodeURIComponent(escape(window.atob(encodedConfig))));
				if (config && config.operationId) {
					if (requestConfigList.default) {
						requestConfigList.default.requestConfigs[config.operationId] = config;
					} else {
						requestConfigList['default'] = { name: 'default', requestConfigs: {} };
						requestConfigList.default.requestConfigs[config.operationId] = config;
					}
				}
			} catch (err) {
				console.log(err);
			}
		}
	} catch (error) {
		console.error(error);
		addToast({
			title: 'Unable to load cached data',
			message: 'The app will be unable to reload entered request config data, see JavaScript console for more information',
			toastType: ToastType.Warning,
		});
	}

	return requestConfigList;
}

function saveRequestConfig(newRequestConfig: APIExplorerRequestCacheData) {
	let reqConfigData = newRequestConfig;
	SettingsManager.setDirect(API_EXPLORER_REQUEST_CACHE, reqConfigData);
}

export function getRequestDataAtom(operationId: string, collection = DEFAULT_COLLECTION_NAME) {
	const key = `${collection}-${operationId}`;
	// Lazy init selector
	if (!requestDataAtoms[key]) {
		requestDataAtoms[key] = selector<RequestConfig | undefined>({
			key,
			get: ({ get }) => {
				// Get data from cache
				const cacheData = get(apiExplorerRequestCacheAtom);
				let config = cacheData[collection]?.requestConfigs[operationId];

				// Initialize default object
				if (!config) {
					config = {
						operationId,
					};
				}

				// Return value
				return config;
			},
			set: ({ get, set }, newValue) => {
				if (newValue instanceof DefaultValue) return;

				// Lazy init collection
				const cacheData = { ...get(apiExplorerRequestCacheAtom) };
				if (!cacheData[collection]) cacheData[collection] = { name: collection, requestConfigs: {} };

				// Set request data for operation
				cacheData[collection].requestConfigs[operationId] = newValue;

				// Update atom
				set(apiExplorerRequestCacheAtom, cacheData);
			},
		});
	}

	// Return selector atom
	return requestDataAtoms[key];
}

export function getRequestDataHeadersAtom(operationId: string, collection?: string) {
	return getRequestDataPropertyAtom<HeaderData[] | undefined>(collection, operationId, 'headers');
}
export function getRequestDataParametersAtom(operationId: string, collection?: string) {
	return getRequestDataPropertyAtom<ParameterData | undefined>(collection, operationId, 'parameters');
}
export function getRequestDataBodyAtom(operationId: string, collection?: string) {
	return getRequestDataPropertyAtom<any>(collection, operationId, 'body');
}

function getRequestDataPropertyAtom<T>(
	collection = DEFAULT_COLLECTION_NAME,
	operationId: string,
	dataProperty: 'headers' | 'parameters' | 'body'
): RecoilState<T> {
	const key = `${collection}-${operationId}-${dataProperty}`;

	// Lazy init selector
	if (!requestDataPropertyAtoms[key]) {
		requestDataPropertyAtoms[key] = selector({
			key,
			get: ({ get }) => {
				// Get data from cache
				const cacheData = get(apiExplorerRequestCacheAtom);
				let config = cacheData[collection]?.requestConfigs[operationId];

				// Initialize default object
				if (!config) {
					config = {
						operationId,
					};
				}

				// Return value
				return config[dataProperty];
			},
			set: ({ get, set }, newValue) => {
				if (newValue instanceof DefaultValue) return;

				// Lazy init collection
				const cacheData = { ...get(apiExplorerRequestCacheAtom) };
				if (!cacheData[collection]) cacheData[collection] = { name: collection, requestConfigs: {} };
				else cacheData[collection] = { ...cacheData[collection] };

				// Lazy init request config
				cacheData[collection].requestConfigs = { ...cacheData[collection].requestConfigs } || {};
				if (!cacheData[collection].requestConfigs[operationId]) {
					cacheData[collection].requestConfigs[operationId] = {
						operationId,
					};
				} else {
					cacheData[collection].requestConfigs[operationId] = { ...cacheData[collection].requestConfigs[operationId] } as RequestConfig;
				}

				// Set request data for operation
				// Note: requestConfigs is always initialized in the code above. This cast is necessary because typescript isn't inferring that it's definitely set
				(cacheData[collection].requestConfigs[operationId] as RequestConfig)[dataProperty] = newValue;

				// Update atom
				set(apiExplorerRequestCacheAtom, cacheData);
			},
		});
	}

	// Return selector atom
	return requestDataPropertyAtoms[key];
}