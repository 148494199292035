import { Region } from '../../../helpers/platformapi/Region';

export enum AppLocation {
	directoryMenu = 'Directory Menu',
	performanceMenu = 'Performance Menu',
	appsMenu = 'Apps Menu',
	sidecar = 'Sidecar (aka Widget Panel)',
}

export interface ListingDetails {
	name: string;
	description: string;

	vendorName: string;
	vendorWebsite: string;
	marketplaceURL: string;

	helpURL: string;
	installationAppIcon?: {
		file?: File;
		name?: string;
		resolution?: string; // eg. '128x128'
		error?: string;
	};

	defaultAppURL: string;
	defaultSandboxOptions: Array<string>;
	defaultPermissionsPolicy: Array<string>;
	applicationLocation: AppLocation;

	hostedAppIcon: string;
	orgId: string;
	oauthClientId: string;
	tosURL: string;
	faqURL?: string;
	privacyPolicyURL?: string;
	supportContactURL?: string;
	salesContactURL?: string;
	additionalHelpURL?: string;

	prodOrgRegion?: Region;

	accessControl: boolean;
}
