interface LegacyCategoryMapping {
	[id: number]: string;
}

export type TaxonomyCategoryKeys =
	| 'analyticsdatamanagement'
	| 'authorization'
	| 'billing'
	| 'commdigital'
	| 'gdprprivacy'
	| 'notificationsalerts'
	| 'organization'
	| 'platform'
	| 'routing'
	| 'telephony'
	| 'useragentman';

export interface TaxonomyCategoryData {
	displayName: string;
}

const categories: LegacyCategoryMapping = {
	// 0: 'Introduction to Genesys Cloud Development',
	1: 'authorization',
	2: 'useragentman',
	3: 'telephony',
	4: 'routing',
	5: 'organization',
	6: 'platform',
	7: 'commdigital',
	8: 'organization',
	9: 'notificationsalerts',
	10: 'analyticsdatamanagement',
	11: 'analyticsdatamanagement',
	12: 'useragentman',
};

class TaxonomyCategories {
	categoryKeyData: Record<TaxonomyCategoryKeys | string, TaxonomyCategoryData | undefined> = {
		analyticsdatamanagement: {
			displayName: 'Analytics & Data Management',
		},
		authorization: {
			displayName: 'Authorization',
		},
		billing: {
			displayName: 'Billing',
		},
		commdigital: {
			displayName: 'Communication & Digital Channels',
		},
		gdprprivacy: {
			displayName: 'GDPR & Privacy',
		},
		notificationsalerts: {
			displayName: 'Notifications & Alerts',
		},
		organization: {
			displayName: 'Organization',
		},
		platform: {
			displayName: 'Platform',
		},
		routing: {
			displayName: 'Routing & Conversation Handling',
		},
		telephony: {
			displayName: 'Telephony',
		},
		useragentman: {
			displayName: 'User & Agent Management',
		},
	};

	getKeyFromLegacyId(id: number) {
		return categories[id];
	}
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new TaxonomyCategories();
