import React, { ReactNode } from 'react';

import './CardGrid.scss';

interface IProps {
	columns: 'responsive' | 1 | 2 | 3 | 4 | 5 | 6;
	children: ReactNode;
}

export default function CardGrid(props: IProps) {
	return <div className={`card-grid columns-${props.columns}`}>{props.children}</div>;
}
