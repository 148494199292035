import { ModelSchema, NormalizedProperty } from './OpenAPITypes';
import SwaggerCache from './SwaggerCache';

export function SchemaToPropertyInfo(schema: ModelSchema, propertyName?: string) {
	const p = {
		schema,
		propertyName: propertyName || schema.__propertyName,
		typeDisplay:
			schema.__modelName || (schema.format && schema.type ? `${schema.type}:${schema.format}` : undefined) || schema.type || 'Unknown',
		className: 'default',
	} as NormalizedProperty;
	p.id = `${p.propertyName}-id`; //-${Math.floor(Math.random() * 9000) + 1000}`;
	return p;
}

export function processModel(schema: ModelSchema, isRequestModel?: boolean, newSchema?: any, swagger?: any, knownModels: string[] = []) {
	if (!schema) return '';

	//deduping check
	if (schema.$ref) {
		let modelName = /^#\/definitions\/(.+)$/i.exec(schema.$ref);
		if (modelName && knownModels.includes(modelName[1])) {
			return getDefaultValueForJson(schema.type);
		} else if (modelName && !knownModels.includes(modelName[1])) {
			knownModels.push(modelName[1]);
		}
	}

	if (!newSchema) {
		if (schema.type === 'array') newSchema = [];
		else newSchema = {};
	}

	if (schema.type === 'object') {
		for (let [key, property] of Object.entries(schema.properties || {})) {
			if (!property.readOnly || !isRequestModel) {
				if (property.type === 'object') {
					newSchema[key] = {};
					if (property.additionalProperties && property.additionalProperties.type) {
						if (property.__modelName) {
							newSchema[key][''] = {};
							processModel(property.additionalProperties, isRequestModel, newSchema[key][''], swagger, knownModels);
						} else {
							newSchema[key][''] = getDefaultValueForJson(property.additionalProperties.type);
						}
					} else {
						let p;
						if (swagger && property.items) {
							p = SwaggerCache.resolveModelRef(swagger, property.items);
						} else {
							p = property;
						}
						processModel(p, isRequestModel, newSchema[key], swagger, knownModels);
					}
				} else if (property.type === 'array') {
					newSchema[key] = [];
					processModel(property, isRequestModel, newSchema[key], swagger, knownModels);
				} else {
					newSchema[key] = getDefaultValueForJson(property.type);
				}
			}
		}
	} else if (schema.type === 'array' && schema.items) {
		// True if the schema is an array and its items are a ref to an object, but the type field is not present. This prevents defaulting to a string.
		const isRefObjArrMissingType: boolean = !schema.items.type && !!schema.items.$ref;
		if (schema.items.type === 'object' || isRefObjArrMissingType) {
			newSchema.push(processModel(schema.items, isRequestModel, undefined, swagger, knownModels));
		} else {
			newSchema.push(getDefaultValueForJson(schema.items.type));
		}
	} else {
		// True if the the schema is a ref to an object, but the type field is not present. This prevents defaulting to a string.
		const isRefObjMissingType: boolean = !schema.type && !!schema.$ref;
		if (isRefObjMissingType) {
			SwaggerCache.resolveModelRef(swagger, schema);
			schema.type = 'object';
			processModel(schema, isRequestModel, newSchema, swagger, knownModels);
		} else {
			return getDefaultValueForJson(schema.type);
		}
	}

	return newSchema;
}

export function getDefaultValueForJson(type?: string) {
	if (!type || typeof type === 'undefined') return '';
	switch (type.toLowerCase()) {
		case 'string':
			return '';
		case 'boolean':
			return true;
		case 'integer':
		case 'number':
			return 0;
		case 'object':
			return {};
		default:
			return `UNKNOWN TYPE (${type})`;
	}
}
