import React, { useEffect, useState } from 'react';
import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';
import { DxButton, DxCheckbox } from 'genesys-react-components';
import { useRecoilValue } from 'recoil';

import AppSettings from '../../helpers/settings/AppSettings';
import DxLink from '../dxlink/DxLink';

import './PrivacyBanner.scss';
import { selectedThemeAtom } from '../../theme/ThemeAtom';

export default function PrivacyBanner() {
	const [show, setShow] = useState(false);
	const telemetryAllowed = useRecoilValue(AppSettings.allowTelemetryAtom());
	const storageAllowed = useRecoilValue(AppSettings.allowStorageAtom());
	const theme = useRecoilValue(selectedThemeAtom());

	useEffect(() => {
		if (storageAllowed === undefined) setShow(true);
	}, [storageAllowed]);

	if (!show) return null;

	const dismiss = async () => {
		setShow(false);
		const x = AppSettings.getAllowStorage();
		if (x === undefined) AppSettings.setAllowStorage(false);
	};

	return (
		<div className={`settings-consent ${theme}`}>
			<div className="settings-consent-container">
				<div className="left-column">
					<h2 className="settings-consent-title">
						<GenesysDevIcon icon={GenesysDevIcons.AppShieldCheck} />
						Privacy Settings
					</h2>
					<div className="message-text">
						This site uses cookies and related technologies, as described in our privacy policy, for purposes that may include site
						operation, analytics, enhanced user experience, or advertising. You may choose to consent to our use of these technologies, or
						manage your own preferences. <DxLink href="https://www.genesys.com/company/legal/privacy-policy">Learn more here</DxLink>
					</div>
				</div>
				<div className="right-column">
					<DxCheckbox
						label="Allow telemetry analytics"
						itemValue="telemetry"
						checked={telemetryAllowed}
						onCheckChanged={(checked) => {
							if (checked === telemetryAllowed) return;
							AppSettings.setAllowTelemetry(checked === true);
						}}
					/>
					<DxCheckbox
						label="Allow browser storage"
						itemValue="storage"
						checked={storageAllowed}
						onCheckChanged={(checked) => {
							if (checked === storageAllowed || (checked === false && storageAllowed === undefined)) return;
							AppSettings.setAllowStorage(checked === true);
						}}
					/>
				</div>
				<DxButton type="primary" onClick={dismiss}>
					Save Settings
				</DxButton>
			</div>
			<button
				className="close-button"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					dismiss();
				}}
			>
				<GenesysDevIcon icon={GenesysDevIcons.AppTimes} />
			</button>
		</div>
	);
}
