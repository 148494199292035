import { atom, selector } from 'recoil';
import { getRecoil, setRecoil } from 'recoil-nexus';
import { ApiResourceProps, PageInfo, ToolboxApp, ToolboxItem, ToolboxItems, ToolboxSize } from '../../types';
import AppSettings from '../settings/AppSettings';
import SettingsManager from '../settings/SettingsManager';
import { addToast, Toast, ToastType } from './ToastAtom';

const SETTING_TOOLBOX_SIZE = 'toolboxSize';

/*** TOOLBOX SIZE ***/

// Minimums based on 720p resolution
const MIN_WIDTH = 860;
const MIN_HEIGHT = 560;
const clamp = (val: number, min: number, max: number) => (Math.max(isNaN(val) ? min : val, min) === min ? min : Math.min(val, max));
const clampSize = (size: ToolboxSize) => ({
	width: clamp(size.width, MIN_WIDTH, document.documentElement.clientWidth),
	height: clamp(size.height, MIN_HEIGHT, document.documentElement.clientHeight - 90),
});

// Force size recheck on window resize events
window.addEventListener('resize', () => setToolboxSize(getRecoil(toolboxSizeAtom)));

export const toolboxSizeAtom = atom({
	key: SETTING_TOOLBOX_SIZE,
	default: (async (): Promise<ToolboxSize> => {
		const size = await SettingsManager.getDirect<ToolboxSize>(SETTING_TOOLBOX_SIZE);
		return clampSize(
			size || {
				width: 0,
				height: 0,
			}
		);
	})(),
	effects_UNSTABLE: [
		({ onSet }) => {
			onSet((val) => {
				// Save the value whenever it changes
				SettingsManager.setDirect(SETTING_TOOLBOX_SIZE, val);
			});
		},
	],
});

export function setToolboxSize(size: ToolboxSize) {
	setRecoil(toolboxSizeAtom, clampSize(size));
}

/*** TOOLBOX ITEMS ***/
const toolboxItemsAtom = AppSettings.toolboxItemsAtom();

export const toolboxPageItemsAtom = selector({
	key: 'toolboxPageItem',
	get: ({ get }) => {
		const toolboxItems = get(toolboxItemsAtom);
		return toolboxItems.pages;
	},
});

export const toolboxApiResourcesAtom = selector({
	key: 'toolboxApiResource',
	get: ({ get }) => {
		const toolboxItems = get(toolboxItemsAtom);
		return toolboxItems.apiexplorer;
	},
});

// App-specific aliases for addItem
export const AddApiResource = (apiResourceItem: ToolboxItem) => {
	// Storage not allowed, warn users their setting will be lost
	if (!SettingsManager.getStorageAllowed()) {
		const toast: Toast = {
			toastType: ToastType.Warning,
			title: 'Browser storage is disabled',
			message: `Starred API resources will not be remembered when you refresh the page. Please enable browser storage in the Account Switcher above to allow your accounts to be remembered.`,
			timeoutSeconds: 30,
		};
		addToast(toast);
	}

	return addItem(ToolboxApp.ApiExplorer, apiResourceItem);
};
export const AddNotificationChannel = (channelItem: ToolboxItem) => addItem(ToolboxApp.Notifications, channelItem);
export const AddPage = (pageItem: ToolboxItem) => {
	// Storage not allowed, warn users their setting will be lost
	if (!SettingsManager.getStorageAllowed()) {
		const toast: Toast = {
			toastType: ToastType.Warning,
			title: 'Browser storage is disabled',
			message: `Starred pages will not be remembered when you refresh the page. Please enable browser storage in the Account Switcher above to allow your accounts to be remembered.`,
			timeoutSeconds: 30,
		};
		addToast(toast);
	}

	return addItem(ToolboxApp.Pages, pageItem);
};
export const AddSettingsPage = (settingsPageItem: ToolboxItem) => addItem(ToolboxApp.Settings, settingsPageItem);

function addItem(app: ToolboxApp, item: ToolboxItem) {
	const items: ToolboxItems = { ...getRecoil(toolboxItemsAtom) };
	item.appType = app;
	if (item.appType === ToolboxApp.Notifications || item.appType === ToolboxApp.Settings) item.disableUserRemove = true;
	// Avoid duplicates
	if (items[app].some((existingItem) => areToolboxItemsEquivalent(existingItem, item))) return;
	items[app] = [...items[app], item];
	setRecoil(toolboxItemsAtom, items);
}

export function removeItem(item: ToolboxItem) {
	const items = { ...getRecoil(toolboxItemsAtom) };
	if (!item.appType) return;
	const idx = items[item.appType].indexOf(item);
	if (idx < 0) return;
	items[item.appType] = [...items[item.appType]];
	items[item.appType].splice(idx, 1);
	setRecoil(toolboxItemsAtom, items);
}

export function removeApiResource(item: ToolboxItem) {
	const items = { ...getRecoil(toolboxItemsAtom) };
	if (!item.appType) return;
	const filteredItems = {
		...items,
		[item.appType]: items[item.appType].filter((i) => {
			const apiResourceProps1 = i.props as ApiResourceProps;
			const apiResourceProps2 = item.props as ApiResourceProps;
			return (
				i.appType !== item.appType ||
				apiResourceProps1.path !== apiResourceProps2.path ||
				apiResourceProps1.verb !== apiResourceProps2.verb ||
				i.title !== item.title
			);
		}),
	};
	setRecoil(toolboxItemsAtom, filteredItems);
}

export function areToolboxItemsEquivalent(a?: ToolboxItem, b?: ToolboxItem) {
	if (!a || !b) return false;
	if ((a.props as PageInfo).link !== (b.props as PageInfo).link) return false;
	return a.title === b.title && a.appType === b.appType;
}
