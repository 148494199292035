import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';
import React, { useEffect, useState } from 'react';
import AssetLoader from '../../../helpers/AssetLoader';

import CodeFence from '../../codefence/CodeFence';
import LoadingPlaceholder from '../../loadingplaceholder/LoadingPlaceholder';
import AlertBlock from '../../markdown/alertblock/AlertBlock';
import TabbedContent from '../../tabbedcontent/TabbedContent';

import './QuickHit.scss';

interface IProps {
	id: string;
	title?: string;
	defaultTitle?: string;
}

interface QuickHitList {
	[language: 'java' | 'javascript' | 'python' | 'go' | 'dotnet' | 'shell' | 'sh' | 'cli' | string]: QuickHitData;
}

interface QuickHitData {
	code: string;
	path: string;
	link: string;
	description?: string;
	codeLanguage?: string;
}

export default function QuickHit(props: IProps) {
	const [data, setData] = useState(undefined as unknown as QuickHitList);
	const [currentKey, setCurrentKey] = useState('');
	const [error, setError] = useState(false);

	// Constructor
	useEffect(() => {
		AssetLoader.get(`/data/quickhit/${encodeURIComponent(props.id)}.json`, true)
			.then((data) => {
				setData(data);
				setCurrentKey(Object.keys(data).shift() || '');
			})
			.catch((err) => {
				console.error(err);
				setError(true);
			});
	}, [props.id]);

	const title = props.title || props.defaultTitle ? <span className="h2">{props.title || titleize(props.id)}</span> : undefined;

	let content = error ? (
		<AlertBlock alertType="critical">
			<p>
				Failed to load quick hit <code>{props.id}</code>
			</p>
		</AlertBlock>
	) : (
		<LoadingPlaceholder text="Loading Quick Hit" />
	);
	if (data) {
		const makeContentPane = (quickhit: QuickHitData) => {
			return (
				<div>
					<div className="file-header">
						{quickhit.link ? (
							<a className="github-link" href={quickhit.link} target="_blank" rel="noopener noreferrer" title="View on GitHub">
								<GenesysDevIcon icon={GenesysDevIcons.BrandGithub} />
							</a>
						) : undefined}
						<div className="file-info">
							<span>{quickhit.path}</span>
							<span>{quickhit.description}</span>
						</div>
					</div>
					<CodeFence
						language={getStandardLanguageKey(quickhit.codeLanguage || currentKey)}
						value={quickhit.code || `Failed to load quick hit: ${props.id}`}
					/>
				</div>
			);
		};
		content = <TabbedContent titles={Object.keys(data).map(langDisplay)} panes={Object.values(data).map(makeContentPane)} />;
	}

	return (
		<div className="quick-hit">
			{title}
			{content}
		</div>
	);
}

function titleize(val: string) {
	return val
		.replaceAll(/[-_]/g, ' ')
		.split(/\s+/)
		.map((word) => word.substring(0, 1).toUpperCase() + word.substring(1))
		.join(' ');
}

function getStandardLanguageKey(lang: string) {
	if (!lang) return 'nohighlight';

	switch (lang.toLowerCase()) {
		case 'cli':
			return 'shell';
		default:
			return lang.toLowerCase();
	}
}

function langDisplay(name: string) {
	switch (name) {
		case 'java':
			return 'Java';
		case 'javascript':
			return 'JavaScript';
		case 'python':
			return 'Python';
		case 'go':
			return 'Go';
		case 'dotnet':
			return '.NET';
		case 'shell':
		case 'sh':
			return 'Shell';
		case 'cli':
			return 'CLI Script';
		default:
			return name;
	}
}
