import React from 'react';
import AssetLoader from '../../../helpers/AssetLoader';

function PdfViewer(props) {
	const url = AssetLoader.contentHost + props.url;
	const style = { border: 0, height: '100%', left: 0, position: 'absolute', top: 0, width: '100%' };

	return <iframe title='PDF Viewer' src={url} style={style} />;
}

export default PdfViewer;
