import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';
import { useRecoilState, useRecoilValue } from 'recoil';
import useStayScrolled from 'react-stay-scrolled';
import axios, { CancelTokenSource } from 'axios';
import { DxItemGroup, DxItemGroupItem, DxItemGroupItemValue } from 'genesys-react-components';
import moment from 'moment';

import EventCard from './eventcard/EventCard';
import CopyButton from '../../copybutton/CopyButton';
import TopicParameterEditor from './TopicParameterEditor';
import { eventsAtom, updateSubscriptions } from '../../../helpers/atoms/ChannelAtoms';
import { selectedAccountAtom } from '../../../helpers/atoms/AccountsAtom';
import { Channel, TopicEvent } from './notificationtopics/NotificationDefinitions';
import { addToast, ToastType } from '../../../helpers/atoms/ToastAtom';
import { MinimalTopic } from './notificationtopics/NotificationDefinitions';

import './ChannelContent.scss';

interface IProps {
	channel: Channel;
}

interface SubscribedTopic {
	id: string;
}

export default function ChannelContent(props: IProps) {
	const [checkboxItems, setCheckboxItems] = useState<DxItemGroupItem[]>([]);
	const [checkboxValues, setCheckboxValues] = useState<DxItemGroupItemValue[]>([]);
	const [chevronState, setChevronState] = useState(true);
	const [selectedAccount] = useRecoilState(selectedAccountAtom);
	const [topics, setTopics] = useState<MinimalTopic[]>([]);

	const channel: Channel = props.channel;
	const subscribedTopicsUpdated = useRecoilValue(updateSubscriptions);
	const events: TopicEvent[] = useRecoilValue(eventsAtom(channel.connectUri));
	const eventsDivRef = useRef(null);
	const cancelToken = useRef<CancelTokenSource | undefined>();
	const { stayScrolled } = useStayScrolled(eventsDivRef);

	useLayoutEffect(() => {
		stayScrolled();
		//eslint-disable-next-line
	}, [events.length]);

	//constructor
	useEffect(() => {
		return () => {
			cancelToken.current?.cancel('component was unmounted');
		};
	}, []);

	useEffect(() => {
		const tokenSource = axios.CancelToken.source();
		cancelToken.current = tokenSource;
		if (selectedAccount) {
			selectedAccount.api
				.request({
					method: 'get',
					url: `/api/v2/notifications/channels/${channel.id}/subscriptions`,
					cancelToken: cancelToken.current.token,
				})
				.then((res) => {
					const items: DxItemGroupItem[] = res.data.entities.map((i: SubscribedTopic) => {
						return { label: i.id, value: i.id };
					});
					setCheckboxItems(items);
				})
				.then(() => {
					return selectedAccount.api.request({
						method: 'get',
						url: `/api/v2/notifications/availabletopics?expand=requiresPermissions,enforced,transports`,
						cancelToken: cancelToken.current?.token,
					});
				})
				.then((res) => {
					setTopics(res.data.entities);
				})
				.catch((err) => {
					if (axios.isCancel(err)) {
					} else {
						addToast({ title: 'Failed to load channel data', message: err.message, toastType: ToastType.Critical });
					}
				});
		}
	}, [selectedAccount, channel, subscribedTopicsUpdated]);

	function filterEvents() {
		let filteredEvents: TopicEvent[] = [];

		//Get pinned events
		for (const event of events) {
			if (event.isPinned) {
				filteredEvents.push(event);
			}
		}

		//Do not filter if no checkbox is selected
		if (checkboxValues.every((item) => !item.isSelected)) {
			for (const event of events) {
				if (!event.isPinned) {
					filteredEvents.push(event);
				}
			}
			return filteredEvents;
		}

		for (const event of events) {
			if (checkboxValues.some((value: DxItemGroupItemValue) => value.item.value === event.eventData['topicName'] && value.isSelected)) {
				if (!event.isPinned) filteredEvents.push(event);
			}
		}

		return filteredEvents;
	}

	const filteredEvents = filterEvents();

	return (
		<div className="channel-content-container">
			<span className="channel-content-heading">Channel Details</span>

			<div className="channel-info-container">
				<span className="channel-info">
					<span className="channel-info-label">Expires: </span>
					<span className="channel-info-value">{moment(channel.expires).format('LLLL')}</span>
				</span>
				<span className="channel-info">
					<span className="channel-info-label">Connect URI: </span>
					<span className="channel-info-value"> {channel.connectUri}</span>
				</span>
				<span>
					<CopyButton copyText={channel.connectUri || 'CONNECT URI MISSING'} />
				</span>
			</div>
			<div className="channel-content-link">
				<span>
					Learn how to <a href="/notificationsalerts/notifications/">connect to the notification service</a>
				</span>
			</div>
			<div className="filter-subscription-container">
				<div className="filtering-container">
					<span className="section-heading" title="Filter events based on topic name" onClick={() => setChevronState(!chevronState)}>
						Filters{' '}
					</span>
					<span className={`chevron${chevronState ? '' : ' -hidden'}`} onClick={() => setChevronState(!chevronState)}>
						{' '}
						<GenesysDevIcon icon={GenesysDevIcons.AppChevronDown} />
					</span>
					<span className={`chevron${chevronState ? ' -hidden' : ''}`} onClick={() => setChevronState(!chevronState)}>
						{' '}
						<GenesysDevIcon icon={GenesysDevIcons.AppChevronUp} />
					</span>

					{chevronState && (
						<div>
							<div className="topics-heading-container">
								<span className="h7 topics-heading">Topics</span>
							</div>
							<div className="subscribed-topics">
								<DxItemGroup
									format="checkbox"
									className="filter-topics-checkboxes"
									items={checkboxItems}
									onItemsChanged={(values) => setCheckboxValues(values)}
								/>
							</div>
						</div>
					)}
				</div>
				<div className="subscription-container">
					<span className="section-heading">Subscribe to Topic</span>
					<div className="parametereditor-container">
						<div>
							<TopicParameterEditor topic={topics} channelId={channel.id || ''} />
						</div>
					</div>
				</div>
			</div>
			<div>
				<span className="channel-content-heading">Event List</span>
				<div className="eventList" ref={eventsDivRef}>
					{filteredEvents.map((event: TopicEvent, i: number) => (
						<EventCard key={i} event={event} />
					))}
				</div>
			</div>
		</div>
	);
}
