import React from 'react';
import AssetLoader from '../../helpers/AssetLoader';

function ContentImage(props) {
	return <img src={makeAbsoluteUrl(props.src)} alt={props.alt} className={props.className} />;
}

export default ContentImage;

function makeAbsoluteUrl(src) {
	return AssetLoader.contentHost + src.replace(/^\/+/, '/');
}