import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';
import React from 'react';

import './ResponseContainer.scss';

interface IProps {
	children: any;
	title: string;
	showExpanded?: boolean;
}

interface IState {
	isCollapsed: boolean;
}

export default class ResponseContainer extends React.PureComponent<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			isCollapsed: props.showExpanded !== true,
		};
	}

	render() {
		return (
			<div className="response-container">
				<div className="response-header" onClick={() => this.setState({ isCollapsed: !this.state.isCollapsed })}>
					<GenesysDevIcon
						className="collapse-arrow"
						icon={this.state.isCollapsed ? GenesysDevIcons.AppChevronRight : GenesysDevIcons.AppChevronDown}
					/>
					<span>{this.props.title}</span>
				</div>
				{this.state.isCollapsed ? undefined : <div className="response-content">{this.props.children}</div>}
			</div>
		);
	}
}
