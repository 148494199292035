import axios, { AxiosRequestConfig, CancelToken } from 'axios';
// import { default as qs } from 'qs';
// import { default as Cookies } from 'js-cookie';

const originRegex = /^(https:\/\/developer(?:-messaging|-beta)?)(\.(?:dev-|test-)?genesys\.cloud)$/i;

class AssetLoader {
	contentHost: string;

	constructor() {
		if (process.env.REACT_APP_CONTENT_HOST && process.env.REACT_APP_CONTENT_HOST !== '') {
			this.contentHost = process.env.REACT_APP_CONTENT_HOST;
		} else {
			this.contentHost = window.location.origin.replace(originRegex, '$1-content$2');
		}

		// Get JWT and store in cookie
		// const queryParams = qs.parse(window.location.search.replace(/^\?/, ''));
		// if (queryParams.jwt) {
		// 	Cookies.set('jwt', queryParams.jwt, {
		// 		domain: process.env.REACT_APP_COOKIE_DOMAIN,
		// 	});
		// }
		// if (queryParams.state) {
		// 	window.location.href = decodeURIComponent(queryParams.state as string);
		// }
	}

	generateCancelToken() {
		return axios.CancelToken.source();
	}

	isCancel(err: any) {
		return axios.isCancel(err);
	}

	get(pathname: string, returnRawBody?: boolean, cancelToken?: CancelToken, timeoutMs?: number) {
		const config: AxiosRequestConfig = {
			method: 'get',
			url: this.contentHost + pathname,
			cancelToken,
		};

		if (timeoutMs && timeoutMs > 0) {
			config.timeout = timeoutMs;
		}

		return axios
			.request(config)
			.then((response) => {
				if (returnRawBody) return response.data;

				// Stringify objects for content
				let data = typeof response.data === 'object' ? `<pre>${JSON.stringify(response.data, null, 2)}</pre>` : response.data;
				return this.addContentHost(data, pathname);
			})
			.catch((err) => {
				if (axios.isCancel(err)) {
					// console.log('request canceled, not an error. Message: ', err.message);
					return;
				}
				if (err.response && err.response.status === 401 && process.env.REACT_APP_OAUTH_REDIRECT_URI) {
					const state = encodeURIComponent(
						window.location.pathname +
							(window.location.search && window.location.search.length > 1 ? window.location.search : '') +
							(window.location.hash && window.location.hash.length > 1 ? window.location.hash : '')
					);
					window.location.href = process.env.REACT_APP_OAUTH_REDIRECT_URI + '&state=' + state;
				} else {
					throw err;
				}
			});
	}

	put(pathname: string, requestBody?: any, requestContentType?: string, cancelToken?: CancelToken, timeoutMs?: number) {
		const config: AxiosRequestConfig = {
			method: 'put',
			url: this.contentHost + pathname,
			headers: {
				'content-type': requestContentType,
			},
			data: requestBody,
			cancelToken,
		};

		if (timeoutMs && timeoutMs > 0) {
			config.timeout = timeoutMs;
		}

		return axios
			.request(config)
			.then((res) => {
				console.log('RESPONSE', res);
				return res.data;
			})
			.catch((err) => {
				if (axios.isCancel(err)) {
					// console.log('request canceled, not an error. Message: ', err.message);
					return;
				}
				throw err;
			});
	}

	post(pathname: string, requestBody?: any, requestContentType?: string, cancelToken?: CancelToken, timeoutMs?: number) {
		const config: AxiosRequestConfig = {
			method: 'post',
			url: this.contentHost + pathname,
			headers: {
				'content-type': requestContentType,
			},
			data: requestBody,
			cancelToken,
		};

		if (timeoutMs && timeoutMs > 0) {
			config.timeout = timeoutMs;
		}

		return axios
			.request(config)
			.then((res) => {
				return res.data;
			})
			.catch((err) => {
				if (axios.isCancel(err)) {
					// console.log('request canceled, not an error. Message: ', err.message);
					return;
				}
				throw err;
			});
	}

	setContentHost(contentHost: string) {
		this.contentHost = contentHost;
	}

	addContentHost(document: string, contextPath = '') {
		// Strip filename
		contextPath = contextPath.substring(0, contextPath.lastIndexOf('/') + 1);

		// Add content host to image sources
		const replacer = (match: string, p1: string, p2: string, p3: string) => {
			let file;
			if (p2.startsWith('data:image')) {
				file = p2;
			} else if (p2.startsWith('//')) {
				file = p2;
			} else if (p2.startsWith('/')) {
				file = this.contentHost + p2;
			} else if (!p2.includes('/') || (p2.includes('/') && !p2.startsWith('http'))) {
				file = this.contentHost + contextPath + p2;
			} else {
				file = p2;
			}
			return `${p1}${file}${p3}`;
		};

		document = document.replace(/(<img.+?src=['"])(.+?)(['"].*?>)/gi, replacer);
		document = document.replace(/(<link.+?href=['"])(.+?)(['"].*?>)/gi, replacer);

		return document;
	}
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new AssetLoader();
