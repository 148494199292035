import React from 'react';

import { OperationDetails } from '../../../../helpers/openapi/OpenAPITypes';
import { AuthNames } from '../../../../types';
import DxLink from '../../../dxlink/DxLink';

interface IProps {
	operationDetails: OperationDetails;
}

interface AuthDetails {
	name: string;
	docLinkText?: string;
	docLink?: string;
}

export default function OperationSecurityDisplay(props: IProps) {
	const getAuthInfo = (authName: string): AuthDetails | undefined => {
		switch (authName) {
			case AuthNames.GenesysCloudOAuth:
				return {
					name: 'Genesys Cloud OAuth',
					docLinkText: 'Platform API Client Authorization',
					docLink: '/authorization/platform-auth/',
				};
			case AuthNames.WebMessaging: {
				return {
					name: 'Web messaging JWT',
					docLinkText: 'Web Messaging Guest API',
					docLink: '/commdigital/digital/webmessaging/websocketapi',
				};
			}
			case AuthNames.GuestChat: {
				return {
					name: 'Guest Chat JWT',
					docLinkText: 'Guest Chat APIs',
					docLink: '/commdigital/digital/webchat/guestchat',
				};
			}
			case undefined:
			case null:
			case '': {
				return;
			}
			default: {
				return {
					name: authName,
				};
			}
		}
	};
	return (
		<div>
			{(!props.operationDetails.operation?.security || props.operationDetails.operation?.security?.length === 0) && (
				<p>
					This resource does not require authorization; the <code>Authorization</code> header should be omitted from this request.
				</p>
			)}
			{props.operationDetails.operation?.security?.map((authDetails) => {
				return Object.keys(authDetails).map((authName) => {
					const authInfo = getAuthInfo(authName);
					if (!authInfo) return undefined;
					return (
						<p key={authName}>
							This resource requires authorization via {authInfo.name}.
							{authInfo.docLink && (
								<React.Fragment>
									{' '}
									Read more about this authorization type at <DxLink href={authInfo.docLink}>{authInfo.docLinkText || 'this link'}</DxLink>.
								</React.Fragment>
							)}
						</p>
					);
				});
			})}
		</div>
	);
}
