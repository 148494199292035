import React, { useEffect } from 'react';
import { DxTextbox } from 'genesys-react-components';
import { useRecoilState, useRecoilValue } from 'recoil';

import { getRequestDataHeadersAtom } from '../../../../helpers/atoms/APIExplorerRequestCache';
import { OperationDetails } from '../../../../helpers/openapi/OpenAPITypes';
import AppSettings from '../../../../helpers/settings/AppSettings';
import CodeFence from '../../../codefence/CodeFence';
import { HeaderData } from '../../../../types';
import { selectedAccountAtom } from '../../../../helpers/atoms/AccountsAtom';

import './HeaderEditor.scss';

interface IProps {
	operationDetails: OperationDetails;
}

export default function HeaderEditor(props: IProps) {
	const selectedAccount = useRecoilValue(selectedAccountAtom);
	const readingMode = useRecoilValue(AppSettings.apiExplorerReadingModeAtom());
	const [headerData, setHeaderData] = useRecoilState(getRequestDataHeadersAtom(props.operationDetails.operation.operationId));
	const customHeader = getCustomHeaderParameters();

	// Constructor
	useEffect(() => {
		// Initialize headers
		if (!headerData) resetHeaders();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Update on account changed
	useEffect(() => {
		if (!headerData) return;
		// Update Authorization header with new token
		const newHeaders = [...headerData];
		const authHeaderIndex = newHeaders.findIndex((data) => data.name.toLowerCase() === 'authorization');
		if (authHeaderIndex >= 0) {
			// BUG: sometimes the account isn't initialized and doesn't have a token yet? This is a race condition when opening a resource immediately on pageload (anchor link)
			newHeaders[authHeaderIndex] = { ...newHeaders[authHeaderIndex], value: selectedAccount?.token || '' };
		}

		//add custom header elements if applicable
		if (customHeader) {
			for (let index = 0; index < customHeader.length; index++) {
				if (!newHeaders.some((data) => data.name === customHeader[index].name)) {
					newHeaders.push({
						...customHeader,
						name: customHeader[index].name,
						value: customHeader[index].default || ' ',
						disableUserRemove: false,
					});
				}
			}
		}
		setHeaderData(newHeaders);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedAccount]);

	const resetHeaders = () => {
		const initialHeaders: HeaderData[] = [];
		if (props.operationDetails.operation.security?.some((securityInfo) => Object.keys(securityInfo).includes('PureCloud OAuth'))) {
			// Is standard OAuth, add known token
			initialHeaders.push({
				name: 'Authorization',
				value: selectedAccount?.token || '',
				isSecret: true,
				prefix: 'Bearer ',
				disableUserRemove: true,
			});
		} else if (props.operationDetails.operation.security?.some((securityInfo) => Object.keys(securityInfo).length > 0)) {
			// Something that's not OAuth, but there is auth
			initialHeaders.push({ name: 'Authorization', value: '', isSecret: true, disableUserRemove: true });
		}

		initialHeaders.push({ name: 'Content-Type', value: 'application/json', disableUserRemove: true });

		//add custom header elements if applicable
		if (customHeader) {
			for (let index = 0; index < customHeader.length; index++) {
				initialHeaders.push({
					...customHeader,
					name: customHeader[index].name,
					value: customHeader[index].default || ' ',
					disableUserRemove: false,
				});
			}
		}

		setHeaderData(initialHeaders);
	};

	if (!headerData) return null;

	// Handle updates from the textbox
	const updateHeaderData = (headerName: string, headerValue: string) => {
		if (!headerData) return;
		let position = headerData.findIndex((data) => data.name === headerName);
		if (position < 0) {
			//custom header Data never got added to headerData
			const customHeaderData = customHeader?.find((data) => data.name === headerName);
			const newHeaderData = [...headerData];
			newHeaderData.push({ ...customHeaderData, name: headerName, value: headerValue, disableUserRemove: true });
			setHeaderData(newHeaderData);
			return;
		}
		// Debounce updates
		if (headerData[position].name === headerName && headerData[position].value === headerValue) return;

		// Update local state
		const newHeaderData = [...headerData];
		newHeaderData[position] = { ...newHeaderData[position] };
		newHeaderData[position].value = headerValue;
		setHeaderData(newHeaderData);
	};

	//custom header parameters
	function getCustomHeaderParameters() {
		if (!props.operationDetails.operation.parameters) return;
		let parameters = props.operationDetails.operation.parameters;
		let customHeaders = parameters.filter((param) => param.in === 'header');
		if (customHeaders.length === 0) return;
		return customHeaders;
	}

	return (
		<div className="header-editor-container">
			{readingMode && customHeader && (
				<>
					<h3>Custom Headers</h3>
					<CodeFence value={customHeader.map((data) => `${data.name}: ${data.description}`).join('\n')} />
				</>
			)}
			{!readingMode && (
				<div className="header-editor">
					{customHeader?.map((data, i) => {
						return (
							<div key={i}>
								<h3>Custom Headers</h3>
								<div className="header-group">
									<DxTextbox inputType="text" className="header-name" label="Header Name" value={data.name} disabled={true} />
									<div className="header-value">
										<DxTextbox inputType="text" label="header-value" value="" onChange={(value) => updateHeaderData(data.name, value)} />
									</div>
								</div>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
}
