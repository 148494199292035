import React, { useEffect, useState } from 'react';
import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';
import { DxButton } from 'genesys-react-components';

import { EntityTypeHints, NormalizedProperty, OpenAPIDefinition, UpdateSource, ValueUpdatedCallback } from '../../../../../helpers/openapi/OpenAPITypes';
import ModelProperty from './ModelProperty';
import { SchemaToPropertyInfo } from '../../../../../helpers/openapi/OpenAPITools';
import SwaggerCache from '../../../../../helpers/openapi/SwaggerCache';

import './ArrayContainer.scss';
import Tooltip from '../../../../tooltip/Tooltip';

interface IProps {
	property: NormalizedProperty;
	onValueUpdated?: ValueUpdatedCallback;
	arrayIndex?: number;
	initialValue?: any[];
	swagger: OpenAPIDefinition;
	updateSource?: UpdateSource;
	isPrimitiveArray?: boolean
}

export default function ArrayContainer(props: IProps) {
	const [myValue, setMyValue] = useState<any[] | undefined>(props.initialValue);

	useEffect(() => {
		if (!props.initialValue || props.initialValue.length === 0 || !Array.isArray(props.initialValue)) return;
		setMyValue(props.initialValue);
	}, [props.initialValue]);

	useEffect(() => {
		if (props.onValueUpdated && props.initialValue !== myValue)
			props.onValueUpdated(props.property.propertyName, myValue, props.arrayIndex);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [myValue]);

	const buildElements = () => {
		if ((!props.property.schema.items && !props.isPrimitiveArray)|| !myValue) return;
		const p = props.isPrimitiveArray
			? {
				id: '',
				schema: { type: 'string' },
				propertyName: 'value',
				typeDisplay: 'string',
				className: 'string',
			}
			: SchemaToPropertyInfo(SwaggerCache.resolveModelRef(props.swagger, props.property.schema.items!));
		if (props.isPrimitiveArray) p.propertyName = 'value';
		const searchFields: string[] | undefined = props.property.schema['x-genesys-search-fields']?.value;
		const entityType: EntityTypeHints | undefined = props.property.schema['x-genesys-entity-type']?.value;
		return myValue.map((initialValue, i) => (
			<div className="array-element-container" key={`${p.id}-${i}`}>
				<ModelProperty
					property={p}
					arrayIndex={i}
					onValueUpdated={valueUpdated}
					initialValue={initialValue}
					swagger={props.swagger}
					arrayEntityType={entityType}
					searchFields={searchFields}
					updateSource={props.updateSource}
				/>
				<Tooltip text="Remove item">
					<DxButton onClick={() => removeElement(i)} className="array-remove-icon">
						<GenesysDevIcon icon={GenesysDevIcons.AppTimes} />
					</DxButton>
				</Tooltip>
			</div>
		));
	};

	const removeElement = (position: number) => {
		let newValue: any[] | undefined = myValue ? [...myValue] : [];
		newValue.splice(position, 1);
		if (newValue.length === 0) newValue = undefined;
		setMyValue(newValue);
	};

	const valueUpdated = (propertyName: string, value: any, arrayIndex?: number) => {
		if (arrayIndex === undefined) {
			console.warn('NO ARRAY INDEX!!!!');
			return;
		}
		let newValue = myValue ? [...myValue] : [];
		newValue[arrayIndex] = value;
		setMyValue(newValue);
	};

	return (
		<div className="array-container">
			<DxButton type="secondary" className="add-button" onClick={() => setMyValue(myValue ? [...myValue, null] : [null])}>
				Add <GenesysDevIcon icon={GenesysDevIcons.AppPlusSolid} className="add-icon" />
			</DxButton>
			{buildElements()}
		</div>
	);
}
