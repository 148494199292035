import { atom } from 'recoil';
import { setRecoil } from 'recoil-nexus';

export interface Heading {
	title: string;
	link: string;
	level: number;
}

const HEADING_REGEX = /^\s*?(#{1,6})\s*(.+?)\s*#*\s*$/i;
const TITLE_REGEX = /[^a-z0-9]/gi;
const CODE_FENCE_REGEX = /^(?:```|~~~)/;

export const inPageHeadingsAtom = atom({
	key: 'inPageHeadings',
	default: undefined as Heading[] | undefined,
});

export function scrapeMarkdownHeadings(markdown?: string) {
	let isInsideCodeFence = false;
	const headingIds: string[] = [];
	const headings = (markdown || '')
		.split('\n')
		.map((line) => {
			// Check for code fences
			if (line.match(CODE_FENCE_REGEX)) {
				isInsideCodeFence = !isInsideCodeFence;
				return undefined;
			}
			if (isInsideCodeFence) return undefined;

			// Check line for heading
			const match = HEADING_REGEX.exec(line);
			if (!match) return undefined;

			// Determine link
			const link = makeHeadingId(match[2], headingIds);

			return {
				title: match[2],
				link: link,
				level: match[1].length,
			} as Heading;
		})
		.filter((line) => line !== undefined) as Heading[];
	setRecoil(inPageHeadingsAtom, headings.length > 0 ? headings : undefined);
}

export function clearHeadings() {
	setRecoil(inPageHeadingsAtom, undefined);
}

// makeHeadingId takes a heading's raw text and a list of known headings, then returns a normally formatted unique ID for the heading
export function makeHeadingId(headingName: string, headingIds: string[]) {
	const baseId = headingName.replace(TITLE_REGEX, '-').toLowerCase();
	let link = baseId;
	let i = 1;
	while (headingIds.includes(link)) {
		link = `${baseId}-${i}`;
		i++;
	}
	headingIds.push(link);
	return link;
}
