import React from 'react';
import { useRecoilState } from 'recoil';
import { toastAtom } from '../../helpers/atoms/ToastAtom';

import ToastCard from './ToastCard';

import './Toaster.scss';

export default function Toaster() {
	const [toast] = useRecoilState(toastAtom);
	return (
		<div className="toaster">
			{toast.map((slice) => (
				<ToastCard key={slice.key} toast={slice} />
			))}
		</div>
	);
}
