import React from 'react';
import { SchemaToPropertyInfo } from '../../../../../helpers/openapi/OpenAPITools';
import { EntityTypeHints, NormalizedProperty, OpenAPIDefinition, ValueUpdatedCallback, UpdateSource } from '../../../../../helpers/openapi/OpenAPITypes';
import SwaggerCache from '../../../../../helpers/openapi/SwaggerCache';
import ResponseContainer from '../../display/ResponseContainer';
import ModelProperty from './ModelProperty';

import './ObjectContainer.scss';

interface IProps {
	property: NormalizedProperty;
	onValueUpdated?: ValueUpdatedCallback;
	arrayIndex?: number;
	isRootElement?: boolean;
	initialValue?: any;
	swagger: OpenAPIDefinition;
	updateSource?: UpdateSource;
}

interface IState {
	myValue: any;
	objectProperties: any[];
	dimensionType: EntityTypeHints;
}

export default class ObjectContainer extends React.PureComponent<IProps, IState> {
	value: any;

	constructor(props: IProps) {
		super(props);
		this.state = {
			myValue: {},
			objectProperties: [],
			dimensionType: EntityTypeHints.DIMENSION_SELECTOR
		};
		// value is the source of truth because it's set synchronously
		this.value = {};
	}

	componentDidUpdate(previousProps: IProps) {
		if (
			this.props.initialValue !== undefined &&
			!this.areEquivalent(this.props.initialValue, this.value) &&
			typeof this.props.initialValue === 'object'
		) {
			this.value = this.props.initialValue;
		}
	}

	areEquivalent(obj1: any, obj2: any) {
		const isNull1 = obj1 === null || obj1 === undefined;
		const isNull2 = obj2 === null || obj2 === undefined;
		if (isNull1 && isNull2) return true;
		if ((isNull1 && !isNull2) || (!isNull1 && isNull2)) return false;
		if (Object.keys(obj1).length !== Object.keys(obj2).length) return false;
		for (const [key, val] of Object.entries(obj1)) {
			if (typeof val === 'object') {
				const isEqual = this.areEquivalent(val, obj2[key]);
				if (!isEqual) return false;
			} else {
				if (val !== obj2[key]) return false;
			}
		}
		return true;
	}

	valueUpdated(propertyName: string, value: any) {
		if (!this.value) this.value = {};
		if (value === undefined) {
			this.value = { ...this.value };
			delete this.value[propertyName];
		} else {
			this.value = {
				...this.value,
				[propertyName]: value,
			};
		}
		if (this.props.onValueUpdated)
			this.props.onValueUpdated(
				this.props.property.propertyName,
				Object.keys(this.value).length > 0 ? this.value : undefined,
				this.props.arrayIndex
			);
	}

	dimensionTypeUpdated(propertyName: string, value: any) {
		if (value === "queueId") {
			this.setState({ dimensionType: EntityTypeHints.QUEUE_ID })
		}
		else if (value === "divisionId") {
			this.setState({ dimensionType: EntityTypeHints.DIVISION_ID })
		}
		else if (value === "userId" || value === "evaluatorId") {
			this.setState({ dimensionType: EntityTypeHints.USER_ID })
		}
		else if (value === "organizationPresenceId") {
			this.setState({ dimensionType: EntityTypeHints.ORG_PRESENCE_ID })
		}
		else if (value === "systemPresence") {
			this.setState({ dimensionType: EntityTypeHints.SYSTEM_PRESENCE_ID })
		}
		else if (value === "mediaType") {
			this.setState({ dimensionType: EntityTypeHints.MEDIA_TYPE_ID })
		}
		else {
			this.setState({ dimensionType: EntityTypeHints.DIMENSION_SELECTOR });
		}
		this.valueUpdated(propertyName, value);
	}

	render() {
		return (
			<div className={'object-container' + (this.props.isRootElement === true ? ' root-element' : '')}>
				<ResponseContainer title={this.props.property.typeDisplay} showExpanded={this.props.isRootElement === true}>
					{this.props.property.schema.properties &&
						Object.values(this.props.property.schema.properties).map((prop) => {
							const p = SchemaToPropertyInfo(SwaggerCache.resolveModelRef(this.props.swagger, prop));
							const entityType: EntityTypeHints | undefined = p.schema['x-genesys-entity-type']?.value;
							const isDimensionType: boolean = entityType === EntityTypeHints.DIMENSION_TYPE;
							const isDimensionSelector: boolean = entityType === EntityTypeHints.DIMENSION_SELECTOR;
							return (
								<ModelProperty
									key={p.id}
									property={p}
									onValueUpdated={isDimensionType ? this.dimensionTypeUpdated.bind(this) : this.valueUpdated.bind(this)}
									initialValue={
										this.props.initialValue && this.props.initialValue[p.propertyName] !== undefined
											? this.props.initialValue[p.propertyName]
											: undefined
									}
									swagger={this.props.swagger}
									dynamicEntityType={isDimensionSelector ? this.state.dimensionType : undefined}
									updateSource={this.props.updateSource}
								/>
							);
						})}
				</ResponseContainer>
			</div>
		);
	}
}
