import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { default as NavMan } from '../../helpers/NavigationManager';

import './Breadcrumb.scss';

import { Page } from '../../helpers/NavigationManager.types';

interface IProps {
	pagePath?: string;
	noClick?: boolean;
}

export default function Breadcrumb(props: IProps) {
	const [currentPath, setCurrentPath] = useState(props.pagePath || NavMan.pathname);

	// Constructor
	useEffect(() => {
		// Only register for changes if we're not bound to an explicit path
		if (!props.pagePath) {
			// Listen for navigation option changes
			const onPathnameChangedHandler = (path: string) => {
				// select new path
				setCurrentPath(path);
			};
			NavMan.onPathnameChanged(onPathnameChangedHandler);

			// Force the path to update; it may have changed since initialied in useState
			setCurrentPath(NavMan.pathname);

			// Cleanup function
			return () => {
				NavMan.onPathnameChanged(onPathnameChangedHandler, true);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Break page path into crumbs
	let pathCrumbs = currentPath.split('/').filter((part) => part !== '');

	// Drop page from crumb list
	if (!currentPath.endsWith('/')) pathCrumbs = pathCrumbs.slice(0, pathCrumbs.length - 1);

	// Convert path crumbs into page crumbs for display
	let partialPath = '';
	const pageCrumbs = [] as Page[];
	pathCrumbs.forEach((part) => {
		partialPath += `/${part}`;
		let page = NavMan.getPage(partialPath, true);
		if (!page) return;
		if (page.isDir) pageCrumbs.push(page.index || { link: page.path || './', group: 'Breadcrumb' });
		else pageCrumbs.push(page as unknown as Page);
	});

	return (
		<div className="breadcrumb">
			<span className="crumb home-crumb">{props.noClick ? 'Home' : <Link to="/">Home</Link>}</span>
			{pageCrumbs.map((page) => (
				<div className="crumb-container" key={page.link}>
					<span className="crumb">|</span>
					<span className="crumb">{props.noClick ? page.group || page.title : <Link to={page.link}>{page.group || page.title}</Link>}</span>
				</div>
			))}
		</div>
	);
}
