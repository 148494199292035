import React, { useEffect, useState } from 'react';
import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';

import Tooltip from '../tooltip/Tooltip';

import './StarIcon.scss';

interface IProps {
	starredTooltipText: string;
	unstarredTooltipText: string;
	isStarred?: boolean;
	onClick?: { (isStarred: boolean): void };
	className?: string;
}

export default function StarIcon(props: IProps) {
	const [isStarred, setIsStarred] = useState<boolean>(props.isStarred === true);
	const [isTooltipActive, setIsTooltipActive] = useState<boolean>(false);

	useEffect(() => {
		if (props.isStarred === undefined) return;
		setIsStarred(props.isStarred === true);
	}, [props.isStarred]);

	const onClick = () => {
		// Update state
		setIsStarred(!isStarred);

		// Tooltip control
		setIsTooltipActive(true);
		setTimeout(() => setIsTooltipActive(false), 1000);

		// Notify parent
		if (props.onClick) props.onClick(!isStarred);
	};

	return (
		<Tooltip
			text={isStarred ? props.starredTooltipText : props.unstarredTooltipText}
			className={`star-icon-container ${props.className || ''}`}
			isShowing={isTooltipActive}
			position="right"
		>
			<GenesysDevIcon
				className={`star-icon${isStarred ? ' star-icon-active' : ''}`}
				onClick={onClick}
				icon={isStarred ? GenesysDevIcons.AppStarSolid : GenesysDevIcons.AppStarStroke}
			/>
		</Tooltip>
	);
}
