import React, { useEffect, useState } from 'react';

import AssetLoader from '../../../helpers/AssetLoader';

import './swaggerDiff.scss';
import { DxAccordion, DxAccordionGroup, DxButton, DxCheckbox, DxItemGroup, DxItemGroupItem } from 'genesys-react-components';
import AlertBlock from '../../markdown/alertblock/AlertBlock';

interface SwaggerVersion {
	key: string;
	date: string;
}

interface SwaggerDiffResponse {
	message: SwaggerDiffResults;
}

interface SwaggerDiffResults {
	changes: SwaggerDiffResultChangeSet;
	data: { oldSwaggerName: string; newSwaggerName: string };
}

interface SwaggerDiffResultChangeSet {
	major: SwaggerDiffResultResource[];
	majorCount: number;
	minor: SwaggerDiffResultResource[];
	minorCount: number;
	point: SwaggerDiffResultResource[];
	pointCount: number;
}

interface SwaggerDiffResultResource {
	key: string;
	changes: SwaggerDiffChange[];
	changeCount: number;
}

interface SwaggerDiffChange {
	key: string;
	description: string;
	impact: string;
	location: string;
	newValue: string;
	oldValue: string;
	parent: string;
}

export default function SwaggerDiff() {
	const [useSDK, setUseSDK] = useState<boolean>(false);
	const [swaggers, setSwaggers] = useState<SwaggerVersion[]>([]);
	const [oldVersion, setOldVersion] = useState<string | undefined>();
	const [newVersion, setNewVersion] = useState<string | undefined>();
	const [errorMessage, setErrorMessage] = useState<string | undefined>();
	const [changes, setChanges] = useState<SwaggerDiffResultChangeSet | undefined>();

	// Constructor
	useEffect(() => {
		// Invoking getObjects lambda function to fetch swagger files from S3 bucket
		AssetLoader.get('/_lambda/swaggerVersions', true)
			.then((data) => {
				console.log(data);
				// Function to parse Key of swagger files to get only valid version number, and sort the order from new to old versions
				// e.g publicapi-v2-1008.json => 1008
				let versionData: SwaggerVersion[] = data.objects.map((obj: any) => ({
					key: obj.key[0].substring(obj.key[0].lastIndexOf('-') + 1, obj.key[0].indexOf('.json')),
					date: obj.date,
				}));

				versionData.sort(function (first: any, second: any) {
					return second.key - first.key;
				});

				console.log(versionData);
				setSwaggers(versionData);
				setOldVersion(versionData[1].key);
				setNewVersion(versionData[0].key);
			})
			.catch((err) => {
				console.error(err);
				setErrorMessage('Failed to fetch swagger file versions data.');
			});
	}, []);

	// Used to detect plurals and add "s" properly
	const pluralize = (text: string, num: number) => {
		return num < 2 ? text : text + 's';
	};

	// Invoking diff lambda function to compare swaggers and generate results
	const diff = () => {
		if (!newVersion) return setErrorMessage('Select a new verion before diffing');
		if (!oldVersion) return setErrorMessage('Select an old verion before diffing');

		setErrorMessage(undefined);
		AssetLoader.get(
			`/_lambda/swaggerDiff?oldversion=${encodeURIComponent(oldVersion)}&newversion=${encodeURIComponent(newVersion)}&sdk=${useSDK}`,
			true
		)
			.then((data: SwaggerDiffResponse) => {
				console.log(data);
				setChanges(data.message.changes);
			})
			.catch((err) => {
				console.error(err);
				setErrorMessage('Failed to send request and generate diff results.');
			});
	};

	return (
		<div className="swagger-diff">
			<div className="options-row">
				<DxItemGroup
					title="Old API Version"
					format="dropdown"
					items={swaggers.map((swaggerVersion) => {
						return {
							label: `${swaggerVersion.key} - ${swaggerVersion.date}`,
							value: swaggerVersion.key,
							isSelected: oldVersion && swaggerVersion.key === oldVersion,
						} as DxItemGroupItem;
					})}
					onItemChanged={(item) => {
						console.log(item);
						setOldVersion(item.value);
					}}
				/>
				<DxItemGroup
					title="New API Version"
					format="dropdown"
					items={swaggers.map((swaggerVersion) => {
						return {
							label: `${swaggerVersion.key} - ${swaggerVersion.date}`,
							value: swaggerVersion.key,
							isSelected: newVersion && swaggerVersion.key === newVersion,
						} as DxItemGroupItem;
					})}
					onItemChanged={(item) => {
						console.log(item);
						setNewVersion(item.value);
					}}
				/>
				<DxCheckbox label="Use SDK Versioning" itemValue="use-sdk-versioning" onCheckChanged={(checked) => setUseSDK(checked)} />
			</div>

			<DxButton className="diff-button" onClick={() => diff()}>
				Compare Versions
			</DxButton>

			{errorMessage && <AlertBlock alertType="critical">{errorMessage}</AlertBlock>}
			{changes && (
				<DxAccordionGroup>
					<h3>Diff Results</h3>
					<DxAccordion title={`Major Changes (${changes.major?.length} changes)`}>
						{changes.major?.map((x, i) => (
							<div key={i}>
								<h4 key={i}>
									{x.key} ({x.changeCount} {pluralize('change', x.changeCount)})
								</h4>
								<ul>
									{x.changes.map((change, index) => (
										<li key={index}>{change.description}</li>
									))}
								</ul>
							</div>
						))}
						{(!changes.major || changes.major.length === 0) && <em>No major changes</em>}
					</DxAccordion>
					<DxAccordion title={`Minor Changes (${changes.minor?.length} changes)`}>
						{changes.minor?.map((x, i) => (
							<div key={i}>
								<h4 key={i}>
									{x.key} ({x.changeCount} {pluralize('change', x.changeCount)})
								</h4>
								<ul>
									{x.changes.map((change, index) => (
										<li key={index}>{change.description}</li>
									))}
								</ul>
							</div>
						))}
						{(!changes.minor || changes.minor.length === 0) && <em>No minor changes</em>}
					</DxAccordion>
					<DxAccordion title={`Point Changes (${changes.point?.length} changes)`}>
						{changes.point?.map((x, i) => (
							<div key={i}>
								<h4 key={i}>
									{x.key} ({x.changeCount} {pluralize('change', x.changeCount)})
								</h4>
								<ul>
									{x.changes.map((change, index) => (
										<li key={index}>{change.description}</li>
									))}
								</ul>
							</div>
						))}
						{(!changes.point || changes.point.length === 0) && <em>No point changes</em>}
					</DxAccordion>
				</DxAccordionGroup>
			)}
		</div>
	);
}
