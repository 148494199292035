import React from 'react';

import DxLink from '../dxlink/DxLink';

import './ToastCard.scss';
import MarkdownDisplay from '../markdowndisplay/MarkdownDisplay';
import { MinimumToastTimeoutSeconds, removeToast, Toast, ToastType } from '../../helpers/atoms/ToastAtom';
import { GenesysDevIcon, GenesysDevIcons } from 'genesys-dev-icons';

interface IProps {
	toast: Toast;
}

export default function ToastCard(props: IProps) {
	const contentLink = props.toast.link ? (
		<span className="content-link">
			<DxLink href={props.toast.link}>{props.toast.linkText || 'More information...'}</DxLink>
		</span>
	) : undefined;
	let iconType = GenesysDevIcons.AppInfoSolid;
	switch (props.toast.toastType) {
		case ToastType.Success: {
			iconType = GenesysDevIcons.AppSuccessSolid;
			break;
		}
		case ToastType.Warning: {
			iconType = GenesysDevIcons.AppWarnSolid;
			break;
		}
		case ToastType.Critical: {
			iconType = GenesysDevIcons.AppCriticalSolid;
			break;
		}
	}

	const progressBarStyle: React.CSSProperties = {};
	if (props.toast.timeoutSeconds && props.toast.timeoutSeconds >= MinimumToastTimeoutSeconds) {
		progressBarStyle.animationDuration = `${props.toast.timeoutSeconds}s`;
	} else {
		progressBarStyle.display = 'none';
	}

	return (
		<div className={`toast ${props.toast.toastType || ''}`}>
			<div className="toast-body">
				<div className="toast-icon">
					<GenesysDevIcon icon={iconType} />
				</div>
				<div className="toast-content">
					<span className="content-title">{props.toast.title}</span>
					<span className="content-text">
						<MarkdownDisplay markdown={props.toast.message} />
					</span>
					{contentLink}
				</div>
				<div className="toast-x" onClick={() => removeToast(props.toast)}>
					<GenesysDevIcon icon={GenesysDevIcons.AppTimes} />
				</div>
			</div>
			<div className="toast-timeout-container">
				<div className="progress-bar" style={progressBarStyle}></div>
			</div>
		</div>
	);
}
