import React from 'react';

import { OperationDetails } from '../../../../helpers/openapi/OpenAPITypes';

interface IProps {
	operationDetails: OperationDetails;
}

export default function ScopesDisplay(props: IProps) {
	let content;
	if (
		!props.operationDetails.operation.security ||
		props.operationDetails.operation.security.every((s) => Object.values(s).every((ss) => ss.length === 0))
	) {
		content = (
			<p>
				<em>No scopes required</em>
			</p>
		);
	} else {
		const items = [] as any[];
		props.operationDetails.operation.security.forEach((securityInfo) => {
			Object.values(securityInfo).forEach((scopes) => {
				scopes.forEach((s) => items.push(<li key={s}>{s}</li>));
			});
		});

		content = (
			<div>
				<p>Apps making this request must have any of these scopes:</p>
				<ul>{items}</ul>
			</div>
		);
	}

	return (
		<div>
			<h3>Required Scopes</h3>
			{content}
		</div>
	);
}
