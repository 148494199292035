import { atom } from 'recoil';
import { getRecoil, setRecoil } from 'recoil-nexus';
import { GetHistory } from '../../components/historyaccess/HistoryAccess';
import { Account } from '../accounts/Account';
import { Region } from '../platformapi/Region';
import SettingsManager from '../settings/SettingsManager';
import { addToast, Toast, ToastType } from './ToastAtom';

interface AccountData {
	token: string;
	region: Region;
	userId?: string;
}

interface IOauthState {
	region?: Region;
	location?: string;
}

const SETTING_SELECTED_ACCOUNT = 'selected-account';
const SETTING_ACCOUNT_LIST = 'accounts';

/*** ATOMS ***/

export const accountsAtom = atom({
	key: SETTING_ACCOUNT_LIST,
	default: init(),
	effects_UNSTABLE: [
		({ onSet }) => {
			onSet((newAccountList) => {
				// Save the account list whenever it changes
				saveAccountList(newAccountList);
			});
		},
	],
});

export const selectedAccountAtom = atom({
	key: SETTING_SELECTED_ACCOUNT,
	default: undefined as unknown as Account | undefined,
});

/*** ATOM FUNCTIONS ***/

// removeAccount removes the provided account from the list of accounts
export function removeAccount(account: Account) {
	let newAccounts = getAccountList();
	const idx = newAccounts.indexOf(account);
	if (idx >= 0) {
		newAccounts.splice(idx, 1);
		setRecoil(accountsAtom, newAccounts);
	}

	if (account.initialized) {
		addToast({
			title: 'Account removed',
			message: `An account for ${account.name} has been removed in the ${account.region} region. You can manage your active accounts using the account selector.`,
			toastType: ToastType.Success,
			timeoutSeconds: 20,
		});
	}
	let selectedAccount = getRecoil(selectedAccountAtom);
	if (selectedAccount?.userId === account.userId) {
		// console.log('setting default account', newAccounts);
		setSelectedAccount(newAccounts.length > 0 ? newAccounts[0] : undefined);
	}
}

export function setSelectedAccount(account?: Account) {
	setRecoil(selectedAccountAtom, account);
	saveSelectedAccount(account);
}

/*** PUBLIC NON-ATOM FUNCTIONS ***/

// authorizeNewAccount initiates an OAuth flow to get an access token
export function authorizeNewAccount(region: Region, forcePromptForLogin = false) {
	// developer_tools built-in client
	const clientId = '96a7d55b-1ed5-4719-9094-08a2a69ca07c';

	// Normalize redirect URI
	let redirectUri = window.location.origin;
	if (!redirectUri.endsWith('/')) redirectUri += '/';

	//BUG: Force the user to be prompted for authentication with a new query param. Pending IAM-1228
	window.location.assign(
		`https://login.${region}/oauth/authorize?client_id=${clientId}${
			forcePromptForLogin ? '&prompt=login' : ''
		}&response_type=token&redirect_uri=${encodeURI(redirectUri)}&state=${encodeURIComponent(serializeOauthState(region))}`
	);
}

/*** PRIVATE FUNCTIONS ***/

function getAccountList() {
	let accounts = getRecoil(accountsAtom);
	if (!accounts || typeof accounts[Symbol.iterator] !== 'function') return [];
	return [...accounts];
}

// init is used as a constructor to load accounts from storage when the account list atom is initialized. Valid accounts will be added to the list via state updates, not returned here.
async function init(): Promise<Account[]> {
	// Load accounts from storage and run the initializer on each one
	let accountDataList = ((await SettingsManager.getDirect(SETTING_ACCOUNT_LIST)) || []) as AccountData[];

	// Asynchronously initialize accounts
	const promises = [] as Promise<Account | undefined>[];
	accountDataList.forEach((accountData: AccountData) => promises.push(initAccount(accountData)));

	// Handle new login
	if (window.location.hash) {
		const state = deserializeOauthState();
		const hash = parseKeyValueString(window.location.hash);
		if (hash.access_token) {
			promises.push(initAccount({ token: hash.access_token, region: state.region || Region.us_east_1 }, true));
			window.location.hash = '';
			if (state.location) {
				// Navigate internally within the app
				GetHistory().push(state.location);
			}

			// Storage not allowed, warn users their setting will be lost
			if (!SettingsManager.getStorageAllowed()) {
				const toast: Toast = {
					toastType: ToastType.Warning,
					title: 'Browser storage is disabled',
					message: `Accounts added to the account switcher will not be remembered when you refresh the page. Please enable browser storage in the Account Switcher above to allow your accounts to be remembered.`,
					timeoutSeconds: 30,
				};
				addToast(toast);
			}
		}
	}

	// Set list of initialized accounts
	Promise.allSettled(promises).then(async (accounts) => {
		let selectedAccountId = ((await SettingsManager.getDirect(SETTING_SELECTED_ACCOUNT)) || []) as string | undefined;

		// Reduce to final list of accounts
		const filteredAccounts = scrubDuplicateAccounts(
			accounts.map((p) => (p.status === 'fulfilled' ? p.value : undefined)).filter((a) => a !== undefined) as Account[]
		);
		setRecoil(accountsAtom, filteredAccounts);

		// Ensure selected account is set
		const foundSelectedAccount = filteredAccounts.some((account) => {
			if (account.userId === selectedAccountId) {
				setSelectedAccount(account);
				return true;
			}
			return false;
		});
		if (!foundSelectedAccount && accounts.length > 0) {
			setSelectedAccount(filteredAccounts[0]);
		}
	});

	return [];
}

function serializeOauthState(region?: Region) {
	let state = `location=${encodeURIComponent(window.location.pathname + window.location.search + window.location.hash)}`;
	if (region) {
		state += `&region=${encodeURIComponent(region)}`;
	}
	return encodeURIComponent(state);
}

function deserializeOauthState(): IOauthState {
	// Auth double encodes the returned state value, so we must double decode it
	return parseKeyValueString(decodeURIComponent(decodeURIComponent(parseKeyValueString(window.location.hash).state || '')));
}

function parseKeyValueString(s?: string) {
	if (!s) return {};
	// https://stackoverflow.com/a/5647103/14737424
	if (s.startsWith('#')) s = s.substring(1);
	let r = s
		.split('&')
		.map((v) => v.split('='))
		.reduce((pre, [key, value]) => ({ ...pre, [key]: decodeURIComponent(value) }), {}) as any;
	return r;
}

async function initAccount(accountData: AccountData, isNewAccount = false) {
	// Create account object and set cached id
	var newAccount = new Account(accountData.token, accountData.region);
	if (accountData.userId) newAccount.setUserId(accountData.userId);

	try {
		// Initialize account info
		await newAccount.init();
		if (isNewAccount) {
			addToast({
				title: 'Account added',
				message: `An account for ${newAccount.name} has been added in the ${newAccount.region} region. You can manage your active accounts using the account selector.`,
				toastType: ToastType.Success,
				timeoutSeconds: 20,
			});
		}

		return newAccount;
	} catch (err) {
		// Notify on error
		const msg = `Account in region ${newAccount.region} with userId ${newAccount.userId} has expired. Please add this account again to continue using it.`;
		console.error(msg, err);
		addToast({
			title: 'Account Error',
			message: msg,
			toastType: ToastType.Critical,
		});

		// Remove account from list
		//TODO: don't remove, show as invalid and prompt user to reauthorize
		removeAccount(newAccount);
	}
}

// Removes duplicate accounts and returns the scrubbed list
function scrubDuplicateAccounts(accounts: Account[]) {
	// console.log('scrubbing');
	const userIds = [] as string[];
	let keepAccounts = [] as Account[];

	// Run through the list backwards to keep newest accounts and accounts without user ids
	for (var i = accounts.length - 1; i >= 0; i--) {
		const userId = accounts[i].userId;
		if (userIds.includes(userId)) continue;
		if (userId && userId !== '') userIds.push(userId);
		keepAccounts.push(accounts[i]);
	}

	// Reverse the list to maintain the original order and notify
	keepAccounts.reverse();

	return keepAccounts;
}

// saveAccountList saves atom account data to local storage
function saveAccountList(accounts: Account[]) {
	//TODO: store org id so expired accounts can prompt to reauthorize in that org
	let accountData = [] as AccountData[];
	accounts.forEach((account: Account) => accountData.push(account.asData()));
	SettingsManager.setDirect(SETTING_ACCOUNT_LIST, accountData);
	// console.log('SAVED', accountData);
}

function saveSelectedAccount(account?: Account) {
	SettingsManager.setDirect(SETTING_SELECTED_ACCOUNT, account?.userId || '');
}
