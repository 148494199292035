import React from 'react';

import './Tag.scss';

interface IProps {
	children: React.ReactNode;
	className?: string;
}

export default function Tag(props: IProps) {
	return <span className={`tag ${props.className || ''}`}>{props.children}</span>;
}
